import Avatar from '@/components/shared/Avatar'
import { Button, ButtonEnum } from '@/components/shared/Button'
import { InfoWrapper, SettingsSubtitle } from './style'
import { Input } from '@/components/shared/Input'
import { BORDER_COLORS, FARE_COLORS } from '@/design'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { Toggler } from '@/components/shared/Toggle'
import useUserData from '@/hooks/useUserData'
import { useActiveWallet, usePrivyTwitterData } from '@/lib/privy/hooks'
import ConnectWallet from '@/components/shared/Wallet/ConnectWallet'
import { usePrivy } from '@privy-io/react-auth'
import { noUserSelect } from '@/style'

const SAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const SBtnWrapper = styled.div`
  display: flex;
  gap: 12px;
`

export const SAccountInfoWrapper = styled.div<{ $isMobileScreen: boolean }>`
  display: flex;
  flex-direction: column;
  ${noUserSelect}

  button {
    width: ${({ $isMobileScreen }) => ($isMobileScreen ? '80px' : '120px')};
  }
  > span {
    padding: 12px !important;
    padding-left: 0px !important;
    text-transform: uppercase;
  }
  .account-info-value {
    color: rgba(255, 255, 255, 0.6);
  }
`

export const SAccountInfoBox = styled.div`
  border: 1px solid ${BORDER_COLORS.one};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-radius: 6px;
  gap: 12px;
  ${noUserSelect}

  .twitter-profile-pic {
    height: 36px;
    width: 36px;
    border-radius: 100%;
    margin-right: 12px;
  }

  .disconnect-btn {
    &:hover {
      border: 1px solid ${FARE_COLORS.salmon} !important;
      box-shadow: inset 0px 0px 56px ${FARE_COLORS.salmon}70;
    }
  }

  .cancel-btn {
    &:hover {
      border: 1px solid ${FARE_COLORS.peach} !important;
      box-shadow: inset 0px 0px 56px ${FARE_COLORS.peach}70;
    }
  }

  .save-btn {
    &:hover {
      border: 1px solid ${FARE_COLORS.aqua} !important;
      box-shadow: inset 0px 0px 56px ${FARE_COLORS.aqua}70;
    }
  }

  @media (max-width: 500px) {
    padding: 16px;
    margin: 16px;
    gap: 12px;
  }
`

export const SInputWrapper = styled.div`
  position: relative;
  flex: 1;
  .account-input-field {
    border: 1px solid ${FARE_COLORS.blue};
  }
`

const SColumnTwitter = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  gap: 12px;
  flex: 1;
  .twitter-profile-pic {
    height: 36px;
    width: 36px;
    border-radius: 100%;
    margin-right: 12px;
  }

  .disconnect-btn {
    &:hover {
      border: 1px solid ${FARE_COLORS.salmon} !important;
      box-shadow: inset 0px 0px 56px ${FARE_COLORS.salmon}70;
    }
  }

  .cancel-btn {
    &:hover {
      border: 1px solid ${FARE_COLORS.peach} !important;
      box-shadow: inset 0px 0px 56px ${FARE_COLORS.peach}70;
    }
  }

  .save-btn {
    &:hover {
      border: 1px solid ${FARE_COLORS.aqua} !important;
      box-shadow: inset 0px 0px 56px ${FARE_COLORS.aqua}70;
    }
  }
`

const SRowTwitter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.border-separator {
    border-top: 1px solid ${BORDER_COLORS.one};
    padding-top: 16px;
  }
`

export const AccountTab = () => {
  const [editingUsername, setEditingUsername] = useState('')
  const [isEditingUsername, setIsEditingUsername] = useState(false)
  const [email, setEmail] = useState('')
  const isMobileScreen = useIsBreakpoint('sm')
  const {
    username,
    requestToSaveUsername,
    avatarSeedAddress,
    requestToRerollAvatarSeed,
    isUsingTwitterToDisplay,
    requestToSaveIsUsingTwitterToDisplay,
    requestToDisconnectTwitter,
  } = useUserData()
  const { walletAddress, user } = useActiveWallet()
  const twitterData = usePrivyTwitterData()
  const { linkTwitter, unlinkTwitter, linkEmail, unlinkEmail, updateEmail } = usePrivy()

  useEffect(() => {
    if (user?.email) {
      setEmail(user.email.address)
    }
  }, [walletAddress, user?.email])

  const saveUsername = async () => {
    setIsEditingUsername(false)
    await requestToSaveUsername(editingUsername)
  }

  const connectTwitter = () => {
    if (!walletAddress) return
    linkTwitter()
  }

  const unlinkUserEmail = async () => {
    if (!email) return
    await unlinkEmail(email)
    setEmail('')
  }

  const disconnectTwitter = async () => {
    if (!twitterData) return
    const unlinkedTwitterUser = await unlinkTwitter(twitterData.subject)
    console.log(unlinkedTwitterUser)
    await requestToDisconnectTwitter()
  }

  return !walletAddress ?
      <ConnectWallet />
    : <div className='settings-tab-content'>
        <SettingsSubtitle>Manage your account:</SettingsSubtitle>
        <InfoWrapper className='account-info-wrapper'>
          <SAccountInfoWrapper $isMobileScreen={isMobileScreen}>
            <span className='account-info-label'>Avatar</span>
            <SAccountInfoBox>
              <Avatar seed={avatarSeedAddress || ''} size='md' />
              <Button
                isMinified
                disabled={false}
                isLoading={false}
                buttonType={ButtonEnum.EDIT_1}
                onClick={requestToRerollAvatarSeed}
              >
                REROLL
              </Button>
            </SAccountInfoBox>
          </SAccountInfoWrapper>
          <SAccountInfoWrapper $isMobileScreen={isMobileScreen}>
            <span className='account-info-label'>Username</span>
            <SAccountInfoBox>
              {isEditingUsername ?
                <>
                  <SInputWrapper>
                    <Input
                      className='account-input-field'
                      name='fp-input-1'
                      autoFocus
                      placeholder='Enter username...'
                      value={editingUsername}
                      onChange={ev => setEditingUsername(ev.currentTarget.value)}
                    />
                  </SInputWrapper>
                  <Button
                    key='1'
                    className='cancel-btn'
                    isMinified
                    disabled={false}
                    isLoading={false}
                    buttonType={ButtonEnum.EDIT_1}
                    onClick={() => setIsEditingUsername(false)}
                  >
                    CANCEL
                  </Button>
                  <Button
                    key='2'
                    className={'save-btn'}
                    isMinified
                    disabled={false}
                    isLoading={false}
                    buttonType={ButtonEnum.EDIT_1}
                    onClick={saveUsername}
                  >
                    SAVE
                  </Button>
                </>
              : <>
                  <span className='account-info-value'>{username || 'UNNAMED'}</span>
                  <Button
                    key='3'
                    isMinified
                    disabled={false}
                    isLoading={false}
                    buttonType={ButtonEnum.EDIT_1}
                    onClick={() => setIsEditingUsername(true)}
                  >
                    {username ? 'EDIT' : 'ADD'}
                  </Button>
                </>
              }
            </SAccountInfoBox>
          </SAccountInfoWrapper>
          <SAccountInfoWrapper $isMobileScreen={isMobileScreen}>
            <span className='account-info-label'>Email</span>
            <SAccountInfoBox>
              <SInputWrapper>
                <span className='account-info-value'>{email || 'NO EMAIL'}</span>
              </SInputWrapper>
              {email && (
                <Button
                  key='3'
                  isMinified
                  disabled={false}
                  isLoading={false}
                  buttonType={ButtonEnum.EDIT_1}
                  onClick={unlinkUserEmail}
                >
                  UNLINK
                </Button>
              )}
              <Button
                key='4'
                isMinified
                disabled={false}
                isLoading={false}
                buttonType={ButtonEnum.EDIT_1}
                onClick={() => (email ? updateEmail() : linkEmail())}
              >
                {email ? 'EDIT' : 'ADD'}
              </Button>
            </SAccountInfoBox>
          </SAccountInfoWrapper>
          <SAccountInfoWrapper $isMobileScreen={isMobileScreen}>
            <span className='account-info-label'>Twitter</span>
            <SAccountInfoBox>
              <SColumnTwitter>
                <SRowTwitter>
                  {twitterData ?
                    <SAvatarWrapper>
                      <img
                        className='twitter-profile-pic'
                        src={twitterData.profilePictureUrl || ''}
                      />
                      <a
                        account-info-value
                        href={`https://twitter.com/${twitterData.username}`}
                        target='_blank'
                        style={{ color: `${FARE_COLORS.aqua}`, textDecoration: 'none' }}
                        rel='noreferrer'
                      >
                        @{twitterData.username}
                      </a>
                    </SAvatarWrapper>
                  : <span className='account-info-value'>NOT CONNECTED</span>}
                  {twitterData ?
                    <SBtnWrapper>
                      <Button
                        className={'disconnect-btn'}
                        isMinified
                        disabled={false}
                        isLoading={false}
                        buttonType={ButtonEnum.EDIT_1}
                        onClick={disconnectTwitter}
                      >
                        UNLINK
                      </Button>
                    </SBtnWrapper>
                  : <Button
                      className={'save-btn'}
                      isMinified
                      disabled={false}
                      isLoading={false}
                      buttonType={ButtonEnum.EDIT_1}
                      onClick={connectTwitter}
                    >
                      CONNECT
                    </Button>
                  }
                </SRowTwitter>
                <SRowTwitter className='border-separator'>
                  <span className='account-info-value'>DISPLAY TWITTER AVATAR/USERNAME</span>
                  <Toggler
                    disabled={!twitterData}
                    isChecked={!!isUsingTwitterToDisplay}
                    onChecked={() => requestToSaveIsUsingTwitterToDisplay(!isUsingTwitterToDisplay)}
                    toggleType='quickplay'
                  />
                </SRowTwitter>
              </SColumnTwitter>
            </SAccountInfoBox>
          </SAccountInfoWrapper>
        </InfoWrapper>
      </div>
}
