import { createWithEqualityFn } from 'zustand/traditional'
import { shallow } from 'zustand/vanilla/shallow'
import { appChainIdMap, DEFAULT_APP_CHAIN_ID } from '@/chains'
import { SupportedAppChainId, type AppChainConfig } from '@/chains/types'
import { AppContracts, makeContracts, makeWsContracts } from '@/chains/lib'
import { LOCAL_STORAGE } from '@/constants/storage'
import { Web3Provider, WebSocketProvider } from '@ethersproject/providers'
import { createSelectors } from './helpers/createSelectors'
import { ConnectedWallet } from '@privy-io/react-auth'

export type SessionVerifyState = 'pending' | 'not-verified' | 'verified'

export type AppChainConfigState = {
  appChainId: SupportedAppChainId
  appChainConfig: AppChainConfig
  appProvider: Web3Provider | null
  appProviderWs: WebSocketProvider | null
  appContracts?: AppContracts & { ws: AppContracts }
  appWalletClientType: string
  isWrongNetwork: boolean
  sessionVerifyState: SessionVerifyState
}

export type AppChainConfigActions = {
  setAppChainId: (appChainId: SupportedAppChainId) => void
  setAppProvider: (chainId: number, activeWallet: ConnectedWallet | null) => void
  setAppWalletClientType: (appWalletClientType: string) => void
  setSessionVerifyState: (sessionVerifyState: SessionVerifyState) => void
}

export const initialAppChainConfigState: AppChainConfigState = {
  appProvider: null,
  appProviderWs: null,
  appChainId: DEFAULT_APP_CHAIN_ID,
  appChainConfig: appChainIdMap[DEFAULT_APP_CHAIN_ID],
  appWalletClientType: localStorage.getItem(LOCAL_STORAGE.SELECTED_APP_WALLET_CLIENT_TYPE) || '',
  isWrongNetwork: false,
  sessionVerifyState: 'pending',
}

export type AppChainConfigStore = AppChainConfigState & AppChainConfigActions

const useAppChainConfigStoreBase = createWithEqualityFn<AppChainConfigStore>(
  (set, get) => ({
    ...initialAppChainConfigState,
    setAppWalletClientType: appWalletClientType =>
      set(() => {
        localStorage.setItem(
          LOCAL_STORAGE.SELECTED_APP_WALLET_CLIENT_TYPE,
          String(appWalletClientType)
        )
        return {
          appWalletClientType,
        }
      }),
    setAppChainId: appChainId =>
      set(() => {
        const isWrongNetwork = !Object.values(SupportedAppChainId).includes(appChainId)

        if (isWrongNetwork) {
          return {
            isWrongNetwork,
          }
        }

        return {
          appChainId,
          appChainConfig: appChainIdMap[appChainId],
          isWrongNetwork,
        }
      }),
    setAppProvider: async (chainId, activeWallet) => {
      if (!activeWallet) return {}
      await activeWallet.switchChain(chainId)
      const appProvider = await activeWallet.getEthersProvider()

      const contracts = makeContracts(get().appChainConfig, appProvider)
      const appProviderWs = new WebSocketProvider(get().appChainConfig.rpc.ws)
      const wsContracts = makeWsContracts(contracts, appProviderWs)
      get().setAppChainId(chainId)
      const appContracts = {
        ...contracts,
        ws: wsContracts,
      } as const

      set({
        appProvider,
        appProviderWs,
        appContracts,
      })
    },
    setSessionVerifyState: sessionVerifyState => set({ sessionVerifyState }),
  }),
  shallow
)

export const useAppChainConfigStore = createSelectors(useAppChainConfigStoreBase)
