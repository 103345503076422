import { BORDER_COLORS } from '@/design'
import { deviceBP } from '@/design/breakpoints'

interface SkeletonProps {
  height?: string
}

const loading = keyframes`
  0% {
    background-color: transparent;
  }
  50% {
    background-color: #121212;
  }
  100% {
    background-color: transparent;
  }
`

const StyledSkeleton = styled.div<SkeletonProps>`
  height: ${props => props.height || '100%'};
  border-bottom: 1px solid ${BORDER_COLORS.one};
  background: transparent;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px 8px;

  > div {
    &:nth-child(1) {
      flex: 3;

      @media ${deviceBP.sm} {
        flex-shrink: 2;
        flex: 2;
      }
    }

    &:nth-child(2),
    &:nth-child(3) {
      flex: 3;
      text-align: center;

      @media ${deviceBP.sm} {
        flex: 5;
      }
    }

    &:nth-child(4) {
      flex: 3;
      text-align: right;

      @media ${deviceBP.sm} {
        flex: 3;
      }
    }
  }
`

const SkeletonDataWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 8px;
  padding-bottom: 8px;

  > span {
    height: 14px;
    width: 14px;
    animation: ${loading} 3s ease-in-out infinite;
    border-radius: 4px;
    margin-top: 10px;
  }
  .rank-avatar-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    animation: ${loading} 3s ease-in-out infinite;
    margin-top: 10px;
    margin-left: 10px;
  }

  .user-data-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 14px;
    width: 100px;
    animation: ${loading} 3s ease-in-out infinite;
    border-radius: 4px;
    margin-top: 10px;
    margin-left: 10px;
  }

  @media ${deviceBP.sm} {
    display: none;
    position: absolute;
    bottom: 0px;
    background: #121212;
    border: 1px solid ${BORDER_COLORS.one};
    border-radius: 6px;
    }
  }
`

const SkeletonUserCell = styled.div`
  height: 14px;
  animation: ${loading} 3s ease-in-out infinite;
  border-radius: 4px;
  margin-top: 10px;
  margin-left: 56px;

  @media ${deviceBP.lg} {
    margin-left: 32px;
  }

  @media ${deviceBP.sm} {
    margin-left: 12px;
`

export default function SkeletonLeaderboardGrid(props: SkeletonProps) {
  return (
    <StyledSkeleton height={props.height}>
      <SkeletonDataWrapper>
        <span />
        <div className='rank-avatar-wrapper' />
        <div className='user-data-text' />
      </SkeletonDataWrapper>
      <SkeletonUserCell />
      <SkeletonUserCell />
      <SkeletonUserCell />
    </StyledSkeleton>
  )
}
