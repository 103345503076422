import { type UseBoundStore, type StoreApi, create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

import {
  GameStateContext,
  GameAction,
  GameStore,
  GameStateAction,
  GameEntrySlice,
  GameLiveEntrySlice,
} from './index.types'
import { createGameEntrySlice, initialGameEntryState } from './gameEntrySlice'
import { createGameLiveEntrySlice, initialGameLiveEntryState } from './gameLiveEntrySlice'
export { initialGameEntryState } from './gameEntrySlice'

export const initialGameState: GameStateContext = {
  type: 'IDLE',
  entry: initialGameEntryState,
  liveEntry: initialGameLiveEntryState,
  submittedEntry: null,
  results: null,
  errMsg: '',
}

/* Current State -> Next State */
const stateTransitions: { [key in GameAction]: GameAction[] } = {
  IDLE: ['START', 'ERROR'],
  START: ['RESOLVE', 'ERROR'],
  RESOLVE: ['RESET'],
  ERROR: ['RESET'],
  RESET: ['IDLE'],
} as const

const isStateTransition = (currType: GameAction, nextType: GameAction) => {
  const transitionList = stateTransitions[currType]
  return {
    isTransition: transitionList.includes(nextType),
    transitionList,
  }
}

const reducer = (
  state: GameStore,
  currType: GameAction,
  { type: nextType, payload }: GameStateAction
) => {
  if (nextType === currType) return
  const { isTransition, transitionList } = isStateTransition(currType, nextType)

  // @TODO: create options that can be passed
  if (!isTransition) {
    return console.warn(
      `Cannot go from ${currType} to ${nextType}.\n Valid ${currType} transition state(s) ->`,
      transitionList
    )
  } else {
    console.log(
      `State transition from %c${currType}%c to %c${nextType}%c | Payload ->`,
      'color: #512DA8; font-weight: bold;',
      '',
      'color: #00796B; font-weight: bold;',
      '',
      payload
    )
  }

  // Pass data to transition
  state.stateData = payload.stateData

  if (nextType === 'IDLE') {
    state.type = nextType
  } else if (nextType === 'START') {
    state.type = nextType
    state.submittedEntry = payload
  } else if (nextType === 'RESOLVE') {
    state.type = nextType
    state.results = payload
  } else if (nextType === 'ERROR') {
    state.type = nextType
    state.errMsg = payload.errMsg
  } else if (nextType === 'RESET') {
    state.type = nextType
    state.results = null
    state.submittedEntry = null
    state.errMsg = ''
  } else {
    return console.error('GameStateAction not found:', nextType)
  }
}

export const createGameStateStore = (
  overrideGameState: Partial<GameStateContext> = {}
): UseBoundStore<StoreApi<GameStore & GameEntrySlice & GameLiveEntrySlice>> => {
  return create<GameStore & GameEntrySlice & GameLiveEntrySlice>()(
    immer((...a) => ({
      ...createGameEntrySlice(...a),
      ...createGameLiveEntrySlice(...a),
      ...Object.assign(initialGameState, overrideGameState),
      send: action => a[0](state => reducer(state, a[1]().type, action)),
    }))
  )
}

export * from './index.types'
