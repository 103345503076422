import { BORDER_COLORS, FARE_COLORS, SPACING, TEXT_COLORS } from '@/design'
import { motion } from 'framer-motion'
import { SFormSection } from '../style'

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2px;
  margin: 20px 0 5px;
  background-color: ${BORDER_COLORS.one};
  padding: 2px;
  border-radius: 2px;
`

export const SFormGridSection = styled(SFormSection)`
  padding-left: ${SPACING.md}px;
  padding-top: 0;
`

export const GridWrapper = styled.div`
  position: relative;
`

export const CheckboxContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: -15px;
  display: flex;
  align-items: center;
`

export const StyledCheckbox = styled.input`
  margin-right: 4px;
  transform: scale(0.8);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid ${FARE_COLORS.gray};
  border-radius: 3px;
  background-color: transparent;
  cursor: pointer;
  position: relative;

  &:checked {
    background-color: ${FARE_COLORS.gray};

    &::after {
      content: '✓';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 12.8px;
    }
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
  }
`

export const CheckboxLabel = styled.label`
  font-family: 'Gohu';
  font-size: 10px;
  color: ${TEXT_COLORS.two};
`

export const Tile = styled(motion.div)<{ $isSelected: boolean; $isDisabled: boolean }>`
  width: 100%;
  padding-bottom: 100%;
  // background-color: ${props => (props.$isSelected ? FARE_COLORS.gray : FARE_COLORS.black)};
  background-color: ${FARE_COLORS.black};
  border-radius: ${props => (props.$isSelected ? '4px' : '2px')};
  box-shadow: ${props => (props.$isSelected ? 'inset 0 0 15px rgba(74, 245, 211, 0.5)' : '')};
  cursor: ${props => (props.$isDisabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.$isDisabled ? 0.5 : 1)};
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle, transparent 0%, rgba(0, 0, 0, 0.1) 100%);
    opacity: ${props => (props.$isSelected ? 1 : 0)};
    transition: opacity 0.3s ease;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20%;
    height: 20%;
    background-color: ${FARE_COLORS.aqua}80;
    border-radius: 50%;
    opacity: ${props => (props.$isSelected ? 1 : 0)};
    transition: opacity 0.3s ease;
  }

  & > .border-draw {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:hover {
    transform: scale(1.01);
    // background-color: ${props => (props.$isSelected ? '#572222' : '#1b1d26')};
    background-color: '#1b1d26';
    box-shadow: inset 0 0 15px rgba(74, 245, 211, 0.5);
  }
`
