import Avatar from '../shared/Avatar'
import CountUp from 'react-countup'
import useLeaderboard from '@/hooks/useLeaderboard'
import { SVGS } from '@/assets'
import { getUserImageToRender, getUsernameToRender } from '@/utils/userDisplay'
import numeral from 'numeral'
import {
  SLeaderboardBody,
  SLeaderboardGrid,
  SLeaderboardGridHeader,
  SLeaderboardRow,
  SUserDataWrapper,
  SUserKDA,
  SUserPoints,
} from './style'
import useCurrencyStore from '@/store/useCurrencyStore'
import SkeletonLeaderboardGrid from './skeleton'
import { SClickToCopy } from '@/components/Mobile/Dropdown/style'
import { useActiveWallet } from '@/lib/privy/hooks'

export const MIN_LEADERBOARD_POINT = 10

export const SAvatar = styled(Avatar)`
  > .avatar-wrapper {
    height: 36px;
    width: 36px;
  }
  img {
    height: 22px;
    width: 22px;
  }
`

const LeaderboardGrid = () => {
  const { leaderboard } = useLeaderboard(true)
  const selectedCurrency = useCurrencyStore.use.selectedCurrency()
  const { walletAddress } = useActiveWallet()

  const skeletonRows = Array.from({ length: 16 }, (_, idx) => (
    <SkeletonLeaderboardGrid key={idx} height='44px' />
  ))

  const leaderboardRows = useMemo(() => {
    if (!leaderboard) return skeletonRows

    return leaderboard
      .filter(rowData => rowData.totalPoints >= 10)
      .map((rowData, idx) => {
        const userImageToRender = getUserImageToRender(
          rowData.fundOwnerAddress,
          rowData.isUsingTwitterToDisplay,
          rowData.avatarSeedAddress,
          rowData.twitterImage,
          {},
          { height: '20px', width: '20px' }
        )
        const usernameToRender = getUsernameToRender(
          rowData.fundOwnerAddress,
          rowData.isUsingTwitterToDisplay,
          rowData.username,
          rowData.twitterUsername
        )

        return (
          <SLeaderboardRow key={rowData.fundOwnerAddress}>
            <SUserDataWrapper>
              <span>#{idx + 1}</span>
              <div className='rank-avatar-wrapper'>
                <img
                  className='rank-border'
                  alt='rank-border'
                  src={(SVGS as any)[`level${rowData.level}Icon`]}
                  style={{ opacity: rowData.level === 0 ? 0.25 : 1 }}
                  id={String(rowData.level)}
                />
                {userImageToRender}
              </div>
              <SClickToCopy copyText={walletAddress} className='leaderboard'>
                <div className='user-data-text'>{usernameToRender}</div>
              </SClickToCopy>
            </SUserDataWrapper>
            <SUserKDA>{numeral(rowData.usdcBalance || '0').format('0,0')}</SUserKDA>
            <SUserKDA>
              {/* <span className='kills-color'>{numeral(rowData.killPoints).format('0,0')}</span> /{' '} */}
              {/* <span className='deaths-color'>{numeral(rowData.deathPoints).format('0,0')}</span> /{' '} */}
              {/* <span className='assists-color'>{numeral(rowData.referralPoints).format('0,0')}</span> */}
              <span className='kills-color'>{rowData.killPoints}</span> /{' '}
              <span className='deaths-color'>{rowData.deathPoints}</span> /{' '}
              <span className='assists-color'>{rowData.referralPoints}</span>
            </SUserKDA>
            <SUserPoints>
              <CountUp end={rowData.totalPoints} />
            </SUserPoints>
          </SLeaderboardRow>
        )
      })
  }, [leaderboard, skeletonRows, walletAddress])

  return (
    <SLeaderboardGrid>
      <SLeaderboardGridHeader>
        <span>USER</span>
        <span>{selectedCurrency.toUpperCase()}</span>
        <span>K / D / A</span>
        <span>POINTS</span>
      </SLeaderboardGridHeader>
      <SLeaderboardBody>{leaderboardRows}</SLeaderboardBody>
    </SLeaderboardGrid>
  )
}

export default LeaderboardGrid
