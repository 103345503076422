import { SVGS } from '@/assets'
import { FreePlayWrapper, NetworkDropdownWrapper } from './style'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { useNetworkSwitch } from '@/hooks/useNetworkSwitch'
import { AnimatePresence } from 'framer-motion'
import {
  MobileDropDownContent,
  MobileDropDownHeader,
  MobileDropdownMenu,
} from '../CurrencySwitch/style'
import { NetworkDropDownItems } from '../CurrencySwitch/NetworkDropDownItems'
import { mobileDropdownVariant } from '../../Mobile/Dropdown/style'
import { SGradientWalletModalContent } from '@/components/Privy/SelectWalletModal'
import { useActiveWallet } from '@/lib/privy/hooks'
import { SupportedAppChainId } from '@/chains/types'

export const FreePlay = () => {
  const isMobileScreen = useIsBreakpoint('xs')
  const { isOpen, setIsOpen } = useNetworkSwitch()
  const { walletChainId } = useActiveWallet()

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  const isFreePlay = walletChainId !== SupportedAppChainId.ARBITRUM_MAINNET

  return (
    <AnimatePresence>
      {isMobileScreen && (
        <FreePlayWrapper onClick={handleToggle}>
          <div>{isFreePlay ? 'CHANGE NETWORK' : 'FREE PLAY'}</div>
          <AnimatePresence>
            {isOpen && (
              <NetworkDropdownWrapper
                initial={{ y: 0 }}
                animate={{ y: 0 }}
                exit={{ y: 500 }}
                transition={{ damping: 25, type: 'spring' }}
                drag='y'
                dragConstraints={{ top: 0, bottom: 0 }}
                dragElastic={0.5}
                onDragEnd={(_, info) => {
                  if (info.offset.y > 100) setIsOpen(!isOpen)
                }}
              >
                <AnimatePresence>
                  {isOpen && (
                    <SGradientWalletModalContent
                      variants={mobileDropdownVariant}
                      initial='initial'
                      animate='animate'
                      exit='exit'
                    >
                      <MobileDropdownMenu>
                        <MobileDropDownContent>
                          <img
                            style={{ marginInline: 'auto' }}
                            src={SVGS.dragBar}
                            alt='drag bar'
                            width={48}
                          />
                          <MobileDropDownHeader>SELECT A NETWORK</MobileDropDownHeader>
                          <NetworkDropDownItems />
                        </MobileDropDownContent>
                      </MobileDropdownMenu>
                    </SGradientWalletModalContent>
                  )}
                </AnimatePresence>
              </NetworkDropdownWrapper>
            )}
          </AnimatePresence>
        </FreePlayWrapper>
      )}
    </AnimatePresence>
  )
}
