import { Button, ButtonEnum } from '../Button'
import { usePrivy } from '@privy-io/react-auth'
import { useAuthWallet } from '@/lib/privy/hooks/useAuthWallet'

const SConnectWallet = styled(Button)`
  font-weight: bold;
  &.default-min-width {
    min-width: 150px;
  }
`

const ConnectWallet = ({ ...props }) => {
  const { isWalletAuthed, linkOrLoginWallet, sessionVerifyState } = useAuthWallet()
  const { isModalOpen } = usePrivy()
  const disableLogin = isWalletAuthed

  if (disableLogin || sessionVerifyState === 'pending') return null

  return (
    <SConnectWallet
      type='button'
      buttonType={ButtonEnum.CONNECT_WALLET}
      disabled={disableLogin}
      onClick={linkOrLoginWallet}
      isLoading={isModalOpen}
      loadingText={'CONNECTING'}
      {...props}
    >
      SIGN IN
    </SConnectWallet>
  )
}

export default ConnectWallet
