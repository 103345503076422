import CountUp from 'react-countup'
import { useForm, Controller } from 'react-hook-form'
import {
  FormAmountLabel,
  FormFooter,
  FormIndicator,
  FormLabel,
  FormLabelRow,
  FormTab,
  FormWrapper,
  SFormSection,
} from '../style'
import numeral from 'numeral'
import { type ISUContractForm } from '@/store/useSUContractStore'
import { RPSSelection } from '@/lib/crypto/rps'
import { useRPSGameState } from '@/store/useGameStateStore'
import { GameButton } from '../../shared/Button/GameButton'
import { FareNumberInput } from '../../shared/Input/FareNumberInput'
import { ModeButton } from '../../shared/Button/style'
import { clamp, ensureNumber } from '@/utils'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { useNetworkStyle } from '@/hooks/useCurrency'
import { FormLayout } from '../FormComponents/shared/FormLayout'
import { SDoubleInputRow } from '../FormComponents/shared/FormLayout/styles'
import { DEFAULT_MAX_COUNT } from '@/lib/crypto/constants'
import { useBufferZone } from '@/hooks/useBufferZone'
import { useBalances } from '@/hooks/useBalances'
import { useIsGameAnimating } from '@/hooks/useIsGameAnimating'

export const RPSForm = () => {
  const networkStyle = useNetworkStyle()
  const balances = useBalances()
  const isMobileScreen = useIsBreakpoint('sm')

  const balanceNumber = Number(balances.currency)

  const { selectedSide, setSelectedSide } = useRPSGameState(state => ({
    selectedSide: state.entry.side,
    setSelectedSide: (side: number) => state.setEntry({ side }),
  }))

  const { control, register, watch } = useForm<ISUContractForm>({
    defaultValues: {
      side: 0,
      entryAmount: 0,
      numberOfEntries: 1,
      stopGain: 0,
      stopLoss: 0,
    },
  })
  const { isGameAnimating } = useIsGameAnimating()
  const formData = watch()
  const { entryAmount, numberOfEntries } = formData
  const { bufferedZone: bufferedMaxEntryAmount, stepZone } = useBufferZone(formData.side)

  const entryAmountNum = numeral(entryAmount).value() || 0

  return (
    <FormWrapper>
      <FormLayout>
        <FormTab className='mobile-tabs'>
          <ModeButton
            $isActive={selectedSide === RPSSelection.Rock}
            onClick={() => setSelectedSide(RPSSelection.Rock)}
            disabled={isGameAnimating}
          >
            <span>Rock</span>
          </ModeButton>
          <ModeButton
            $isActive={selectedSide === RPSSelection.Paper}
            onClick={() => setSelectedSide(RPSSelection.Paper)}
            disabled={isGameAnimating}
          >
            <span>Paper</span>
          </ModeButton>
          <ModeButton
            $isActive={selectedSide === RPSSelection.Scissors}
            onClick={() => setSelectedSide(RPSSelection.Scissors)}
            disabled={isGameAnimating}
          >
            <span>Scissors</span>
          </ModeButton>
        </FormTab>
        <form onSubmit={e => e.preventDefault()}>
          <SDoubleInputRow className='mobile-form'>
            <SFormSection>
              <FormLabelRow>
                <FormLabel>
                  <FormIndicator $isActive={entryAmountNum > 0} />
                  <FormAmountLabel>Amount</FormAmountLabel>
                </FormLabel>
                <FormLabel>
                  <FormAmountLabel>
                    {balanceNumber > 0 && (
                      <FormAmountLabel>
                        <CountUp
                          end={balanceNumber}
                          decimals={2}
                          duration={2}
                          separator={','}
                          preserveValue
                        />
                      </FormAmountLabel>
                    )}
                  </FormAmountLabel>
                </FormLabel>
              </FormLabelRow>
              <Controller
                {...register('entryAmount', { required: true, max: 1_000_000_000, min: 0 })}
                control={control}
                defaultValue={0}
                render={({ field }) => {
                  const value = numeral(field.value).value() || 0
                  const isDisabled =
                    (numeral(balances.currency).value() || 0) === 0 || isGameAnimating

                  return (
                    <FareNumberInput
                      {...field}
                      onChange={event => field.onChange(numeral(event.target.value).value() as any)}
                      allowLeadingZeros={false}
                      allowNegative={false}
                      isAllowed={({ floatValue = 0 }) => {
                        return floatValue <= 1_000_000_000
                      }}
                      onFocus={event => event.target.select()}
                      hasInputSlider
                      disabled={isDisabled}
                      inputSliderProps={{
                        value,
                        onChange: sliderValue => field.onChange(sliderValue),
                        min: 0,
                        max: bufferedMaxEntryAmount,
                        step: stepZone,
                        disabled: isDisabled,
                      }}
                      thousandSeparator=','
                      inputSuffix={
                        <img
                          src={networkStyle.currencyIcon}
                          alt={networkStyle.currencyName}
                          width={20}
                        />
                      }
                    />
                  )
                }}
              />
              {/* {row.error} ERROR SHOULD BE HERE */}
            </SFormSection>
            <SFormSection>
              <FormLabelRow>
                <FormLabel $isRelative>
                  <FormIndicator $isActive={numberOfEntries > 0} style={{ left: -12, top: 4 }} />
                  <FormAmountLabel>
                    {isMobileScreen ? 'Rounds' : 'Number of Entries'}
                  </FormAmountLabel>
                </FormLabel>
                <FormLabel>
                  {Number(entryAmount / (numberOfEntries || 1)) > 0 && (
                    <FormAmountLabel>
                      <CountUp
                        end={Number(entryAmount / (numberOfEntries || 1))}
                        decimals={2}
                        duration={0.18}
                        separator={','}
                        preserveValue
                      />
                      <span>&nbsp;/ {isMobileScreen ? 'round' : 'entry'}</span>
                    </FormAmountLabel>
                  )}
                </FormLabel>
              </FormLabelRow>
              <Controller
                {...register('numberOfEntries')}
                control={control}
                defaultValue={1}
                render={({ field }) => {
                  const MIN = 1
                  const MAX = 20
                  const value = numeral(field.value).value()
                  const clampedValue = clamp(ensureNumber(value), MIN, MAX)
                  const { ref, ...props } = field
                  return (
                    <FareNumberInput
                      {...field}
                      allowLeadingZeros={false}
                      allowNegative={false}
                      isAllowed={({ floatValue = 0 }) => {
                        return floatValue <= 20
                      }}
                      onFocus={event => event.target.select()}
                      hasInputSlider
                      inputSliderProps={{
                        value: clampedValue,
                        onChange: sliderValue => field.onChange(sliderValue),
                        min: 1,
                        max: DEFAULT_MAX_COUNT,
                        step: 1,
                        disabled: isGameAnimating,
                      }}
                      thousandSeparator=','
                      disabled={isGameAnimating}
                    />
                  )
                }}
              />
            </SFormSection>
          </SDoubleInputRow>
        </form>
      </FormLayout>
      <FormFooter>
        <GameButton
          formData={{ ...formData, side: selectedSide }}
          entryAmountNum={entryAmountNum}
        />
      </FormFooter>
    </FormWrapper>
  )
}
