import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { FareNumberInputNew } from '@/components/shared/Input/FareNumberInputNew'
import {
  FormAmountLabel,
  FormFooter,
  FormIndicator,
  FormLabel,
  FormLabelRow,
  FormWrapper,
  SFormSection,
} from '@/components/Forms/style'
import numeral from 'numeral'
import CountUp from 'react-countup'
import { useCrashGameStore } from '@/store/useCrashGameStore'
import { GameButton } from '@/components/shared/Button/GameButton'
import { FormLayout } from '@/components/Forms/FormComponents/shared/FormLayout'
import * as FareNumberInput from '@/components/shared/Input/FareNumberInput'
import { useNetworkStyle } from '@/hooks/useCurrency'
import { useBalances } from '@/hooks/useBalances'
import { useBufferZone } from '@/hooks/useBufferZone'
import { useIsGameAnimating } from '@/hooks/useIsGameAnimating'
import { useShallow } from 'zustand/react/shallow'

interface CrashFormData {
  side: number
  entryAmount: number
  numberOfEntries: number
  stopLoss: number
  stopGain: number
  autoCashoutMultiplier: number
}

const CrashFormComponent: React.FC = () => {
  const { control, watch } = useForm<CrashFormData>({
    defaultValues: {
      side: 200,
      entryAmount: 0,
      numberOfEntries: 1,
      stopLoss: 0,
      stopGain: 0,
      autoCashoutMultiplier: 2,
    },
  })
  useLocation()
  const networkStyle = useNetworkStyle()
  const formData = watch()
  const balances = useBalances()
  const balanceNumber = Number(balances.currency)
  // Watch only necessary fields to minimize re-renders
  const entryAmount = watch('entryAmount')
  const autoCashoutMultiplier = watch('autoCashoutMultiplier')
  const { bufferedZone: bufferedMaxEntryAmount, stepZone } = useBufferZone(
    Math.round(formData.autoCashoutMultiplier * 100)
  )
  const { setAutoCashoutMultiplier } = useCrashGameStore(
    useShallow(state => ({ setAutoCashoutMultiplier: state.setAutoCashoutMultiplier }))
  )
  const { isGameAnimating } = useIsGameAnimating()

  return (
    <FormWrapper>
      <FormLayout>
        <SFormSection>
          <FormLabelRow>
            <FormLabel>
              <FormIndicator $isActive={entryAmount > 0} />
              <FormAmountLabel>Amount</FormAmountLabel>
            </FormLabel>
            <FormLabel>
              {balanceNumber > 0 && (
                <FormAmountLabel>
                  <CountUp
                    end={balanceNumber}
                    decimals={2}
                    duration={2}
                    separator={','}
                    preserveValue
                  />
                </FormAmountLabel>
              )}
            </FormLabel>
          </FormLabelRow>
          <Controller
            name='entryAmount'
            control={control}
            rules={{ required: true, max: bufferedMaxEntryAmount, min: 0 }}
            defaultValue={0}
            render={({ field }) => {
              const {
                // ref,
                ...props
              } = field
              const value = numeral(field.value).value() || 0
              const isDisabled = (numeral(balances.currency).value() || 0) === 0 || isGameAnimating
              return (
                <FareNumberInput.FareNumberInput
                  {...props}
                  // getInputRef={ref}
                  onChange={event => {
                    field.onChange(numeral(event.target.value).value() as any)
                  }}
                  allowLeadingZeros={false}
                  allowNegative={false}
                  thousandSeparator=','
                  decimalScale={2}
                  hasInputSlider
                  disabled={isDisabled}
                  inputSuffix={
                    <img
                      src={networkStyle.currencyIcon}
                      alt={networkStyle.currencyName}
                      width={20}
                    />
                  }
                  inputSliderProps={{
                    value,
                    onChange: sliderValue => {
                      field.onChange(sliderValue)
                    },
                    min: 0,
                    max: bufferedMaxEntryAmount,
                    step: stepZone,
                    disabled: isDisabled,
                  }}
                />
              )
            }}
          />
        </SFormSection>
        <SFormSection>
          <FormLabelRow>
            <FormIndicator
              $isActive={autoCashoutMultiplier > 1.01 && autoCashoutMultiplier <= 500}
            />
            <FormLabel>Cashout Multiplier</FormLabel>
          </FormLabelRow>
          <Controller
            name='autoCashoutMultiplier'
            control={control}
            rules={{ required: true, min: 1.01, max: 500 }}
            render={({ field }) => {
              return (
                <FareNumberInputNew
                  {...field}
                  onChange={event => {
                    const value = numeral(event.target.value).value() ?? 0
                    field.onChange(value)
                    setAutoCashoutMultiplier(value)
                  }}
                  allowLeadingZeros={false}
                  allowNegative={false}
                  decimalScale={2}
                  hasInputSlider
                  disabled={isGameAnimating}
                  inputSliderProps={{
                    value: field.value,
                    onChange: sliderValue => {
                      field.onChange(sliderValue)
                      setAutoCashoutMultiplier(sliderValue)
                    },
                    min: 1.01,
                    max: 500,
                    step: 0.01,
                    disabled: isGameAnimating,
                  }}
                />
              )
            }}
          />
        </SFormSection>
      </FormLayout>
      <FormFooter>
        <GameButton
          formData={{
            ...formData,
            side: Math.round(formData.autoCashoutMultiplier * 100),
          }}
          entryAmountNum={entryAmount}
        />
      </FormFooter>
    </FormWrapper>
  )
}

export const CrashForm = memo(CrashFormComponent)
