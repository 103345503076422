import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import * as THREE from 'three'
import { useFrame } from '@react-three/fiber'
import { useGLTF } from '@react-three/drei'
import { type GLTF } from 'three-stdlib'
import { type GroupProps } from '@react-three/fiber'
import { useBombsGameStore } from '@/store/useBombsGameStore'
import { type Ripple } from './Ripple'
import { BombModel } from './BombModel'
import { BombCoinModel } from './BombCoinModel'
import { useBombsGameState } from '@/store/useGameStateStore'

// GLTF Structure result
type GLTFResult = GLTF & {
  nodes: {
    ['bomb-tube-geometry001']: THREE.Mesh
    ['bomb-tube-geometry001_1']: THREE.Mesh
    ['bomb-tube-geometry001_2']: THREE.Mesh
    ['accent-blocks001']: THREE.Mesh
    ['hinge-covers001']: THREE.Mesh
    ['hinge-left002']: THREE.Mesh
    Plane009: THREE.Mesh
    Plane009_1: THREE.Mesh
    ['hinge-right002']: THREE.Mesh
    Plane005: THREE.Mesh
    Plane005_1: THREE.Mesh
    Light: THREE.Mesh
  }
  materials: {
    Lighting: THREE.MeshStandardMaterial
    BaseBlack: THREE.MeshPhysicalMaterial
    Transparent: THREE.MeshStandardMaterial
    BaseGray: THREE.MeshStandardMaterial
    lightBeam: THREE.MeshStandardMaterial
  }
}

const BOMB_COLOR = new THREE.Color(0xff4848) // Loss
const SAFE_COLOR = new THREE.Color(0x1af51a) // Win
const DEFAULT_COLOR = new THREE.Color(0xffffff)
const DEFAULT_EMISSIVE = new THREE.Color(0.05, 0.05, 0.05)
const DEFAULT_EMISSIVE_INTENSITY = 2.3

// Grid size constant
const GRID_SIZE = 5

// Props interface for BombTubeModel
interface BombTubeModelProps extends GroupProps {
  isSelected: boolean
  isDisabled?: boolean
  onClick?: () => void
  index: number
  ripples: Ripple[]
  floatSpeed?: number
  floatHeight?: number
  rippleSpeed?: number
  rippleHeight?: number
  isOpen: boolean
  revealedContent: 'safe' | 'bomb' | null
  speedMultiplier: number
  numberOfEntries: number
}

// Main component
export const BombTubeModel: React.FC<BombTubeModelProps> = memo(
  ({
    isSelected = false,
    isDisabled = false,
    onClick: onSelect,
    index,
    ripples,
    floatSpeed = 0.5,
    floatHeight = 0.25,
    rippleSpeed = 0.4,
    rippleHeight = 0.15,
    isOpen,
    revealedContent,
    speedMultiplier = 1,
    numberOfEntries,
    ...props
  }) => {
    const group = useRef<THREE.Group>(null)
    const { nodes, materials } = useGLTF(import.meta.env.VITE_GAME_ASSETS_CDN + '/glb/Bombs_Frontend_Prototype.glb') as GLTFResult
    const [openProgress, setOpenProgress] = useState(0)
    const leftDoorRef = useRef<THREE.Mesh | null>(null)
    const rightDoorRef = useRef<THREE.Mesh | null>(null)
    const [shouldOpen, setShouldOpen] = useState(false)

    // Game state
    const { revealedTilesCount, selectedTiles } = useBombsGameStore(state => ({
      revealedTilesCount: state.revealedTilesCount,
      selectedTiles: state.selectedTiles,
      bombCount: state.bombCount,
    }))
    const { type: gameStateType } = useBombsGameState()

    const selectedTilesCount = selectedTiles.filter(Boolean).length

    // Clone materials to avoid affecting other instances
    const uniqueMaterials = useMemo(
      () => ({
        Lighting: materials.Lighting.clone(),
        BaseBlack: materials.BaseBlack.clone(),
        BaseGray: materials.BaseGray.clone(),
        Transparent: materials.Transparent.clone(),
      }),
      [materials]
    )

    // Create lightbeam material
    const lightbeamMaterial = useMemo(
      () =>
        new THREE.MeshStandardMaterial({
          color: DEFAULT_COLOR,
          emissive: DEFAULT_COLOR,
          emissiveIntensity: 0.2,
          transparent: true,
          opacity: 0.3,
          side: THREE.DoubleSide,
        }),
      []
    )

    // Determine when to show the lightbeam
    const showLightbeam = isOpen && revealedContent !== null && openProgress > 0.5 && isSelected

    // Update lighting material based on selection state
    useEffect(() => {
      if (gameStateType === 'IDLE') {
        const isSelected = selectedTiles[index]
        if (uniqueMaterials.Lighting) {
          uniqueMaterials.Lighting.emissive.setRGB(
            isSelected ? 1 : 0.05,
            isSelected ? 1 : 0.05,
            isSelected ? 1 : 0.05
          )
          uniqueMaterials.Lighting.needsUpdate = true
        }
      }
    }, [selectedTiles, index, uniqueMaterials.Lighting, gameStateType])

    const handleClick = useCallback(() => {
      if (!isDisabled && onSelect) {
        onSelect()
      }
    }, [isDisabled, onSelect])

    const calculateExplosionTiming = useCallback(() => {
      const remainingTiles = selectedTilesCount - revealedTilesCount
      const revealDelay = 200
      const bombRevealDelay = 600
      const remainingTime = remainingTiles * revealDelay + bombRevealDelay + 600

      return remainingTime
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [revealedTilesCount])

    useEffect(() => {
      if (group.current) {
        leftDoorRef.current = group.current.getObjectByName('hinge-left002') as THREE.Mesh
        rightDoorRef.current = group.current.getObjectByName('hinge-right002') as THREE.Mesh
      }
    }, [])

    useEffect(() => {
      setShouldOpen(isSelected || revealedContent === 'bomb')
    }, [isSelected, revealedContent])

    // Animation and update logic
    useFrame((state, delta) => {
      if (group.current) {
        // Adjust delta based on speedMultiplier
        const adjustedDelta = delta * speedMultiplier * 2

        // Calculate float and ripple offsets
        const floatOffset =
          Math.sin(state.clock.elapsedTime * floatSpeed * speedMultiplier) * floatHeight
        let rippleOffset = 0
        ripples.forEach(ripple => {
          const x = index % GRID_SIZE
          const z = Math.floor(index / GRID_SIZE)
          const distance = Math.sqrt(
            Math.pow(x - ripple.origin[0], 2) + Math.pow(z - ripple.origin[1], 2)
          )
          const rippleStrength = Math.max(0, 1 - distance / GRID_SIZE)
          const ripplePhase = Math.max(0, Math.min(1, (ripple.time - distance * 0.1) / rippleSpeed))
          rippleOffset +=
            Math.sin(ripplePhase * Math.PI) * rippleStrength * rippleHeight * (ripple.strength || 1)
        })
        group.current.position.y = floatOffset + rippleOffset

        // Handle open/close animation
        if (isOpen && openProgress < 1) {
          setOpenProgress(prev => Math.min(prev + adjustedDelta * 2, 1))
        } else if (!isOpen && openProgress > 0) {
          setOpenProgress(prev => Math.max(prev - adjustedDelta * 2, 0))
        }

        // Handle door animation
        const targetRotation = shouldOpen && isOpen ? Math.PI * 0.5 : 0
        const rotationSpeed = 10 * speedMultiplier * delta // Adjust this value to change the door animation speed

        // if (numberOfEntries > 1) {
        //   if (leftDoor && rightDoor && (isSelected || revealedContent === 'bomb')) {
        //     leftDoor.rotation.z = Math.PI * 0.5 * openProgress
        //     rightDoor.rotation.z = -Math.PI * 0.5 * openProgress
        //   }
        // } else {
        //   if (leftDoor && rightDoor && (isSelected || revealedContent === 'bomb')) {
        //     leftDoor.rotation.z = Math.PI * 0.5 * openProgress
        //     rightDoor.rotation.z = -Math.PI * 0.5 * openProgress
        //   }
        if (leftDoorRef.current && rightDoorRef.current) {
          leftDoorRef.current.rotation.z = THREE.MathUtils.lerp(
            leftDoorRef.current.rotation.z,
            targetRotation,
            rotationSpeed
          )
          rightDoorRef.current.rotation.z = THREE.MathUtils.lerp(
            rightDoorRef.current.rotation.z,
            -targetRotation,
            rotationSpeed
          )
        }

        // Update materials color when opened
        if (openProgress > 0.5 && revealedContent) {
          const color = revealedContent === 'bomb' ? BOMB_COLOR : SAFE_COLOR
          if (isSelected) {
            uniqueMaterials.Lighting.color.copy(color)
            uniqueMaterials.Lighting.emissive.copy(color)
            uniqueMaterials.Lighting.emissiveIntensity = 0.5
          }
          lightbeamMaterial.color.copy(color)
          lightbeamMaterial.emissive.copy(color)
        }

        // Update lightbeam material
        if (lightbeamMaterial && showLightbeam) {
          const pulseIntensity = (Math.sin(state.clock.elapsedTime * 1.5) + 1) / 2
          lightbeamMaterial.emissiveIntensity = 0.1 + pulseIntensity * 0.1
          lightbeamMaterial.opacity = 0.2 + pulseIntensity * 0.1
        }

        // Handle reset phase
        if (gameStateType === 'RESET' || (!isOpen && openProgress > 0)) {
          setOpenProgress(prev => Math.max(prev - adjustedDelta * 2, 0))
          const resetSpeed = numberOfEntries > 1 ? 0.5 : 0.2
          uniqueMaterials.Lighting.color.lerp(DEFAULT_COLOR, resetSpeed)
          uniqueMaterials.Lighting.emissive.lerp(DEFAULT_EMISSIVE, resetSpeed)
          uniqueMaterials.Lighting.emissiveIntensity = THREE.MathUtils.lerp(
            uniqueMaterials.Lighting.emissiveIntensity,
            DEFAULT_EMISSIVE_INTENSITY,
            resetSpeed
          )
          lightbeamMaterial.color.lerp(DEFAULT_COLOR, resetSpeed)
          lightbeamMaterial.emissive.lerp(DEFAULT_COLOR, resetSpeed)
        }
      }
    })

    return (
      <group
        ref={group}
        {...props}
        onClick={event => {
          event.stopPropagation()
          handleClick()
        }}
      >
        <group name='Scene001'>
          <group name='bomb-tube001'>
            <mesh
              name='bomb-tube-geometry001'
              castShadow
              receiveShadow
              geometry={nodes['bomb-tube-geometry001'].geometry}
              material={uniqueMaterials.Lighting}
            />
            <mesh
              name='bomb-tube-geometry001_1'
              castShadow
              receiveShadow
              geometry={nodes['bomb-tube-geometry001_1'].geometry}
              material={uniqueMaterials.BaseBlack}
            />
            <mesh
              name='bomb-tube-geometry001_2'
              castShadow
              receiveShadow
              geometry={nodes['bomb-tube-geometry001_2'].geometry}
              material={uniqueMaterials.Transparent}
            />
            <mesh
              name='accent-blocks001'
              castShadow
              receiveShadow
              geometry={nodes['accent-blocks001'].geometry}
              material={uniqueMaterials.BaseGray}
              position={[0, 0.145, 0]}
            />
            <mesh
              name='hinge-covers001'
              castShadow
              receiveShadow
              geometry={nodes['hinge-covers001'].geometry}
              material={uniqueMaterials.BaseBlack}
              position={[0, 1.273, 0]}
            />
            <mesh
              name='hinge-left002'
              castShadow
              receiveShadow
              geometry={nodes['hinge-left002'].geometry}
              material={uniqueMaterials.BaseGray}
              position={[-0.911, 1.236, 0]}
            >
              <group name='door-left002' position={[0.911, 0.037, 0]}>
                <mesh
                  name='Plane009'
                  castShadow
                  receiveShadow
                  geometry={nodes.Plane009.geometry}
                  material={uniqueMaterials.Lighting}
                />
                <mesh
                  name='Plane009_1'
                  castShadow
                  receiveShadow
                  geometry={nodes.Plane009_1.geometry}
                  material={uniqueMaterials.BaseBlack}
                />
              </group>
            </mesh>
            <mesh
              name='hinge-right002'
              castShadow
              receiveShadow
              geometry={nodes['hinge-right002'].geometry}
              material={uniqueMaterials.BaseGray}
              position={[0.908, 1.236, 0]}
            >
              <group name='door-right002' position={[-0.908, 0.037, 0]}>
                <mesh
                  name='Plane005'
                  castShadow
                  receiveShadow
                  geometry={nodes.Plane005.geometry}
                  material={uniqueMaterials.Lighting}
                />
                <mesh
                  name='Plane005_1'
                  castShadow
                  receiveShadow
                  geometry={nodes.Plane005_1.geometry}
                  material={uniqueMaterials.BaseBlack}
                />
              </group>
            </mesh>
            {showLightbeam && (
              <mesh
                name='Light'
                castShadow
                receiveShadow
                geometry={nodes.Light.geometry}
                material={lightbeamMaterial}
              />
            )}
          </group>
        </group>
        {isOpen && openProgress > 0.5 && revealedContent && (
          <group position={[0, 1 + openProgress * 0.5, 0]}>
            {revealedContent === 'bomb' ?
              <group scale={0.4} position={[0, 0.5, 0]} rotation={[-Math.PI / 3, 0, 0]}>
                <BombModel
                  isRevealed={isSelected}
                  explosionTiming={calculateExplosionTiming()}
                  speedMultiplier={speedMultiplier}
                  showFlame={isSelected && gameStateType === 'RESOLVE'}
                />
              </group>
            : revealedContent === 'safe' ?
              <group scale={0.3} position={[0, 0.35, 0]} rotation={[0, 0, 0]}>
                <BombCoinModel />
              </group>
            : null}
          </group>
        )}
      </group>
    )
  }
)

useGLTF.preload(import.meta.env.VITE_GAME_ASSETS_CDN + '/glb/Bombs_Frontend_Prototype.glb')
