import { LOCAL_STORAGE } from '@/constants/storage'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export type RandomnessType = 'keccak' | 'vrf'

export const RANDOMNESS_TYPES = {
  VRF: 'vrf' as RandomnessType,
  KECCAK: 'keccak' as RandomnessType,
} as const

export const DEFAULT_RANDOMNESS_TYPE = RANDOMNESS_TYPES.KECCAK

export const setRandomnessTypeInLocalStorage = (
  randomnessType: RandomnessType,
  publicAddress: string
) => {
  let randomnessTypeStr = localStorage.getItem(LOCAL_STORAGE.RANDOMNESS_TYPE)
  if (!randomnessTypeStr) {
    localStorage.setItem(LOCAL_STORAGE.RANDOMNESS_TYPE, JSON.stringify({}))
    randomnessTypeStr = localStorage.getItem(LOCAL_STORAGE.RANDOMNESS_TYPE)
  }
  localStorage.setItem(
    LOCAL_STORAGE.RANDOMNESS_TYPE,
    JSON.stringify({ ...JSON.parse(randomnessTypeStr!), [publicAddress]: randomnessType })
  )
}

export const getRandomnessTypeFromLocalStorage = (publicAddress: string) => {
  // NOTE: VRF is becoming the bottle neck so this is a quickfix reset to default everyone to KECCAK
  const hasResetRandomnessType = localStorage.getItem(LOCAL_STORAGE.HAS_RESET_RANDOMNESS_TYPE)
  if (hasResetRandomnessType !== 'true') {
    localStorage.removeItem(LOCAL_STORAGE.RANDOMNESS_TYPE)
    localStorage.setItem(LOCAL_STORAGE.HAS_RESET_RANDOMNESS_TYPE, 'true')
  }

  const randomnessTypeStr = localStorage.getItem(LOCAL_STORAGE.RANDOMNESS_TYPE)
  if (!randomnessTypeStr) return DEFAULT_RANDOMNESS_TYPE

  let selectedRandomnessType = DEFAULT_RANDOMNESS_TYPE

  try {
    selectedRandomnessType = JSON.parse(randomnessTypeStr)[publicAddress] || DEFAULT_RANDOMNESS_TYPE
  } catch (err) {
    console.warn(err)
  }

  return selectedRandomnessType || DEFAULT_RANDOMNESS_TYPE
}

export type RandomnessTypeState = {
  randomnessType: RandomnessType
}

export type RandomnessTypeStateActions = {
  setRandomnessType: (randomnessType: RandomnessType, publicAddress: string) => void
}

// @TODO: If user has not selected before, should it default to VRF or Keccak?
// @TODO: Allow randomnesType to be updated to the localStorage's value whenever user changes account
export const initialRandomnessTypeState: RandomnessTypeState = {
  randomnessType: DEFAULT_RANDOMNESS_TYPE,
}

export type RandomnessTypeStore = RandomnessTypeState & RandomnessTypeStateActions

const useRandomnessTypeStore = create<RandomnessTypeStore>()(
  immer(set => ({
    ...initialRandomnessTypeState,
    setRandomnessType: (randomnessType, publicAddress) =>
      set(state => {
        state.randomnessType = randomnessType
        setRandomnessTypeInLocalStorage(randomnessType, publicAddress)
      }),
  }))
)

export default useRandomnessTypeStore
