import { formatEther } from 'viem'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { useBombsGameState } from './useGameStateStore'
import { bombCountToRevealCountToMultiplier } from '@/lib/crypto/bombs'
import { type Ripple } from '@/components/Canvases/BombsCanvas/Ripple'
import type { GameAction, GameStateAction } from '@/lib/fare/state'

interface BombsGameState {
  selectedTiles: boolean[]
  bombCount: number
  entryAmount: number
  revealedTiles: ('safe' | 'bomb' | null)[]
  revealedTilesCount: number
  didPlayerWin: boolean | null
  currentRevealIndex: number
  isRevealingBombs: boolean
  ripples: Ripple[]
  previousSelectedTiles: boolean[]
  rippleStrength: number
  isReselectingTiles: boolean
  numberOfEntries: number
  allResults: ('safe' | 'bomb' | null)[][]
  speedMultiplier: number
  backendResult: any
}

interface BombsGameActions {
  selectTile: (index: number) => void
  setBombCount: (count: number) => void
  setGameState: (phase: GameAction) => void
  setEntryAmount: (amount: number) => void
  resolveEntry: (payload: {
    resultSides: any[]
    playedCount: number
    deltaAmounts: number[]
    totalDeltaAmount: number
  }) => void
  setRevealedTiles: (tiles: ('safe' | 'bomb' | null)[]) => void
  revealNextTile: () => number
  revealAllBombs: () => void
  checkGameOutcome: () => boolean
  resetGame: () => void
  addRipple: (origin: [number, number], strength?: number) => void
  updateRipples: () => void
  calculateOdds: () => number
  reselectPreviousTiles: () => void
  toggleIsReselectingTiles: () => void
  setNumberOfEntries: (count: number) => void
  setSpeedMultiplier: (multiplier: number) => void
  // setBackendResult: (payload: any) => void
}

type BombsGameStore = BombsGameState & BombsGameActions
const send = (payload: any) => useBombsGameState.getState().send?.(payload)
const gameState = () => useBombsGameState.getState().type

export const useBombsGameStore = create<BombsGameStore>()(
  devtools(
    immer((set, get) => ({
      selectedTiles: Array(25).fill(false),
      bombCount: 5,
      gameState: 'IDLE',
      entryAmount: 0,
      revealedTiles: Array(25).fill(null),
      revealedTilesCount: 0,
      didPlayerWin: null,
      currentRevealIndex: 0,
      isRevealingBombs: false,
      ripples: [],
      previousSelectedTiles: [],
      isReselectingTiles: true,
      rippleStrength: 1,
      numberOfEntries: 1,
      allResults: [],
      speedMultiplier: 1,
      backendResult: null,

      selectTile: index =>
        set(state => {
          console.log('INDEX:::3 clicked to index: ', index)
          if (gameState() === 'IDLE') {
            const wasSelected = state.selectedTiles[index]
            const newSelected = !wasSelected
            state.selectedTiles[index] = newSelected
            // Add a new ripple only when the tile's state actually changes
            if (newSelected !== wasSelected) {
              state.ripples.push({
                origin: [index % 5, Math.floor(index / 5)],
                time: 0,
                strength: state.rippleStrength,
              })
            }
          }
        }),

      setBombCount: count =>
        set(state => {
          state.bombCount = count
        }),

      setGameState: phase =>
        set(state => {
          send?.({
            type: phase,
            payload: {},
          } as GameStateAction)

          if (phase === 'IDLE') {
            state.selectedTiles = Array(25).fill(false)
          }
        }),

      setEntryAmount: amount =>
        set(state => {
          state.entryAmount = amount
        }),

      resolveEntry: (payload: {
        resultSides: any[]
        playedCount: number
        deltaAmounts: number[]
        totalDeltaAmount: number
      }) =>
        set(state => {
          send?.({
            type: 'RESOLVE',
            payload: {
              resultSides: payload.resultSides,
              playedCount: payload.playedCount,
              deltaAmounts: payload.deltaAmounts,
              totalDeltaAmount: payload.totalDeltaAmount,
            },
          } as GameStateAction)
          state.currentRevealIndex = 0
          state.isRevealingBombs = false
          state.speedMultiplier = payload.resultSides.length > 1 ? 1.5 : 1

          // @NOTE: Convert bombIndexes to be an array where bombIndexes are filled with 'bomb' and other indexes to be filled with 'safe'. So that, we are providing what game expects
          const results = Array(payload.resultSides.length)
            .fill(Array(25).fill('safe'))
            .map((resultArr, resultIndex) => {
              return resultArr.map((_: any, index: any) =>
                payload.resultSides[resultIndex].includes(index) ? 'bomb' : 'safe'
              )
            })
          console.log('GAME STATE::: original results: ', payload.resultSides)
          console.log('GAME STATE::: converted results: ', results)
          state.revealedTiles = results[0] // Set the first result
          state.allResults = results // Store all results
          state.backendResult = {
            resultSides: payload.resultSides,
            playedCount: payload.playedCount,
            deltaAmounts: payload.deltaAmounts,
            totalDeltaAmount: payload.totalDeltaAmount,
          } // Store the backend's result in this state. Currently, we are making use of deltaAmount to use deltaAmountCounter correctly
        }),

      // setBackendResult: payload =>
      //   set(state => {
      //     state.backendResult = payload
      //   }),

      setRevealedTiles: tiles =>
        set(state => {
          state.revealedTiles = tiles
        }),

      revealNextTile: () => {
        let result = -1
        set(state => {
          if (gameState() === 'RESOLVE' && !state.isRevealingBombs) {
            while (state.currentRevealIndex < state.selectedTiles.length) {
              if (state.selectedTiles[state.currentRevealIndex]) {
                const tileType = state.revealedTiles[state.currentRevealIndex]
                state.revealedTilesCount++
                state.currentRevealIndex++
                result = tileType === 'safe' ? 1 : 0 // Set result to 1 for coin, 0 for bomb
                return // Exit the set function
              }
              state.currentRevealIndex++
            }
            // If we've revealed all selected tiles, prepare to reveal bombs
            state.isRevealingBombs = true
          }
        })
        return result
      },

      revealAllBombs: () =>
        set(state => {
          state.revealedTiles = state.revealedTiles.map((tile, index) => {
            if (tile === 'bomb' || (tile === 'safe' && state.selectedTiles[index])) {
              return tile
            }
            return state.revealedTiles[index] === 'bomb' ? 'bomb' : null
          })
        }),

      checkGameOutcome: () => {
        const state = get()
        const selectedBombs = state.selectedTiles.reduce(
          (count, selected, index) =>
            selected && state.revealedTiles[index] === 'bomb' ? count + 1 : count,
          0
        )
        const didPlayerWin = selectedBombs === 0
        return didPlayerWin
      },

      resetGame: () =>
        set(state => {
          state.previousSelectedTiles = [...state.selectedTiles]
          state.didPlayerWin = null
          state.currentRevealIndex = 0
          state.isRevealingBombs = false
        }),

      addRipple: (origin: [number, number], strength = 1) =>
        set(state => {
          const newRipple: Ripple = { origin, time: 0, strength }
          state.ripples = [...state.ripples, newRipple]
        }),

      updateRipples: () =>
        set(state => {
          state.ripples = state.ripples
            .map(ripple => {
              if (ripple.time >= 1) {
                return null
              }
              return { ...ripple, time: ripple.time + 0.025 }
            })
            .filter(Boolean) as Ripple[]
        }),

      calculateOdds: () => {
        const state = get()
        const bombCount = state.bombCount
        const selectedCount = state.selectedTiles.filter(Boolean).length
        const totalCells = 25

        if (selectedCount === 0 || bombCount >= totalCells) {
          return 0
        }

        const multiplier = bombCountToRevealCountToMultiplier[bombCount][selectedCount]
        return Number(formatEther(multiplier))
      },

      reselectPreviousTiles: () =>
        set(state => {
          if (gameState() === 'IDLE') {
            const selectedTilesCount = state.previousSelectedTiles.filter(Boolean).length
            const reducedStrength = 1 / (selectedTilesCount * 0.5) // reduce strength since we're triggering several at the same time

            state.previousSelectedTiles.forEach((isSelected, index) => {
              if (isSelected) {
                state.selectedTiles[index] = true
                const rippleStrength = reducedStrength * 1.25
                const newRipple: Ripple = {
                  origin: [index % 5, Math.floor(index / 5)],
                  time: 0,
                  strength: rippleStrength,
                }
                state.ripples = [...state.ripples, newRipple]
              }
            })

            // Reset ripple strength after reselection
            state.rippleStrength = 1
          }
        }),

      toggleIsReselectingTiles: () =>
        set(state => {
          state.isReselectingTiles = !state.isReselectingTiles
        }),

      setNumberOfEntries: count =>
        set(state => {
          state.numberOfEntries = count
        }),

      setSpeedMultiplier: multiplier =>
        set(state => {
          state.speedMultiplier = multiplier
        }),
    }))
  )
)
