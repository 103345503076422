import { SVGS } from '@/assets'
import { Button, ButtonEnum } from '@/components/shared/Button'
import { BORDER_COLORS, FARE_COLORS, TEXT_COLORS } from '@/design'
import { deviceBP } from '@/design/breakpoints'
import { useActiveWallet } from '@/lib/privy/hooks'
import { addAppNoti } from '@/store/useNotiStore'
import { noUserSelect } from '@/style'

const DepositBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #1c1c1e;
  ${noUserSelect}

  @media ${deviceBP.sm} {
    width: 100%;
  }
`

const DepositTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: white;
  line-height: 1.2;
  font-family: system-ui, sans-serif;

  @media ${deviceBP.sm} {
    font-size: 18px;
    margin-inline: 12px;
  }
`

const DepositContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
  font-size: 16px;
  color: ${TEXT_COLORS.two};

  p {
    margin: 8px 0;
    font-family: system-ui, sans-serif;
    width: 90%;
  }

  @media ${deviceBP.sm} {
    font-size: 14px;
    margin-inline: 12px;
    word-wrap: break-word;
  }
`

const CopyInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
`

const InputAddress = styled.input`
  background: #2c2c2e;
  border: 1px solid ${BORDER_COLORS.one};
  color: ${TEXT_COLORS.one};
  border-radius: 12px;
  padding: 8px 16px;
  font-size: 14px;
  flex: 1;
  height: 40px;
  font-family: system-ui, sans-serif;

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  &:focus {
    outline: none;
    border: 1px solid ${FARE_COLORS.aqua};
  }

  @media (min-width: 768px) {
    width: auto;
  }
`

export const TransferModalFunds = () => {
  const { walletAddress, networkStyle } = useActiveWallet()
  const copyToClipboard = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(walletAddress)
      addAppNoti({
        type: 'success',
        msg: 'Copied to clipboard',
      })
    } catch (err) {
      addAppNoti({
        type: 'error',
        msg: 'Failed to copy',
      })
    }
  }, [walletAddress])

  return (
    <DepositBoxWrapper>
      <DepositTitle>Deposit {networkStyle.currencyName} (Arbitrum)</DepositTitle>

      <DepositContent>
        <p>Buy USDC on Coinbase, Binance, or another exchange.</p>
        <p>Send/Withdraw USDC to the address below and select Arbitrum as the network.</p>
      </DepositContent>

      <CopyInputWrapper>
        <InputAddress value={walletAddress} disabled />
        <Button
          type='button'
          buttonType={ButtonEnum.BASE}
          onClick={copyToClipboard}
          disabled={false}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
          }}
        >
          <img src={SVGS.copyIcon} alt='Copy to clipboard' width={40} />
        </Button>
      </CopyInputWrapper>
    </DepositBoxWrapper>
  )
}
