import { LOCAL_STORAGE } from '@/constants/storage'
import axios from 'axios'
import { inviteCodesApi } from '@/lib/http/inviteCodes'
import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'
import useInviteCodeStore from '@/store/useInviteCodeStore'
import { useDebouncedCallback } from 'use-debounce'

export const PointsPageMounter = () => {
  const appChainConfig = useAppChainConfigStore(state => state.appChainConfig)
  const httpUrl = useMemo(() => appChainConfig.httpUrl, [appChainConfig])

  const fetchInviteCodes = useDebouncedCallback(
    async () => {
      try {
        const inviteCodes = await inviteCodesApi.getInviteCodes()
        useInviteCodeStore.getState().setInviteCodes({
          usedInviteCodes: inviteCodes.usedInviteCodes,
          activeInviteCodes: inviteCodes.activeInviteCodes,
        })
      } catch (err) {
        // @TODO: Add better error handling
        console.error(err)
      }
    },
    250,
    { leading: true }
  )

  const fetchPointsDetails = useDebouncedCallback(async () => {
    try {
      const { data } = await axios({
        withCredentials: true,
        method: 'GET',
        url: `${httpUrl}/deathmatch/points-detail`,
      })
      console.log('received data: ', data)
      useInviteCodeStore.getState().setPointsDetails(data)
    } catch (err) {
      // @TODO: Add better error handling
      console.error(err)
    }
  }, 250)

  const fetchQuests = useDebouncedCallback(async () => {
    try {
      const { data } = await axios({
        method: 'GET',
        withCredentials: true,
        url: `${httpUrl}/deathmatch/quest-detail`,
      })
      console.log('received data: ', data)
      useInviteCodeStore.getState().setQuests(data.quests)
      useInviteCodeStore.getState().setBonusQuests(data.bonusQuests)
    } catch (err) {
      // @TODO: Add better error handling
      console.error(err)
    }
  }, 250)

  useEffect(() => {
    fetchInviteCodes()
    fetchPointsDetails()
    fetchQuests()
  }, [httpUrl])

  return null
}
