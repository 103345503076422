import { GameContractListener } from '@/components/Singletons/GameContractListener'
import { type PropsWithChildren } from 'react'
import { GamePage } from '@/pages'
import { type GameModes } from '../Spinner'
import { LeftContainer, PageWrapper, RightContainer } from '@/pages/style'
import { RefactorLiveEntries } from '../LiveEntries/RefactorLiveEntries'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'

interface IGamePageWrapperProps extends PropsWithChildren {
  mode: GameModes
  CanvasComponent: React.FC<{
    isMounted: boolean
    setIsMounted: any
  }>
  FormComponent: React.FC
}

const GamePageComponent: React.FC<IGamePageWrapperProps> = ({
  mode,
  CanvasComponent,
  FormComponent,
}) => {
  const isMobileScreen = useIsBreakpoint('sm')

  return (
    <PageWrapper>
      <GamePage mode={mode}>
        <LeftContainer>
          <CanvasComponent isMounted={true} setIsMounted={() => {}} />
          {!isMobileScreen && <RefactorLiveEntries />}
        </LeftContainer>
        <RightContainer>
          <FormComponent />
        </RightContainer>
      </GamePage>
      <GameContractListener />
    </PageWrapper>
  )
}

export default GamePageComponent
