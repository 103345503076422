import { SVGS } from '@/assets'
import ModalCard from '@/components/shared/Modal/Card'

interface IMobileLeaderboardModal {
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void
}

export default function MobileLeaderboardModal({
  isVisible,
  setIsVisible,
}: IMobileLeaderboardModal) {
  const handleClose = () => {
    localStorage.setItem('mobile-leaderboard-modal-dismissed', 'true')
    setIsVisible(false)
  }

  return (
    <ModalCard
      title='Join the board'
      description='Do you want to see your username on the leaderboard?'
      isVisible={isVisible}
      setIsVisible={handleClose}
    >
      <div style={{ textAlign: 'left' }}>
        <p>1. Swipe left to the game screen.</p>
        <p>2. Fund your wallet and place a bet.</p>
        <p>3. Take over the Leaderboard!</p>
      </div>
      <div style={{ display: 'inline-flex', gap: '12px', marginTop: '8px' }}>
        <img src={SVGS.diceIcon} alt='Leaderboard' width={32} />
        <img src={SVGS.coin} alt='Leaderboard' width={32} />
        <img src={SVGS.rockIcon} alt='Leaderboard' width={32} />
      </div>
    </ModalCard>
  )
}
