import * as THREE from 'three'
import React, { useRef, useEffect } from 'react'
import { useAnimations, useGLTF } from '@react-three/drei'
import { type GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    ['bomb-coin']: THREE.Mesh
  }
  materials: {
    GoldCoin: THREE.MeshPhysicalMaterial
  }
}

type ActionName = 'Flip'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

export function BombCoinModel(props: JSX.IntrinsicElements['group']) {
  const group = useRef<THREE.Group>(null)
  const { nodes, materials, animations } = useGLTF(import.meta.env.VITE_GAME_ASSETS_CDN + '/glb/Bomb-Coin.glb') as GLTFResult
  const { actions } = useAnimations(animations, group)

  useEffect(() => {
    // Play the 'Flip' animation when the component mounts
    if (actions && actions['Flip']) {
      actions.Flip.setLoop(THREE.LoopOnce, 1)
      actions.Flip.clampWhenFinished = true
      actions.Flip.reset().play()
    }
  }, [actions])

  return (
    <group ref={group} {...props} dispose={null}>
      <group name='Scene'>
        <mesh
          name='bomb-coin'
          castShadow
          receiveShadow
          geometry={nodes['bomb-coin'].geometry}
          material={materials.GoldCoin}
          rotation={[Math.PI / 2, 0, 0]}
        />
      </group>
    </group>
  )
}

useGLTF.preload(import.meta.env.VITE_GAME_ASSETS_CDN + '/glb/Bomb-Coin.glb')
