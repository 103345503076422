import { BREAKPOINTS, TEXT_COLORS } from '@/design'
import { deviceBP } from '@/design/breakpoints'
import { phoneBP } from '@/design/phoneSizes'
import { motion } from 'framer-motion'

export const ModalOverlayContainer = styled(motion.div)`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
`

export const ModalWrapper = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
`

export const ModalContent = styled.div<{ $maxHeight?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: black;
  height: 300px;
  width: 600px;
  border-radius: 6px;
  overflow: hidden;

  &.fund-modal-content {
    padding: 2rem;
    width: 440px;
    height: fit-content;
    min-height: 830px;
    text-align: left;
    background-color: #1c1c1e;
    border-radius: 2.5rem !important;
    overflow: hidden;
    border-radius: 16px;
    text-align: justify;

    @media (max-width: 760px) {
      min-height: 400px;
      max-height: ${props => props.$maxHeight || '650px'};
    }

    @media ${deviceBP.sm} {
      padding: 16px;
      width: 320px;
    }

    @media ${phoneBP.xs} and (max-height: 605px) {
      height: 500px;
    }
  }

  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 320px;
    text-align: center;
  }
`
export const ModalHeader = styled.div`
  padding: 0 16px;
  width: 100%;
`

export const ModalTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  color: white;
  line-height: 1;

  @media ${deviceBP.sm} {
    font-size: 18px;
    margin-inline: 12px;
  }
`

export const ModalDescription = styled.p`
  font-size: 16px;
  color: ${TEXT_COLORS.two};
  margin: 8px 0;
  text-align: justify;

  @media ${deviceBP.sm} {
    font-size: 14px;
    margin-inline: 12px;
    text-wrap: pretty;
  }
`

export const ModalBody = styled.div`
  overflow-y: auto;
  flex-grow: 1;
  width: 100%;
  padding: 16px;

  @media ${deviceBP.sm} {
    padding: 8px;
  }
`

export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .submit-button {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
  }

  .next-button {
    display: flex;
    justify-content: flex-end;
  }
`
