import { Bar, MenuSandwich } from '@/components/Mobile/MobileMenu/style'
import { RightHeader } from './style'
import { useGetGameStoreType } from '@/store/useGameStateStore'

interface IMobileHeaderProps {
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>
  isShowMenu: boolean
}

interface GameMenuDropdownProps
  extends Omit<IMobileHeaderProps, 'setShowProfile' | 'isShowProfile'> {
  rightHeaderRef: React.RefObject<HTMLDivElement>
}

export const GameMenuDropdown: React.FC<GameMenuDropdownProps> = ({
  setShowMenu,
  isShowMenu,
  rightHeaderRef,
}) => {
  const { pathname } = useLocation()
  const gameStoreType = useGetGameStoreType(pathname)
  const shouldDisable = useMemo(() => gameStoreType !== 'IDLE', [gameStoreType])

  return (
    <RightHeader>
      <MenuSandwich
        onClick={() => setShowMenu(!isShowMenu)}
        ref={rightHeaderRef}
        $isDisabled={shouldDisable}
      >
        {[...Array(3)].map((_, idx) => (
          <Bar key={idx} isActive={isShowMenu}></Bar>
        ))}
      </MenuSandwich>
    </RightHeader>
  )
}
