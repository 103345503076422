import { defaultAppChainConfig } from '@/chains/configs'
import { PostHogConfig } from 'posthog-js'

interface PostHogReactConfig {
  apiKey: string
  options: Partial<PostHogConfig>
}

export const getPosthogConfig: () => PostHogReactConfig = () => {
  if (!defaultAppChainConfig.features.enablePosthog)
    return { apiKey: '', options: { api_host: '' } }

  return {
    apiKey: defaultAppChainConfig.services.posthog.key,
    options: {
      api_host: defaultAppChainConfig.services.posthog.host,
    },
  }
}
