import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'
import axios from 'axios'
import { ILiveEntry } from '../fsocket/types'
import { utils } from 'ethers'
import numeral from 'numeral'

const routes = {
  transactionHistory: 'transaction-history',
  stats: 'stats',
} as const

export interface IAnalytics {
  biggestWinAmount: string
  biggestLossAmount: string
  highestMultiplier: string
  totalBetVolume: string
  totalBetCount: number
  averageBetAmount: string
  mostPlayedGameName: string
  longestWinStreak: number
  longestLoseStreak: number
  dailyStreak: number
}

export const analyticsApi = {
  async getUserAnalyticsStats(publicAddress: string) {
    const {
      httpUrl,
      networkStyle: { decimals },
    } = useAppChainConfigStore.getState().appChainConfig

    const { data } = await axios({
      method: 'GET',
      withCredentials: true,
      url: `${httpUrl}/analytics/${routes.stats}`,
      params: {
        publicAddress,
      },
    })
    const stats = data as IAnalytics
    const gameStats: IAnalytics = {
      dailyStreak: stats.dailyStreak,
      totalBetCount: stats.totalBetCount,
      totalBetVolume: numeral(utils.formatUnits(stats.totalBetVolume, decimals)).format('0,0.00'),
      averageBetAmount: numeral(utils.formatUnits(stats.averageBetAmount, decimals)).format(
        '0,0.00'
      ),
      biggestWinAmount: numeral(utils.formatUnits(stats.biggestWinAmount, decimals)).format(
        '0,0.00'
      ),
      biggestLossAmount: numeral(utils.formatUnits(stats.biggestLossAmount, decimals)).format(
        '0,0.00'
      ),
      longestWinStreak: stats.longestWinStreak,
      longestLoseStreak: stats.longestLoseStreak,
      highestMultiplier: numeral(utils.formatUnits(stats.highestMultiplier, 18)).format('0,0.00'),
      mostPlayedGameName: stats.mostPlayedGameName,
    }

    return gameStats as IAnalytics
  },

  async getUserTransactionHistory(publicAddress: string) {
    const httpUrl = useAppChainConfigStore.getState().appChainConfig.httpUrl

    const { data } = await axios({
      method: 'GET',
      withCredentials: true,
      url: `${httpUrl}/analytics/${routes.transactionHistory}`,
      params: {
        publicAddress,
      },
    })

    return data as ILiveEntry[]
  },
}
