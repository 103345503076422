import { AnimatePresence, motion } from 'framer-motion'
import CountUp from 'react-countup'
import { BigNumber } from 'ethers'

import { SVGS, PNGS } from '@/assets'
import useCurrencyStore from '@/store/useCurrencyStore'
import {
  BlockHeader,
  CollapsedWrapper,
  BalanceWrapper,
  OverviewLabel,
  OverviewRow,
  CollapsedBalanceWrapper,
  OverviewPanelWrapper,
  StatsOverviewRow,
  OverviewButtonWrapper,
  PanelContainer,
} from './style'
import { GameHistory } from '@/components/GameHistory'
import { Button, ButtonEnum } from '../../Button'
import { useLocalStorage } from 'usehooks-ts'
import useUserDataStore from '@/store/useUserDataStore'
import { multiplyBigNumberWithFixedPointNumber } from '@/lib/crypto'
import { useActiveWallet } from '@/lib/privy/hooks'
import { fundWalletModalState } from '@/components/Modals/FundWalletModal'

export const OverviewPanel = () => {
  const [isShowPanel, setShowPanel] = useLocalStorage<boolean>('showBalancePanel', true)
  const balances = useCurrencyStore(state => state.balances)
  const selectedCurrencyAmount = balances.currency
  const { networkStyle, activeWallet, isWalletLinked } = useActiveWallet()
  const {
    accWinningAmount,
    accLosingAmount,
    winThresholdForPoint,
    loseThresholdForPoint,
    bonusLevelPoints,
    levelMultiplier,
    cachedAssistPoints,
  } = useUserDataStore()
  const openFundWalletModal = () => (fundWalletModalState.isFundModalShowing = true)

  const overviewActionBtnElem = useMemo(() => {
    return (
      <Button
        onClick={openFundWalletModal}
        buttonType={ButtonEnum.CONNECT_WALLET}
        disabled={false}
        style={{ height: '42px' }}
      >
        <span>ADD FUNDS</span>
      </Button>
    )
  }, [openFundWalletModal])

  return (
    <AnimatePresence>
      <PanelContainer>
        <OverviewPanelWrapper className='solid-color'>
          <BlockHeader $isShowPanel={isShowPanel} onClick={() => setShowPanel(!isShowPanel)}>
            <motion.div
              key={String(isShowPanel)}
              transition={{
                duration: 0.25,
                ease: 'easeIn',
              }}
              initial={{
                opacity: 0,
                height: 0,
              }}
              animate={{
                opacity: 1,
                height: 'auto',
              }}
              exit={{
                opacity: 0,
                height: 0,
              }}
            >
              {isShowPanel ?
                <>
                  <img src={SVGS.rewardIcon} alt='wallet-icon' />
                  <p>BALANCE</p>
                </>
              : <CollapsedWrapper>
                  <CollapsedBalanceWrapper>
                    <img
                      src={networkStyle.currencyIcon}
                      alt={networkStyle.currencyName}
                      width={16}
                    />
                    <CountUp
                      end={Number(selectedCurrencyAmount)}
                      decimals={2}
                      duration={2}
                      separator={','}
                      suffix={` ${networkStyle.currencyName}`}
                      preserveValue
                    />
                    {/* <span style={{ color: COLORS.error, marginLeft: SPACING.xs }}>(-2.53%)</span> */}
                  </CollapsedBalanceWrapper>
                </CollapsedWrapper>
              }
            </motion.div>
            <img
              src={PNGS.chevronIcon}
              style={{
                transform: `rotate(${isShowPanel ? 180 : 0}deg)`,
                transition: '.2s ease-in-out',
              }}
            />
          </BlockHeader>
          {isShowPanel ?
            <motion.div
              key={String(isShowPanel)}
              transition={{
                duration: 0.25,
                ease: 'easeIn',
              }}
              initial={{
                opacity: 0,
                height: 0,
              }}
              animate={{
                opacity: 1,
                height: 'auto',
              }}
              exit={{
                opacity: 0,
                height: 0,
              }}
            >
              <BalanceWrapper>
                <>
                  <StatsOverviewRow className='left-border'>
                    <div>
                      <img
                        src={networkStyle.currencyIcon}
                        alt={networkStyle.currencyName}
                        width={16}
                      />
                      <CountUp
                        end={Number(selectedCurrencyAmount)}
                        decimals={2}
                        duration={2}
                        separator={','}
                        suffix={` ${networkStyle.currencyName}`}
                        preserveValue
                      />
                    </div>
                    <div>
                      <img src={SVGS.deathmatchIcon} alt='usdc' />
                      <CountUp
                        end={
                          multiplyBigNumberWithFixedPointNumber(
                            BigNumber.from(accWinningAmount || '0'),
                            String(levelMultiplier || 1)
                          )
                            .div(winThresholdForPoint || 1)
                            .toNumber() +
                            multiplyBigNumberWithFixedPointNumber(
                              BigNumber.from(accLosingAmount || '0'),
                              String(levelMultiplier || 1)
                            )
                              .div(loseThresholdForPoint || 1)
                              .toNumber() +
                            // Add Assists
                            (cachedAssistPoints || 0) +
                            // Add bonus level points
                            (bonusLevelPoints || 0) || 0
                        }
                        decimals={0}
                        duration={2}
                        separator={','}
                        suffix={' POINTS'}
                        preserveValue
                      />
                    </div>
                  </StatsOverviewRow>

                  <OverviewLabel>
                    <img src={SVGS.slotsIcon} alt='history' />
                    <span>HISTORY</span>
                  </OverviewLabel>
                  <OverviewRow className='left-border-large'>
                    <GameHistory />
                  </OverviewRow>
                </>
                {!activeWallet || !isWalletLinked ? null : (
                  <OverviewButtonWrapper>{overviewActionBtnElem}</OverviewButtonWrapper>
                )}
              </BalanceWrapper>
            </motion.div>
          : null}
        </OverviewPanelWrapper>
      </PanelContainer>
    </AnimatePresence>
  )
}
