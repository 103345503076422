import type { UnityConfig } from 'react-unity-webgl'

// const fareCdnUri = import.meta.env.VITE_S3_BUCKET_URL
// // const buildRoot = import.meta.env.VITE_S3_BUCKET_URL + '/plinkoFrontend'
// const buildRoot = '/plinkoUnityBuild/Build'

export default {
  // streamingAssetsUrl: `${fareCdnUri}/StreamingAssets`,
  loaderUrl: import.meta.env.VITE_GAME_ASSETS_CDN + '/plinkoUnityBuild/plinko-build.loader.js',
  dataUrl: import.meta.env.VITE_GAME_ASSETS_CDN + '/plinkoUnityBuild/plinko-build.data',
  frameworkUrl: import.meta.env.VITE_GAME_ASSETS_CDN + '/plinkoUnityBuild/plinko-build.framework.js',
  codeUrl: import.meta.env.VITE_GAME_ASSETS_CDN + '/plinkoUnityBuild/plinko-build.wasm',
  webGLContextAttributes: {
    alpha: true,
    antialias: true,
    depth: true,
    failIfMajorPerformanceCaveat: true,
    powerPreference: 'high-performance',
    premultipliedAlpha: true,
    preserveDrawingBuffer: true,
    stencil: true,
    desynchronized: true,
    xrCompatible: true,
  },
} as UnityConfig
