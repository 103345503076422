import { PNGS, SVGS } from '@/assets'
import { gameNameToSVGMap } from '@/chains/lib'
import { AppGameName } from '@/chains/types'
import { useNetworkStyle } from '@/hooks/useCurrency'
import { ILiveEntry } from '@/lib/fsocket/types'
import { analyticsApi } from '@/lib/http/analyticsApi'
import { utils } from 'ethers'
import numeral from 'numeral'
import { SBetLink, SLEData, SLEDataRow, SLEHeaderRow } from '../shared/LiveEntries/styles'
import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'
import { SUserTxHistory } from './styles'

export const UserTxHistory = ({ walletAddress = '' }: { walletAddress: string }) => {
  const [userTxHistory, setUserTxHistory] = useState<ILiveEntry[]>([])
  const decimals = useNetworkStyle().decimals
  const explorerUrl =
    useAppChainConfigStore.use.appChainConfig().chainDefinition.blockExplorers?.default?.url

  const fetchAndSetTxHistory = useCallback(async () => {
    try {
      const data = await analyticsApi.getUserTransactionHistory(walletAddress)
      setUserTxHistory(data)
    } catch (err) {
      console.error(err)
    }
  }, [walletAddress])

  useEffect(() => {
    if (!walletAddress) return
    fetchAndSetTxHistory()
  }, [walletAddress])

  const txElems = useMemo(
    () =>
      userTxHistory.map(game => {
        const formattedAmount = numeral(utils.formatUnits(game.deltaAmount || 0, decimals)).format(
          '0,0'
        )

        const entryAmount = numeral(
          Number(utils.formatUnits(game.multiplier || 0, decimals))
        ).format('0,0')

        const isWin = Number(game.deltaAmount) > 0

        const renderGameIcon = () => {
          const gameIconSrc =
            gameNameToSVGMap[game.gameName as AppGameName] || SVGS.questionMarkIcon
          return <img src={gameIconSrc} alt='game icon' width={16} />
        }

        return (
          <SLEDataRow key={game.resolvementTxHash}>
            <SLEData>
              <span style={{ marginRight: '8px' }}>{renderGameIcon()}</span> {game.gameName}
            </SLEData>
            <SLEData>
              <img src={SVGS.usdcIcon} width={16} />
              <span className='mr-left'>{entryAmount}</span>
            </SLEData>

            <SLEData className='le-center'>
              <span className={isWin ? 'le-win' : 'le-loss'}>
                {isWin ? '+' : ''}
                {formattedAmount}
              </span>
            </SLEData>

            <SLEData className='le-center'>
              <SBetLink href={`${explorerUrl}/tx/${game.resolvementTxHash}`} target='_blank'>
                <img src={PNGS.linkIcon} alt='Transaction link' />
              </SBetLink>
            </SLEData>
          </SLEDataRow>
        )
      }),
    [userTxHistory, explorerUrl, decimals]
  )

  return (
    <SUserTxHistory>
      <SLEHeaderRow>
        <div>GAME</div>
        <div>ENTRY</div>
        <div className='le-center'>RESULT</div>
        <div className='le-center'>TX</div>
      </SLEHeaderRow>
      {txElems}
    </SUserTxHistory>
  )
}
