import { BigNumber, type BigNumberish } from 'ethers'
import { createWithEqualityFn } from 'zustand/traditional'
import { shallow } from 'zustand/vanilla/shallow'
import { immer } from 'zustand/middleware/immer'

import { AppGameName } from '@/chains/types'
import { createSelectors } from './helpers/createSelectors'

export interface IGameHistory {
  multiplier: string
  deltaAmount: string
  resolvementTxHash: string
  gameName?: string
}

export interface IInProgressEntry {
  txHash: string
  fundOwnerAddress: string
  multiplier: string
  gameName: string
}

export interface IUserData {
  username: string
  avatarSeedAddress: string
  isUsingTwitterToDisplay: boolean
  twitterAccount: {
    username: string
    displayName: string
    image: string
  }
  deathmatchPoints: number
  latestSUEntries: IGameHistory[]
  allTimeSUEntries: IGameHistory[]
  accWinningAmount: string
  accLosingAmount: string
  winThresholdForPoint: string
  loseThresholdForPoint: string
  bonusLevelPoints: number
  levelMultiplier: number
  cachedAssistPoints: number
  inProgressEntries: IInProgressEntry[]
  currentGameName: AppGameName | void
}

export type UserDataState = {
  username?: string
  avatarSeedAddress?: string
  isUsingTwitterToDisplay: boolean
  twitterUsername?: string
  twitterDisplayName?: string
  twitterImage?: string
  deathmatchPoints?: number
  latestSUEntries: IGameHistory[]
  allTimeSUEntries: IGameHistory[]
  accWinningAmount?: string
  accLosingAmount?: string
  winThresholdForPoint?: string
  loseThresholdForPoint?: string
  bonusLevelPoints?: number
  levelMultiplier?: number
  cachedAssistPoints?: number
  inProgressEntries?: IInProgressEntry[]
  currentGameName: AppGameName | void
}

export type UserDataActions = {
  setUsername: (username: string) => void
  setAvatarSeedAddress: (avatarSeedAddress: string) => void
  setIsUsingTwitterToDisplay: (isUsingTwitterToDisplay: boolean) => void
  setTwitterUsername: (twitterUsername: string) => void
  setTwitterDisplayName: (twitterDisplayName: string) => void
  setTwitterImage: (twitterImage: string) => void
  setTwitterAccount: (twitterAccount: {
    username?: string
    displayName?: string
    image?: string
  }) => void
  setDeathmatchPoints: (deathmatchPoints: number) => void
  setLatestSUEntries: (latestSUEntries: IGameHistory[]) => void
  setAllTimeSUEntries: (allTimeSUEntries: IGameHistory[]) => void
  addLatestSUEntries: (suEntry: IGameHistory) => void
  setUserData: (userData: IUserData) => void
  increaseAccWinningAmount: (increaseAmount: BigNumberish) => void
  increaseAccLosingAmount: (increaseAmount: BigNumberish) => void
  setBonusLevelPoints: (points: number) => void
  setLevelMultiplier: (multiplier: number) => void
  setCachedAssistPoints: (cachedAssistPoints: number) => void
  removeInProgressEntry: (submissionTxHash: string) => void
}

export const initialUserDataState: UserDataState = {
  username: undefined,
  avatarSeedAddress: undefined,
  isUsingTwitterToDisplay: false,
  twitterUsername: undefined,
  twitterDisplayName: undefined,
  twitterImage: undefined,
  deathmatchPoints: undefined,
  latestSUEntries: [],
  allTimeSUEntries: [],
  accWinningAmount: undefined,
  accLosingAmount: undefined,
  winThresholdForPoint: undefined,
  loseThresholdForPoint: undefined,
  bonusLevelPoints: undefined,
  levelMultiplier: undefined,
  cachedAssistPoints: undefined,
  inProgressEntries: undefined,
  currentGameName: undefined,
}

export type UserDataStore = UserDataState & UserDataActions

const useUserDataStoreBase = createWithEqualityFn<
  UserDataStore,
  [['zustand/immer', UserDataStore]]
>(
  immer(set => ({
    ...initialUserDataState,
    setUsername: username =>
      set(() => ({
        username,
      })),
    setAvatarSeedAddress: avatarSeedAddress =>
      set(() => ({
        avatarSeedAddress,
      })),
    setIsUsingTwitterToDisplay: isUsingTwitterToDisplay =>
      set(() => ({
        isUsingTwitterToDisplay,
      })),
    setTwitterUsername: twitterUsername =>
      set(() => ({
        twitterUsername,
      })),
    setTwitterDisplayName: twitterDisplayName =>
      set(() => ({
        twitterDisplayName,
      })),
    setTwitterImage: twitterImage =>
      set(() => ({
        twitterImage,
      })),
    setTwitterAccount: twitterAccount =>
      set(state => {
        state.twitterUsername = twitterAccount?.username
        state.twitterDisplayName = twitterAccount?.displayName
        state.twitterImage = twitterAccount?.image
      }),
    setDeathmatchPoints: deathmatchPoints =>
      set(() => ({
        deathmatchPoints,
      })),
    setLatestSUEntries: latestSUEntries =>
      set(() => ({
        latestSUEntries,
      })),
    setAllTimeSUEntries: allTimeSUEntries =>
      set(() => ({
        allTimeSUEntries,
      })),
    addLatestSUEntries: suEntry =>
      set(state => {
        state.latestSUEntries.unshift(suEntry)
        if (state.latestSUEntries.length > 10) {
          state.latestSUEntries.pop()
        }
        if (state.allTimeSUEntries) {
          state.allTimeSUEntries.unshift(suEntry)
        }
      }),
    increaseAccWinningAmount: increaseAmount =>
      set(state => {
        state.accWinningAmount = BigNumber.from(state.accWinningAmount)
          .add(increaseAmount)
          .toString()
      }),
    increaseAccLosingAmount: increaseAmount =>
      set(state => {
        state.accLosingAmount = BigNumber.from(state.accLosingAmount).add(increaseAmount).toString()
      }),
    setBonusLevelPoints: points =>
      set(state => {
        state.bonusLevelPoints = points
      }),
    setLevelMultiplier: multiplier =>
      set(state => {
        state.levelMultiplier = multiplier
      }),
    setCachedAssistPoints: cachedAssistPoints =>
      set(state => {
        state.cachedAssistPoints = cachedAssistPoints
      }),
    setUserData: userData =>
      set(state => {
        state.username = userData.username
        state.avatarSeedAddress = userData.avatarSeedAddress
        state.isUsingTwitterToDisplay = userData.isUsingTwitterToDisplay
        state.twitterUsername = userData.twitterAccount?.username
        state.twitterDisplayName = userData.twitterAccount?.displayName
        state.twitterImage = userData.twitterAccount?.image
        state.deathmatchPoints = userData.deathmatchPoints
        state.latestSUEntries = userData.latestSUEntries || []
        state.allTimeSUEntries = userData.latestSUEntries || []
        state.accLosingAmount = userData.accLosingAmount
        state.accWinningAmount = userData.accWinningAmount
        state.winThresholdForPoint = userData.winThresholdForPoint
        state.loseThresholdForPoint = userData.loseThresholdForPoint
        state.bonusLevelPoints = userData.bonusLevelPoints || 0
        state.levelMultiplier = userData.levelMultiplier || 0
        state.cachedAssistPoints = userData.cachedAssistPoints || 0
        state.inProgressEntries = userData.inProgressEntries || []
        state.currentGameName = userData.currentGameName
        // state.currentGameConfig = userData.currentGameConfig
      }),
    removeInProgressEntry: submissionTxHash =>
      set(state => {
        state.inProgressEntries = state.inProgressEntries?.filter(
          entry => entry.txHash !== submissionTxHash
        )
      }),
  })),
  shallow
)

export default createSelectors(useUserDataStoreBase)
