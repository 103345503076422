import {
  SendTransactionModalUIOptions,
  UnsignedTransactionRequest,
  useFundWallet,
  usePrivy,
} from '@privy-io/react-auth'
import { utils } from 'ethers'
import axios from 'axios'
import numeral from 'numeral'
import CountUp from 'react-countup'
import { SVGS } from '@/assets'
import { Button, ButtonEnum } from '@/components/shared/Button'
import { BACKGROUND_COLORS, BORDER_COLORS, FARE_COLORS, SPACING, TEXT_COLORS } from '@/design'
import { useBalances } from '@/hooks/useBalances'
import { toEthHex } from '@/lib/crypto'
import { useActiveWallet } from '@/lib/privy/hooks'
import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'
import { addAppNoti } from '@/store/useNotiStore'
import { noUserSelect } from '@/style'
import { shortenAddress } from '@/utils/text'
import { FareNumberInput } from '@/components/shared/Input/FareNumberInput'
import { Input } from '@/components/shared/Input'
import posthog from 'posthog-js'
import { deviceBP } from '@/design/breakpoints'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { usePostLog } from '@/lib/posthog/logging'

const ContainerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: fit-content;
  grid-gap: ${SPACING.sm}px;
  margin: 10px auto;

  @media (min-width: 1500px) {
    grid-template-columns: 2.5fr 1fr;
    margin: ${SPACING.xs}px;
  }
`

const DepositBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${FARE_COLORS.black};
  border-radius: 6px;
  padding: 18px;
  border: 1px solid ${BORDER_COLORS.one};
  ${noUserSelect}

  @media ${deviceBP.sm} {
    width: 250px;
  }
`

const DepositHeader = styled.div`
  margin-bottom: 16px;
  font-size: 12px;

  .aqua-bubble {
    border: 1px solid ${FARE_COLORS.aqua};
    border-radius: 4px;
    padding: 4px 8px;
    width: fit-content;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.1em;
    color: white;
  }
`

const DepositTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  color: white;
  margin-block: 6px;
  position: relative;
  line-height: 1;

  img {
    margin-left: 8px;

    @media ${deviceBP.sm} {
      margin-right: 44px;
    }
  }

  .badge {
    position: absolute;
    top: 14px;
    left: 332px;
    background-color: ${BORDER_COLORS.one};
    border-radius: 50%;
    padding: 2px;

    @media ${deviceBP.sm} {
      top: 24px;
      left: 190px;
    }
  }
`

const DepositContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-block: 16px;
`

const DepositContentItem = styled.div`
  display: flex;
  margin-block: 16px;
  align-items: center;

  .number {
    display: flex;
    flex-shrink: 0;
    border: 1px solid ${FARE_COLORS.aqua};
    background-color: ${FARE_COLORS.aqua}50;
    border-radius: 50%;
    padding: 6px;
    margin-right: 12px;
    color: white;
    justify-content: center;
    align-items: center;
    width: 10px;
    height: 10px;
    font-size: 10px;
  }

  .list-item {
    font-size: 12px;
    align-items: center;
    line-height: 1.5rem;
  }

  .logo-wrapper {
    display: inline-flex;
    align-items: center;
    position: relative;
    width: 40px;
    height: 24px;
  }

  .coinbase-logo,
  .binance-logo {
    position: absolute;
    width: 24px;
  }

  .coinbase-logo {
    top: -5px;
    left: 5px;
    z-index: 1;
  }

  .binance-logo {
    top: -5px;
    left: 25px;
    z-index: 0;
  }

  .base-bubble {
    display: inline-flex;
    border: 2px solid ${BORDER_COLORS.one};
    border-radius: 12px;
    padding: 1px 6px;
    width: fit-content;
    font-size: 12px;
    letter-spacing: 0.1em;
    color: white;
    align-items: center;

    img {
      margin-right: 4px;
    }
  }
`

const TextBold = styled.span`
  font-weight: 600;
  letter-spacing: 0.1em;
  color: white;
  font-size: 12px;
`

const CopyInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
`

const InputAddress = styled.input`
  background: ${BACKGROUND_COLORS.one};
  border: 1px solid ${BORDER_COLORS.one};
  color: ${TEXT_COLORS.one};
  border-radius: 6px;
  padding-inline: 8px;
  font-size: 13px;
  flex: 1;
  height: 40px;

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  &:focus {
    outline: none;
    border: 1px solid ${FARE_COLORS.aqua};
  }

  @media (min-width: 768px) {
    width: auto;
  }
`

const BalanceWrapper = styled.div`
  background-color: ${FARE_COLORS.black};
  height: auto;
  border-radius: 6px;
  padding: 12px;
  border: 1px solid ${BORDER_COLORS.one};

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${BORDER_COLORS.one};
    border-radius: 50%;
    width: 26px;
    height: 26px;
    border: none;
    transition: all 0.12s ease-in-out;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:hover:not(:disabled) {
      transform: scale(1.1);
      background-color: ${BORDER_COLORS.two};
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      outline: 1px solid ${BORDER_COLORS.three};
      outline-offset: 2px;
    }

    &:active:not(:disabled) {
      transform: scale(0.95);
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      background-color: ${BORDER_COLORS.one}80;
    }
  }

  .balance-amount {
    ${noUserSelect}
    font-size: 12px;
    color: white;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    .balance-countup-amount {
      font-size: 12px;
    }

    img {
      width: 16px;
      &.native-token-img {
        padding-right: 16px;
        height: 16px;
        width: auto;
      }
    }
  }

  img {
    width: 14px;
  }

  @media ${deviceBP.sm} {
    width: 262px;
  }
`

const BalanceContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;

  #input-num-format {
    padding-top: 8px;
    padding-bottom: 12px;
  }
  &.bottom-divider {
    border-bottom: 1px solid rgba(255, 255, 255, 0.18);
    padding-bottom: 16px;
    margin-bottom: 8px;
  }
  &.balance-content-column {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }
`

const BalanceButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  ${noUserSelect}
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${BORDER_COLORS.one};
    border-radius: 50%;
    width: 26px;
    height: 26px;
    border: none;
    transition: all 0.12s ease-in-out;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:hover {
      transform: scale(1.1);
      background-color: ${BORDER_COLORS.one};
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      outline: 1px solid ${BORDER_COLORS.three};
      outline-offset: 2px;
      background-color: ${BORDER_COLORS.two};
    }

    &:active {
      transform: scale(0.95);
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    }
  }
`

const BuyCryptoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${FARE_COLORS.black};
  border-radius: 6px;
  padding: 12px;
  border: 1px solid ${BORDER_COLORS.one};
  ${noUserSelect}

  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 12px;
  }

  @media ${deviceBP.sm} {
    width: 262px;
  }
`
const InlineContent = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  .divider {
    border: 1px solid ${BORDER_COLORS.one};
    flex-grow: 1;
  }

  .text {
    margin: 0 10px;
    color: ${BORDER_COLORS.one};
    font-weight: 700;
    font-size: 12px;
  }
`

const CryptoWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;

  @media (max-width: 1500px) {
    gap: 8px;
  }

  @media ${deviceBP.sm} {
    width: 250px;
  }
`

const PaymentMethods = styled.div`
  display: flex;
  gap: 8px;
  margin-left: 10px;

  img {
    width: 24px;
  }
`

export const SInput = styled(Input)`
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 12px;
  &:focus {
    outline: none;
    border: 1px solid ${FARE_COLORS.aqua};
    background: #010e0c;
  }
`

const WithdrawInput = memo(function WithdrawInput({
  amount,
  setAmount,
  tokenIcon,
  tokenName,
  max,
  decimals = 2,
}: {
  amount: number
  max: number
  setAmount: any
  tokenIcon: string
  tokenName: string
  decimals: number
}) {
  return (
    <FareNumberInput
      value={amount}
      onChange={event => {
        setAmount(numeral(event.target.value).value() as any)
      }}
      allowLeadingZeros={false}
      allowNegative={false}
      thousandSeparator=','
      decimalScale={decimals}
      hasInputSlider
      inputSuffix={<img src={tokenIcon} alt={tokenName} width={20} />}
      inputSliderProps={{
        value: amount,
        onChange: sliderValue => {
          setAmount(sliderValue)
        },
        min: 0,
        max,
        step: max / 100,
      }}
    />
  )
})

export const BuyUSDC = () => {
  const { walletAddress, networkStyle } = useActiveWallet()
  const { chainDefinition } = useAppChainConfigStore(state => ({
    appContracts: state.appContracts,
    chainDefinition: state.appChainConfig.chainDefinition,
  }))
  const { fundWallet } = useFundWallet({
    onUserExited: (...args) => {
      console.log('User exited fund wallet', args)
    },
  })

  const fundActiveWalletCurrency = () => {
    fundWallet(walletAddress, {
      asset: 'USDC',
      amount: '30.0',
      chain: chainDefinition,
    }).then(() => {
      posthog?.capture('user_funded_wallet_currency', {
        asset: 'USDC',
        amount: '30.0',
        chain: chainDefinition,
      })
    })
  }
  const fundActiveWalletNative = () => {
    fundWallet(walletAddress, {
      asset: 'native-currency',
      amount: '0.0042',
      chain: chainDefinition,
    }).then(() => {
      posthog?.capture('user_funded_wallet', {
        asset: 'native-currency',
        amount: '0.0042',
        chain: chainDefinition,
      })
    })
  }

  return (
    <BuyCryptoWrapper>
      <CryptoWrapper>
        <TextBold>NO CRYPTO?</TextBold>
        <Button
          type='button'
          buttonType={ButtonEnum.CONNECT_WALLET}
          disabled={false}
          onClick={fundActiveWalletCurrency}
          style={{
            width: '88px',
            backgroundColor: `${FARE_COLORS.aqua}50`,
            color: 'white',
            fontWeight: 'bold',
            padding: '0px',
            height: '28px',
          }}
        >
          BUY {networkStyle.currencyName}
        </Button>
        <Button
          type='button'
          buttonType={ButtonEnum.CONNECT_WALLET}
          onClick={fundActiveWalletNative}
          disabled={false}
          style={{
            width: '88px',
            backgroundColor: `${FARE_COLORS.aqua}50`,
            color: 'white',
            fontWeight: 'bold',
            padding: '0px',
            height: '28px',
          }}
        >
          BUY {networkStyle.nativeToken}
        </Button>
      </CryptoWrapper>
      <PaymentMethods>
        <img src={SVGS.visaLogo} />
        <img src={SVGS.mastercardLogo} />
        <img src={SVGS.paypalLogo} />
        <img src={SVGS.applePayLogo} />
        <img src={SVGS.googlePayLogo} />
      </PaymentMethods>
    </BuyCryptoWrapper>
  )
}

export const TransferFunds = () => {
  const isMobileScreen = useIsBreakpoint('sm')
  const { walletAddress, networkStyle } = useActiveWallet()
  const copyToClipboard = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(walletAddress)
      addAppNoti({
        type: 'success',
        msg: 'Copied to clipboard',
      })
    } catch (err) {
      addAppNoti({
        type: 'error',
        msg: 'Failed to copy',
      })
    }
  }, [walletAddress])

  return (
    <DepositBoxWrapper>
      <DepositHeader>
        <span className='aqua-bubble'>EASIEST METHOD</span> 1 MINUTE • FREE
      </DepositHeader>
      <DepositTitle>
        Deposit {networkStyle.currencyName} (Arbitrum)
        <img src={networkStyle.currencyIcon} width={24} alt={networkStyle.currencyName} />
        <img
          src={networkStyle.networkLogo}
          width={10}
          className='badge'
          alt={networkStyle.networkName}
        />
      </DepositTitle>

      <DepositContent>
        <DepositContentItem>
          <div className='number'>1</div>
          <div className='list-item'>
            <TextBold>BUY USDC</TextBold> on Coinbase, Binance or another exchange.
            <div className='logo-wrapper'>
              <div>
                <img src={SVGS.coinbaseCoinLogo} className='coinbase-logo' alt='coinbase logo' />
                <img src={SVGS.binanceLogo} className='binance-logo' alt='binance logo' />
              </div>
            </div>
          </div>
        </DepositContentItem>

        <DepositContentItem>
          <div className='number'>2</div>
          <div className='list-item'>
            <TextBold>SEND/WITHDRAW</TextBold> USDC to the address below and select{' '}
            <span className='base-bubble'>
              <img src={networkStyle.networkLogo} width={10} alt='base logo' />
              ARBITRUM
            </span>{' '}
            as the network
          </div>
        </DepositContentItem>
      </DepositContent>

      <CopyInputWrapper>
        <InputAddress value={walletAddress} disabled />
        <Button
          type='button'
          buttonType={ButtonEnum.CONNECT_WALLET}
          onClick={copyToClipboard}
          disabled={false}
          style={{
            width: '100px',
            backgroundColor: `${FARE_COLORS.aqua}50`,
            color: 'white',
            fontWeight: 'bold',
            height: '40px',
            paddingTop: '10px',
            marginLeft: isMobileScreen ? 'auto' : '',
          }}
        >
          COPY
        </Button>
      </CopyInputWrapper>
    </DepositBoxWrapper>
  )
}

export const DepositTab = () => {
  const { walletAddress, networkStyle, activeWallet, walletChainId } = useActiveWallet()
  const { appContracts, chainDefinition } = useAppChainConfigStore(state => ({
    appContracts: state.appContracts,
    chainDefinition: state.appChainConfig.chainDefinition,
  }))
  const privy = usePrivy()
  const { native, currency } = useBalances()
  const [receiverAddress, setReceiverAddress] = useState('')
  const [withdrawCurrencyAmount, setWithdrawCurrencyAmount] = useState(0)
  const [withdrawNativeAmount, setWithdrawNativeAmount] = useState(0)
  const { postlog } = usePostLog()
  const { fundWallet } = useFundWallet({
    onUserExited: (...args) => {
      console.log('User exited fund wallet', args)
    },
  })

  const nativeBalance = useMemo(() => numeral(native).value() || 0, [native])
  const currencyBalance = useMemo(() => numeral(currency).value() || 0, [currency])
  const isPrivyWallet = useMemo(
    () => activeWallet?.walletClientType === 'privy' || activeWallet?.connectorType === 'embedded',
    [activeWallet]
  )

  const exportPrivyWallet = () => {
    if (!privy.exportWallet) return

    privy.exportWallet({ address: walletAddress })

    postlog(`user_exported_privy_wallet`, {
      loglevel: 'success',
      eventName: `user_exported_privy_wallet`,
      address: walletAddress,
    })
  }

  const withdrawCurrencyFromWallet = async () => {
    if (!walletAddress || !appContracts) return
    if (!receiverAddress) {
      return addAppNoti({
        msg: 'Please enter address',
        type: 'error',
      })
    }
    const amount = String(withdrawCurrencyAmount)
    const toAddress = receiverAddress
    const shortToAddress = shortenAddress(toAddress)
    const contractAddress = appContracts.currency.address

    const txData = appContracts.currency.interface.encodeFunctionData('transfer', [
      toAddress,
      utils.parseUnits(amount, networkStyle.decimals),
    ])
    const requestData: UnsignedTransactionRequest = {
      to: contractAddress,
      chainId: walletChainId,
      from: walletAddress,
      data: txData,
    }
    const privyUIConfig: SendTransactionModalUIOptions = {
      header: `Withdraw ${networkStyle.currencyName}`,
      description: `Withdrawing ${networkStyle.currencyName} to ${shortToAddress}`,
      buttonText: `Withdraw ${networkStyle.currencyName}`,
      successHeader: `Successfully sent ${networkStyle.currencyName}`,
      successDescription: `You have just sent ${networkStyle.currencyName} to ${shortToAddress}`,
      transactionInfo: {
        title: `Transfer ${networkStyle.currencyName}`,
        action: `Transfer ${networkStyle.currencyName}`,
        contractInfo: {
          name: networkStyle.currencyName,
          imgUrl: networkStyle.currencyIcon,
          url: 'https://app.fareplay.io',
          imgSize: 'sm',
          imgAltText: `${networkStyle.currencyName} icon`,
        },
      },
      showWalletUIs: true,
    }
    await privy.sendTransaction(requestData, privyUIConfig, {}, walletAddress)

    postlog(`user_withdrew_currency_from_privy_wallet`, {
      loglevel: 'success',
      eventName: `user_withdrew_currency_from_privy_wallet`,
      to: contractAddress,
      chainId: walletChainId,
      from: walletAddress,
      data: txData,
    })

    addAppNoti({
      msg: 'Sent ' + networkStyle.nativeToken,
      type: 'success',
    })
  }

  const withdrawNativeTokenFromWallet = async () => {
    if (!walletAddress || !appContracts) return
    if (!receiverAddress) {
      return addAppNoti({
        msg: 'Please enter address',
        type: 'error',
      })
    }
    const amount = String(withdrawNativeAmount)
    const toAddress = receiverAddress

    const requestData: UnsignedTransactionRequest = {
      to: toAddress,
      chainId: walletChainId,
      value: toEthHex(amount),
      from: walletAddress,
    }

    const privyUIConfig: SendTransactionModalUIOptions = {
      header: `Withdraw ${networkStyle.nativeToken}`,
      description: `Withdraw ${networkStyle.nativeToken} from your Privy wallet`,
      buttonText: `Withdraw ${networkStyle.nativeToken}`,
      successHeader: `Sucessfully sent ${networkStyle.nativeToken}`,
      successDescription: `You have just sent ${networkStyle.nativeToken} to ${shortenAddress(toAddress)}`,
      transactionInfo: {
        title: `Transfer ${networkStyle.nativeToken} to ${shortenAddress(toAddress)}`,
        action: `Transfer ${networkStyle.nativeToken} out`,
        contractInfo: {
          name: networkStyle.nativeToken,
          imgUrl: SVGS.ethIcon,
          url: 'https://app.fareplay.io',
          imgSize: 'sm',
          imgAltText: `${networkStyle.nativeToken} icon`,
        },
      },
      showWalletUIs: true,
    }
    await privy.sendTransaction(requestData, privyUIConfig, {}, walletAddress)
    postlog(`user_withdrew_native_currency_from_privy_wallet`, {
      loglevel: 'success',
      eventName: `user_withdrew_native_currency_from_privy_wallet`,
      to: toAddress,
      chainId: walletChainId,
      value: toEthHex(amount),
      from: walletAddress,
    })
    addAppNoti({
      msg: 'Sent ' + networkStyle.nativeToken,
      type: 'success',
    })
  }

  const fundActiveWalletNative = () => {
    fundWallet(walletAddress, {
      asset: 'native-currency',
      amount: '0.0042',
      chain: chainDefinition,
    }).then(() => {
      postlog(`user_funded_wallet`, {
        loglevel: 'success',
        eventName: `user_funded_wallet`,
        asset: 'native-currency',
        amount: '0.0042',
        chain: chainDefinition,
      })
    })
  }

  const fundActiveWalletCurrency = () => {
    fundWallet(walletAddress, {
      asset: 'USDC',
      amount: '30.0',
      chain: chainDefinition,
    }).then(() => {
      postlog(`user_funded_wallet_currency`, {
        loglevel: 'success',
        eventName: `user_funded_wallet_currency`,
        asset: 'USDC',
        amount: '30.0',
        chain: chainDefinition,
      })
    })
  }

  return (
    <ContainerWrapper>
      <TransferFunds />
      {isPrivyWallet ?
        <BalanceWrapper>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 12,
            }}
          >
            <div>BALANCES</div>
            <BalanceButtons>
              {isPrivyWallet && (
                <button onClick={exportPrivyWallet}>
                  <img src={SVGS.privyIcon} alt='export wallet' />
                </button>
              )}
            </BalanceButtons>
          </div>
          <div
            style={{
              borderBottom: '1px solid rgba(255,255,255,0.18)',
              paddingBottom: 12,
              marginBottom: 12,
            }}
          >
            <SInput
              placeholder='Withdraw address...'
              value={receiverAddress}
              onChange={event => setReceiverAddress(event.currentTarget.value)}
            />
          </div>
          <BalanceContent className='bottom-divider balance-content-column'>
            <div className='balance-amount'>
              <CountUp
                className='balance-countup-amount'
                end={currencyBalance}
                decimals={2}
                duration={2}
                separator={','}
                preserveValue
              />
              <button onClick={withdrawCurrencyFromWallet} disabled={withdrawCurrencyAmount <= 0}>
                <img
                  src={SVGS.depositWallet}
                  alt='deposit wallet'
                  style={{ marginBottom: '3px', width: '12px' }}
                />
              </button>
            </div>
            <WithdrawInput
              decimals={2}
              amount={withdrawCurrencyAmount}
              setAmount={setWithdrawCurrencyAmount}
              tokenName={networkStyle.currencyName}
              tokenIcon={networkStyle.currencyIcon}
              max={currencyBalance}
            />
          </BalanceContent>
          <BalanceContent className='balance-content-column'>
            <div className='balance-amount'>
              <CountUp
                className='balance-countup-amount'
                end={nativeBalance}
                decimals={8}
                duration={2}
                separator={','}
                preserveValue
              />
              <button onClick={withdrawNativeTokenFromWallet} disabled={withdrawNativeAmount <= 0}>
                <img
                  src={SVGS.depositWallet}
                  alt='deposit wallet'
                  style={{ marginBottom: '3px', width: '12px' }}
                />
              </button>
            </div>
            <WithdrawInput
              decimals={10}
              amount={withdrawNativeAmount}
              setAmount={setWithdrawNativeAmount}
              tokenName={networkStyle.nativeToken}
              tokenIcon={SVGS.ethIcon}
              max={nativeBalance}
            />
          </BalanceContent>
        </BalanceWrapper>
      : <div></div>}
      <InlineContent>
        <div className='divider'></div>
        <div className='text'>OTHER METHODS</div>
        <div className='divider'></div>
      </InlineContent>
      <div></div>
      <BuyUSDC />
    </ContainerWrapper>
  )
}
