import React, { useState, useCallback, useEffect } from 'react'
import { lerp } from 'three/src/math/MathUtils'
import AmountSlider from '../shared/Slider/AmountSlider'
import { DiceContainer, MarkContainer, SliderContainer, SliderThumb } from './styled'
import { useDebouncedCallback } from 'use-debounce'
import { useSound } from '../shared/SoundSystem/SoundContext'
import clickButton4Audio from '@/assets/audio/ClickyButton4.wav'
import clickButton11Audio from '@/assets/audio/Click 11.wav'

const Thumb = (props: any, _state: any) => {
  return <SliderThumb {...props} />
}

interface DiceSliderProps {
  sliderValue?: number
  setSliderValue?: (value: number) => void
  isDisabled?: boolean
}

export const DiceSlider = ({ sliderValue, setSliderValue, isDisabled }: DiceSliderProps) => {
  const [val, setVal] = useState(52)
  const { loadSound, playSound } = useSound()

  // sfx parameters
  const CLICK_SOUND_THRESHOLD = 10 // Slider interval at which click sound will be played
  const lastPlayedValueRef = useRef(52)
  const lastPlayTimeRef = useRef(Date.now())

  useEffect(() => {
    loadSound('hoverSound', clickButton4Audio)
    // loadSound('hoverSound', '/src/assets/audio/Click 11.wav')
    loadSound('clickSound', clickButton11Audio)
    // loadSound('clickSound', '/src/assets/audio/bombs-mouse-over.wav')
    // loadSound('clickSound', '/src/assets/audio/ClickyButton4.wav')
  }, [loadSound])

  const getNormalizedValueRange = (value: number) => {
    const totalRange = 94.9
    const normalizedValue = Number((value - 5).toFixed(2))
    const percentage = normalizedValue / totalRange
    let newValue = 0
    if (percentage <= 0.5) {
      newValue = Math.round(lerp(5, 50, percentage * 2))
    } else {
      newValue = Math.round(lerp(50, 99.9, (percentage - 0.5) * 2))
    }
    return Math.min(99.9, newValue)
  }

  const playSliderClick = useDebouncedCallback(() => {
    playSound('clickSound', 0.3, 1.5)
  }, 0)

  const handleChange = useCallback(
    (newValue: number) => {
      const interpolatedValue = getNormalizedValueRange(newValue)

      // Ensuring the value is within the range and rounded to the nearest integer
      // const clampedValue = Math.min(99.9, Math.max(5, Math.round(newValue)))
      setSliderValue?.(interpolatedValue)
      setVal(newValue)
      if (Math.abs(interpolatedValue - lastPlayedValueRef.current) >= CLICK_SOUND_THRESHOLD) {
        playSliderClick()
        lastPlayedValueRef.current = interpolatedValue
      }

      return interpolatedValue
    },
    [setSliderValue, playSliderClick]
  )

  // const handleChange = useCallback(
  //   (newValue: number) => {
  //     const now = Date.now()
  //     const timeSinceLastPlay = now - lastPlayTimeRef.current
  //     const interpolatedValue = getNormalizedValueRange(newValue)

  //     setSliderValue?.(interpolatedValue)
  //     setVal(newValue)

  //     // Calculate speed based on value change and time elapsed
  //     const valueChange = Math.abs(interpolatedValue - lastPlayedValueRef.current)
  //     const speed = valueChange / timeSinceLastPlay

  //     // Adjust these values to fine-tune the behavior
  //     const minSpeed = 0.01
  //     const maxSpeed = 1
  //     // const minPitch = 1
  //     // const maxPitch = 1.2
  //     const minPitch = 1.5
  //     const maxPitch = 1.6
  //     const minVolume = 0.175
  //     const maxVolume = 0.3

  //     // Calculate pitch and volume based on speed
  //     const normalizedSpeed = Math.min(Math.max((speed - minSpeed) / (maxSpeed - minSpeed), 0), 1)
  //     const pitch = minPitch + normalizedSpeed * (maxPitch - minPitch)
  //     const volume = minVolume + normalizedSpeed * (maxVolume - minVolume)

  //     // Play sound if the change is significant or if enough time has passed
  //     if (valueChange >= 1 || timeSinceLastPlay > 100) {
  //       playSound('clickSound', volume, pitch)
  //       lastPlayedValueRef.current = interpolatedValue
  //       lastPlayTimeRef.current = now
  //     }

  //     return interpolatedValue
  //   },
  //   [setSliderValue, playSound]
  // )

  const getInverseNormalizedValueRange = (interpolatedValue: number) => {
    if (interpolatedValue > 90) return interpolatedValue
    return interpolatedValue - 1.5
  }

  const handleInverseChange = useCallback(
    (interpolatedValue: number) => {
      const newValue = getInverseNormalizedValueRange(interpolatedValue)

      // Ensuring the value is within the range and rounded to the nearest integer
      // const clampedValue = Math.min(99.9, Math.max(5, Math.round(newValue)))
      // setSliderValue?.(interpolatedValue)
      // setVal(newValue)
      return newValue
    },
    [setSliderValue]
  )

  const Mark = ({ key }: any) => {
    const marks = [5, 99.9]
    if (marks.includes(key)) {
      let label = 'WIN'
      let posLeft = 0

      if (key === 5) {
        posLeft = 0
        label = 'LOSE'
      } else if (key === 99.9) {
        posLeft = 100
        label = 'WIN'
      }

      return (
        <MarkContainer className={label.toLowerCase()} key={key} left={posLeft}>
          {label}
        </MarkContainer>
      )
    }
    return null
  }

  useEffect(() => {
    const newValue = handleInverseChange(sliderValue || 52)
    setVal(newValue)
  }, [sliderValue])

  // const handleMouseOver = () => {
  //   playSound('hoverSound', 0.3) // Play at 30% volume
  // }

  const handleMouseClick = useDebouncedCallback(() => {
    playSound('hoverSound', 0.3)
  }, 25)

  return (
    <SliderContainer>
      <DiceContainer className='slider-input-wrapper' onMouseDownCapture={handleMouseClick}>
        <AmountSlider
          className='slider-z-index'
          marks={[5, 50, 99.9]}
          renderMark={Mark}
          max={100}
          min={5}
          step={1}
          value={val}
          // defaultValue={52}
          onChange={handleChange}
          renderThumb={Thumb}
          disabled={isDisabled}
        />
      </DiceContainer>
    </SliderContainer>
  )
}
