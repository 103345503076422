import { Variants, motion } from 'framer-motion'
import { COLORS } from '@/design'

export const SAmountNumbers = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 8px;
  right: 10px;
  user-select: none;
  z-index: 20;
`

export const SAmountNumber = styled(motion.span)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 300px;
  > img {
    margin-left: 2px;
    height: 24px;
    width: 24px;
  }
  > svg {
    margin-left: 2px;
    height: 24px;
    width: 24px;
  }
  > span {
    font-size: 14px;
    font-family: GohuUppercase, monospace;
    padding-top: 4px;
    line-height: 10px;
    &.is-win {
      color: ${COLORS.success};
    }
    &.is-lose {
      color: ${COLORS.error};
    }
  }
`

export const amountNumbersVariant: Variants = {
  initial: {
    opacity: 0,
    y: 18,
    transition: {
      duration: 0.6,
    },
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      bounce: 0.7,
      duration: 0.65,
      delay: 0.5,
    },
  },
  exit: {
    opacity: 0,
    y: -20,
    transition: {
      duration: 0.4,
      delay: 3,
    },
  },
}

export const deltaNumberVariant: (delay: number) => Variants = delay => ({
  initial: { opacity: 0, y: 120, transition: { duration: 0.6 } },
  animate: {
    opacity: 1,
    y: [100, 30, 0],
    transition: {
      y: {
        duration: 0.5,
        times: [0, 0.4, 1],
        delay,
      },
      opacity: {
        duration: 0.3,
        delay,
      },
    },
  },
  exit: { opacity: 0, y: -12, transition: { duration: 0.2 } },
})

export const SDeltaNumber = styled(motion.div)`
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 99;
  &.is-win {
    color: ${COLORS.success};
  }
  &.is-lose {
    color: ${COLORS.error};
  }
`
