import { AppGameName } from '@/chains/types'
import { gameProxy } from '@/store/proxy/gameProxy'
import { useSnapshot } from 'valtio'

export const usePathGameName = () => {
  const gameNameSnapshot = useSnapshot(gameProxy)
  const gameName = (gameNameSnapshot.pathGameName as AppGameName) || AppGameName.CoinFlip

  return gameName
}
