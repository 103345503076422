import { LayoutGroup, motion } from 'framer-motion'
import FundsAccordion from './FundsAccordion'
import { TEXT_COLORS } from '@/design'
import { depositReadMoreText, transferReadMoreText } from '@/constants/funding'
import { fundWalletModalState } from '.'
import { modalVariants } from '@/components/Privy/SelectWalletModal'
import { SVGS } from '@/assets'
import { useFundWallet } from '@privy-io/react-auth'
import { useActiveWallet } from '@/lib/privy/hooks'
import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'

const AccordionDetails = styled(motion.div)`
  color: ${TEXT_COLORS.two};
`
const descriptionDetails = (i: number, text: string): JSX.Element => {
  return (
    <motion.p
      key={i}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.12 } }}
      transition={{ stiffness: 100 }}
      style={{ fontFamily: 'system-ui, sans-serif' }}
    >
      {text}
    </motion.p>
  )
}

export const FundWalletMenu = () => {
  const { fundWallet } = useFundWallet()

  const { walletAddress } = useActiveWallet()
  
  const { chainDefinition } = useAppChainConfigStore((state) => ({
    appContracts: state.appContracts,
    chainDefinition: state.appChainConfig.chainDefinition,
  }))

  const setStepIdx = (step: number) => {
    fundWalletModalState.stepIdx = step
  }

  const fundActiveWalletCurrency = () => {
    fundWallet(walletAddress, {
      asset: 'USDC',
      amount: '30.0',
      chain: chainDefinition,
    }).then(() => {
      //  TODO: handle success
    })
  }

  const showDepositModal = () => {
    // ╭─────────────────────────────────────────────────────────╮
    // │ This should be disabled until our MoonPay account is    │
    // │ approved. For now, we'll fall back on the Privy         │
    // │ on-ramp modal.                                          │
    // │                                                         │
    // │ fundWalletModalState.isMoonpayModalShowing = true       │
    // ╰─────────────────────────────────────────────────────────╯

    fundActiveWalletCurrency()
    fundWalletModalState.isFundModalShowing = false
  }

  return (
    <motion.div variants={modalVariants} layout>
      <LayoutGroup>
        <FundsAccordion
          next={() => setStepIdx(1)}
          title='Transfer Crypto'
          image1={SVGS.usdcIcon}
          image2={SVGS.ethIconCircle}
          image3={SVGS.tetherIcon}
          description='Deposit from Coinbase, Kraken, Binance...'
        >
          <AccordionDetails>
            {transferReadMoreText.map((text: string, i: number) => descriptionDetails(i, text))}
          </AccordionDetails>
        </FundsAccordion>
        <FundsAccordion
          next={showDepositModal}
          title='Card Deposit'
          image1={SVGS.visaLogo}
          image2={SVGS.mastercardLogo}
          image3={SVGS.applePayLogo}
          image4={SVGS.googlePayLogo}
          description='Deposit with Visa, Mastercard, Apple Pay...'
        >
          <AccordionDetails>
            {depositReadMoreText.map((text: string, i: number) => descriptionDetails(i, text))}
          </AccordionDetails>
        </FundsAccordion>
      </LayoutGroup>
    </motion.div>
  )
}
