import useCurrencyStore from '@/store/useCurrencyStore'
import { useCurrency } from './useCurrency'

export const useAllowance = (entryAmountNum: number) => {
  const { approveAllowance } = useCurrency()
  const { allowances, isApprovingAllowance, approveAllowanceState } = useCurrencyStore(state => ({
    allowances: state.allowances,
    isApprovingAllowance: state.isApprovingAllowance,
    approveAllowanceState: state.approveAllowanceState,
  }))

  const hasApprovedAllowance = useMemo(
    () =>
      Number(entryAmountNum) === 0 ||
      (Number(allowances.currency) !== 0 && Number(allowances.currency) >= entryAmountNum),
    [allowances.currency, entryAmountNum]
  )

  return useMemo(
    () => ({
      approveAllowance,
      hasApprovedAllowance,
      isApprovingAllowance,
      allowances: allowances.currency,
      approveAllowanceState,
    }),
    [isApprovingAllowance, hasApprovedAllowance, approveAllowanceState]
  )
}
