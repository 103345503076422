import { AnimatePresence, motion, Variants } from 'framer-motion'
import { LoadingChip } from '../LoadingChip'

const SAAFullLoading = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.6);
`

export const SLoadingContent = styled(motion.div)`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  img {
    margin-bottom: 16px;
    height: 64px;
  }
  span {
    font-size: 16px;
  }
`

export const loadingVariants: Variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
}

export const loadingContentVariants: Variants = {
  initial: {
    opacity: 0,
    y: -200,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: 200,
  },
}

const flickerLoadingTextVariant: Variants = {
  initial: {
    opacity: 1,
    y: 0,
  },
  animate: {
    y: 0,
    opacity: [1, 0.6],
    transition: {
      opacity: {
        type: 'tween',
        ease: 'easeOut',
        repeat: Infinity,
        repeatType: 'mirror',
        duration: 1,
      },
    },
  },
  exit: {
    y: -100,
  },
}
export const FullOverlayLoading = ({
  loadingText,
  isShowing,
}: {
  loadingText: string
  isShowing: boolean
}) => {
  return (
    <AnimatePresence>
      {isShowing ?
        <SAAFullLoading variants={loadingVariants} initial='initial' animate='animate' exit='exit'>
          <SLoadingContent
            variants={loadingContentVariants}
            initial='initial'
            animate='animate'
            exit='exit'
          >
            <LoadingChip width={78} height={78} slowAnimation />
            <motion.span
              variants={flickerLoadingTextVariant}
              initial='initial'
              animate='animate'
              exit='exit'
            >
              {loadingText}
            </motion.span>
          </SLoadingContent>
        </SAAFullLoading>
      : null}
    </AnimatePresence>
  )
}
