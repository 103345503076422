import { routeGameTypeMap } from '@/chains/lib'
import { type AppGameName } from '@/chains/types'
import {
  formatUsdc,
  getPotentialProfitCoefficient,
  multiplyBigNumberWithFixedPointNumber,
} from '@/lib/crypto'
import useBankrollStore from '@/store/useBankrollStore'
import useCurrencyStore from '@/store/useCurrencyStore'
import { useShallow } from 'zustand/react/shallow'
import { utils } from 'ethers'
import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'
import { fsocket } from '@/lib/fsocket'
import { useDebounceValue } from 'usehooks-ts'

export const useBufferZone = (side: any) => {
  const { pathname } = useLocation()
  const networkStyle = useAppChainConfigStore(state => state.appChainConfig.networkStyle)
  const riskFactor = useBankrollStore(state => state.riskFactor)
  const { bankrollBalance } = fsocket.user.useState()
  const { balances } = useCurrencyStore(
    useShallow(state => ({
      balances: state.balances,
    }))
  )
  const [potentialProfitCoefficient, setPotentialProfitCoefficient] = useDebounceValue(
    () => {
      return getPotentialProfitCoefficient(routeGameTypeMap[pathname] as AppGameName, side)
    },
    400,
    { trailing: true }
  )

  useEffect(() => {
    setPotentialProfitCoefficient(
      getPotentialProfitCoefficient(routeGameTypeMap[pathname] as AppGameName, side)
    )
  }, [side, pathname])

  const maxAllowedBet = useMemo(() => {
    if (!bankrollBalance || potentialProfitCoefficient === 0) return Number.MAX_SAFE_INTEGER
    let risk = 100
    if (riskFactor) {
      risk = riskFactor
    }
    const maxBet =
      (Number(formatUsdc(bankrollBalance)) * (risk / 10000)) / potentialProfitCoefficient
    return maxBet
  }, [potentialProfitCoefficient, bankrollBalance, riskFactor])

  const bufferedZone = useMemo(() => {
    const bufferedCurrencyBalance = multiplyBigNumberWithFixedPointNumber(
      utils.parseUnits(balances.currency, networkStyle.decimals),
      '0.9'
    )
    const bufferedMaxAllowBet = multiplyBigNumberWithFixedPointNumber(
      utils.parseUnits(maxAllowedBet.toFixed(6), networkStyle.decimals),
      '0.9'
    )
    const lesserOfTwoMaxValue = Number(
      formatUsdc(
        Number(balances.currency) > maxAllowedBet ? bufferedMaxAllowBet : bufferedCurrencyBalance
      )
    )

    return lesserOfTwoMaxValue > 1 ? Math.floor(lesserOfTwoMaxValue) : lesserOfTwoMaxValue
  }, [balances.currency, maxAllowedBet, networkStyle.decimals])

  const stepZone = useMemo(() => {
    if (bufferedZone <= 1) {
      return 0.01
    } else if (bufferedZone < 5) {
      return 0.1
    } else if (bufferedZone < 25) {
      return 0.25
    } else if (bufferedZone < 50) {
      return 0.5
    } else if (bufferedZone < 100) {
      return 1
    } else if (bufferedZone < 250) {
      return 5
    } else {
      return 10
    }
  }, [bufferedZone])

  return useMemo(() => ({ bufferedZone, stepZone }), [bufferedZone, stepZone])
}
