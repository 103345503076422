import { useGLTF } from '@react-three/drei'
import { forwardRef, useRef, useImperativeHandle, useMemo, useEffect, useState } from 'react'
import { type GLTF } from 'three-stdlib'
import * as THREE from 'three'

const dicePath = import.meta.env.VITE_GAME_ASSETS_CDN + '/glb/dice.glb'

type GLTFResult = GLTF & {
  nodes: {
    dicemesh: THREE.Mesh
    dicemesh_1: THREE.Mesh
    dicemesh_2: THREE.Mesh
    dicemesh_3: THREE.Mesh
    dicemesh_4: THREE.Mesh
    dicemesh_5: THREE.Mesh
    alien: THREE.Mesh
    clover: THREE.Mesh
    fire: THREE.Mesh
    heart: THREE.Mesh
    skull: THREE.Mesh
    sparkle: THREE.Mesh
    trim: THREE.Mesh
  }
  materials: {
    Material_2: THREE.MeshStandardMaterial
    Material_1: THREE.MeshStandardMaterial
    Material_3: THREE.MeshStandardMaterial
    Material_4: THREE.MeshStandardMaterial
    Material_5: THREE.MeshStandardMaterial
    Material_6: THREE.MeshStandardMaterial
    Material_0: THREE.MeshStandardMaterial
  }
}

interface IDiceModel {
  groupProps?: JSX.IntrinsicElements['group']
  meshProps?: JSX.IntrinsicElements['mesh']
  boxGeometryProps?: JSX.IntrinsicElements['boxGeometry']
  scaleOverride?: number
}

export type DiceModelRef = {
  diceGroupRef: THREE.Group | null
  setWinState: () => void
  setLoseState: () => void
  resetState: () => void
}

export const DiceModelNew = forwardRef<DiceModelRef, IDiceModel>((props: IDiceModel, ref) => {
  const { nodes, materials } = useGLTF(dicePath) as GLTFResult
  const scaleValue = 0.2

  const winColor = useMemo(() => new THREE.Color(0.1, 1, 0.1), [])
  const loseColor = useMemo(() => new THREE.Color(0.1, 0.1, 0.1), [])

  const diceGroupRef = useRef<THREE.Group>(null)
  const clonedMaterials = useRef<THREE.MeshStandardMaterial[]>([])
  const originalColors = useRef<
    { color: THREE.Color; emissive: THREE.Color; emissiveIntensity: number }[]
  >([])

  const [isCloned, setIsCloned] = useState(false)

  // Clone materials on mount, excluding Material_0
  useEffect(() => {
    // Get all materials except Material_0
    const materialsToClone = Object.entries(materials)
      .filter(([key]) => key !== 'Material_0')
      .map(([_, material]) => material)

    // Clone materials and store original properties
    clonedMaterials.current = materialsToClone.map(material => {
      const cloned = material.clone()
      originalColors.current.push({
        color: cloned.color.clone(),
        emissive: cloned.emissive.clone(),
        emissiveIntensity: cloned.emissiveIntensity,
      })
      return cloned
    })

    setIsCloned(true)
  }, [materials])

  useImperativeHandle(
    ref,
    () => ({
      diceGroupRef: diceGroupRef.current,
      setWinState: () => {
        clonedMaterials.current.forEach(material => {
          material.color.copy(winColor)
          material.emissive.copy(winColor)
          material.emissiveIntensity = 1.5
        })
      },
      setLoseState: () => {
        clonedMaterials.current.forEach(material => {
          material.color.copy(loseColor)
          material.emissive.copy(loseColor)
          material.emissiveIntensity = 1.5
        })
      },
      resetState: () => {
        clonedMaterials.current.forEach((material, index) => {
          const original = originalColors.current[index]
          if (original) {
            material.color.copy(original.color)
            material.emissive.copy(original.emissive)
            material.emissiveIntensity = original.emissiveIntensity
            console.log(`Material ${index} reset to original color`)
          } else {
            console.warn(`Original color not found for material index ${index}`)
          }
        })
      },
    }),
    [winColor, loseColor]
  )

  return (
    <group
      ref={diceGroupRef}
      {...props.groupProps}
      dispose={null}
      scale={props.scaleOverride || scaleValue}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.dicemesh.geometry}
        material={clonedMaterials.current[0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.dicemesh_1.geometry}
        material={clonedMaterials.current[1]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.dicemesh_2.geometry}
        material={clonedMaterials.current[2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.dicemesh_3.geometry}
        material={clonedMaterials.current[3]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.dicemesh_4.geometry}
        material={clonedMaterials.current[4]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.dicemesh_5.geometry}
        material={clonedMaterials.current[5]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.alien.geometry}
        material={materials.Material_0}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.clover.geometry}
        material={materials.Material_0}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.fire.geometry}
        material={materials.Material_0}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.heart.geometry}
        material={materials.Material_0}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.skull.geometry}
        material={materials.Material_0}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.sparkle.geometry}
        material={materials.Material_0}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.trim.geometry}
        material={materials.Material_0}
      />
    </group>
  )
})

DiceModelNew.displayName = 'DiceModelNew'

useGLTF.preload(dicePath)
