import numeral from 'numeral'
import { useForm, Controller } from 'react-hook-form'
import {
  FormAmountLabel,
  FormFooter,
  FormIndicator,
  FormLabel,
  FormLabelRow,
  FormWrapper,
  SFormSection,
} from '../style'
import { FareNumberInput } from '../../shared/Input/FareNumberInput'
import { gameTypeHelperFunctionsMap, getMaxCount } from '@/lib/crypto'
import { type ISUContractForm } from '@/store/useSUContractStore'
import { ensureNumber, clamp } from '@/utils/math'
import CountUp from 'react-countup'
import { useDiceGameState } from '@/store/useGameStateStore'
import { DiceSlider } from '../../DiceSlider/DiceSlider'
import { GameButton } from '../../shared/Button/GameButton'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { useNetworkStyle } from '@/hooks/useCurrency'
import { SDoubleInputRow, STripleRow } from '../FormComponents/shared/FormLayout/styles'
import { FormLayout } from '../FormComponents/shared/FormLayout'
import { routeGameTypeMap } from '@/chains/lib'
import { DEFAULT_MAX_COUNT } from '@/lib/crypto/constants'
import { useBufferZone } from '@/hooks/useBufferZone'
import { useBalances } from '@/hooks/useBalances'
import { useIsGameAnimating } from '@/hooks/useIsGameAnimating'

interface IDiceSUForm extends ISUContractForm {
  multiplier: number
  winChance: number
  rollOverNum: number
}

// @TODO: Need to get this dynamically or put this in a constant file
const HOUSE_EDGE_EV = 1.98

function Slider({
  side,
  setSide,
  isDisabled,
}: {
  side: number
  setSide: (val: number) => void
  isDisabled: boolean
}) {
  return <DiceSlider sliderValue={side} setSliderValue={setSide} isDisabled={isDisabled} />
}

const calculateSideFromMultiplier = (multiplier: number) => {
  // Rearrange the equation to solve for side
  const calculatedMultiplierWithoutPPV = multiplier / 0.99
  const side = 10000 - 10000 / calculatedMultiplierWithoutPPV

  return side / 100
}

export const DiceForm = () => {
  const { pathname } = useLocation()
  const networkStyle = useNetworkStyle()
  const balances = useBalances()
  const isMobileScreen = useIsBreakpoint('sm')

  const balanceNumber = Number(balances.currency)

  const { selectedSide, setSelectedSide } = useDiceGameState(state => ({
    selectedSide: state.entry.side,
    setSelectedSide: (side: number) => state.setEntry({ side }),
  }))

  const {
    register,
    // handleSubmit,
    control,
    setValue,
    // trigger,
    // reset,
    watch,
    // formState: { errors },
  } = useForm<IDiceSUForm>({
    mode: 'onChange',
    defaultValues: {
      side: 50,
      entryAmount: 0,
      multiplier: HOUSE_EDGE_EV,
      winChance: 50,
      numberOfEntries: 1,
      stopGain: 0,
      stopLoss: 0,
      rollOverNum: 0,
    },
  })
  const { isGameAnimating } = useIsGameAnimating()

  const formData = watch()
  const {
    entryAmount,
    numberOfEntries,
    // multiplier,
    // winChance,
    // rollOverNum,
    // side,
  } = formData
  const { bufferedZone: bufferedMaxEntryAmount, stepZone } = useBufferZone(selectedSide * 100)

  const { getMultiplierWithPPV } = useMemo(() => gameTypeHelperFunctionsMap.dice, [])

  const calculatedMultiplier = useMemo(
    () => getMultiplierWithPPV(selectedSide * 100),
    [selectedSide, getMultiplierWithPPV]
  )

  useEffect(() => {
    setValue('rollOverNum', selectedSide)
  }, [selectedSide, setValue])

  useEffect(() => {
    setValue('winChance', 100 - selectedSide)
  }, [selectedSide, setValue])

  useEffect(() => {
    setValue('multiplier', ensureNumber(calculatedMultiplier.toFixed(4)))
  }, [calculatedMultiplier, setValue])

  const [maxCountForSide, setMaxCountForSide] = useState(DEFAULT_MAX_COUNT)

  // @NOTE: Expects side as same as others. So if side is 53.22 it should be represented as 5322
  const handlePossibleMaxCountChange = (side: any) => {
    if (10000 - side > 0) {
      const maxCount = getMaxCount(routeGameTypeMap[pathname], side)
      // @NOTE: I could have done what I have done below with a single if but using if and else if makes it easier to understand
      if (numberOfEntries > maxCount) {
        // @NOTE: If their current count is above the new max count, I am setting their count to be the new max count
        setValue('numberOfEntries', maxCount)
      } else if (maxCountForSide === numberOfEntries && maxCount > maxCountForSide) {
        // @NOTE: If someone had fulled the slider to have max count, and if they move the slider for win chance that causes the max count to increase, I autyo increase their selected count to be new max
        setValue('numberOfEntries', maxCount)
      }
      setMaxCountForSide(maxCount)
    }
  }

  return (
    <FormWrapper>
      <FormLayout>
        <form>
          {!isMobileScreen && (
            <SFormSection className='no-border'>
              <Slider
                side={selectedSide}
                setSide={val => {
                  setSelectedSide(val)
                  // @TODO: Could have a useDebounce or something so that we dont calculate for each little movement on slide rather at the end of slider movement
                  handlePossibleMaxCountChange(val * 100)
                }}
                isDisabled={isGameAnimating}
              />
            </SFormSection>
          )}

          <STripleRow>
            <SFormSection className='no-pd'>
              <FormLabelRow className='center-label'>
                <FormLabel>
                  <FormAmountLabel>Roll Over</FormAmountLabel>
                </FormLabel>
              </FormLabelRow>
              <Controller
                {...register('rollOverNum', { required: true, max: 99.9, min: 5 })}
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <>
                    <FareNumberInput
                      {...field}
                      style={{ paddingRight: 0 }}
                      onChange={event => {
                        field.onChange(event.target.value as any)
                        handlePossibleMaxCountChange(Number(event.target.value) * 100)
                      }}
                      onBlur={e => {
                        const MIN = 5
                        const MAX = 99.9
                        const val = clamp(
                          numeral(e.currentTarget.value || '0').value() || 0,
                          MIN,
                          MAX
                        )

                        setSelectedSide(val)
                      }}
                      allowLeadingZeros={false}
                      allowNegative={false}
                      decimalScale={2}
                      fixedDecimalScale
                      thousandSeparator=','
                      disabled={isGameAnimating}
                    />
                  </>
                )}
              />
            </SFormSection>
            <SFormSection className='no-pd'>
              <FormLabelRow className='center-label'>
                <FormLabel>
                  <FormAmountLabel>Win Chance</FormAmountLabel>
                </FormLabel>
              </FormLabelRow>
              <Controller
                {...register('winChance', { required: true, max: 99.9, min: 0 })}
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <FareNumberInput
                    {...field}
                    style={{ paddingRight: 0 }}
                    onChange={event => field.onChange(event.target.value as any)}
                    onBlur={e => {
                      const MIN = 0.1
                      const MAX = 95
                      const val = clamp(
                        numeral(e.currentTarget.value || '0').value() || 0,
                        MIN,
                        MAX
                      )
                      setSelectedSide(100 - val)
                    }}
                    allowLeadingZeros={false}
                    allowNegative={false}
                    decimalScale={2}
                    fixedDecimalScale
                    thousandSeparator=','
                    disabled={isGameAnimating}
                  />
                )}
              />
            </SFormSection>
            <SFormSection className='no-pd'>
              <FormLabelRow className='center-label'>
                <FormLabel>
                  <FormAmountLabel>Multiplier</FormAmountLabel>
                </FormLabel>
              </FormLabelRow>
              <Controller
                {...register('multiplier', { required: true, max: 99.9, min: 1.0421 })}
                control={control}
                defaultValue={calculatedMultiplier}
                render={({ field }) => {
                  // const MIN = 1.0421
                  // const MAX = 990
                  return (
                    <FareNumberInput
                      {...field}
                      style={{ paddingRight: 0 }}
                      onChange={event => {
                        field.onChange(event.target.value as any)
                        handlePossibleMaxCountChange(Number(event.target.value) * 100)
                      }}
                      onBlur={e => {
                        const MIN = 0.1
                        const MAX = 95

                        const val = clamp(
                          numeral(e?.currentTarget.value || '0').value() || calculatedMultiplier,
                          MIN,
                          MAX
                        )
                        const calculatedSide = calculateSideFromMultiplier(val)
                        setSelectedSide(calculatedSide)
                        field.onChange(val)
                      }}
                      allowLeadingZeros={false}
                      allowNegative={false}
                      decimalScale={2}
                      fixedDecimalScale
                      thousandSeparator=','
                      disabled={isGameAnimating}
                    />
                  )
                }}
              />
            </SFormSection>
          </STripleRow>

          <SDoubleInputRow className='mobile-form'>
            <SFormSection>
              <FormLabelRow>
                <FormLabel>
                  <FormIndicator $isActive={entryAmount > 0} />
                  <FormAmountLabel>Amount</FormAmountLabel>
                </FormLabel>
                <FormLabel>
                  {balanceNumber > 0 && (
                    <FormAmountLabel>
                      <CountUp
                        end={balanceNumber}
                        decimals={2}
                        duration={2}
                        separator={','}
                        preserveValue
                      />
                    </FormAmountLabel>
                  )}
                </FormLabel>
              </FormLabelRow>
              <Controller
                name='entryAmount'
                control={control}
                rules={{ required: true, max: 1_000_000_000, min: 0 }}
                defaultValue={0}
                render={({ field }) => {
                  const { ref, ...props } = field
                  const value = numeral(field.value).value() || 0
                  const isDisabled =
                    (numeral(balances.currency).value() || 0) === 0 || isGameAnimating

                  return (
                    <FareNumberInput
                      {...props}
                      getInputRef={ref}
                      onChange={event => field.onChange(numeral(event.target.value).value() as any)}
                      allowLeadingZeros={false}
                      allowNegative={false}
                      thousandSeparator=','
                      decimalScale={2}
                      hasInputSlider
                      disabled={isDisabled}
                      inputSuffix={
                        <img
                          src={networkStyle.currencyIcon}
                          alt={networkStyle.currencyName}
                          width={20}
                        />
                      }
                      inputSliderProps={{
                        value,
                        onChange: sliderValue => field.onChange(sliderValue),
                        min: 0,
                        max: bufferedMaxEntryAmount,
                        step: stepZone,
                        disabled: isDisabled,
                      }}
                    />
                  )
                }}
              />
            </SFormSection>

            <SFormSection>
              <FormLabelRow>
                <FormLabel>
                  <FormIndicator $isActive={numberOfEntries > 0} />
                  <FormAmountLabel>
                    {isMobileScreen ? 'Rounds' : 'Number of Entries'}
                  </FormAmountLabel>
                </FormLabel>

                <FormLabel>
                  {Number(entryAmount / (numberOfEntries || 1)) > 0 && (
                    <FormAmountLabel>
                      <CountUp
                        end={Number(entryAmount / (numberOfEntries || 1))}
                        decimals={2}
                        duration={0.18}
                        separator={','}
                        preserveValue
                      />
                      <span>&nbsp;/ {isMobileScreen ? 'round' : 'entry'}</span>
                    </FormAmountLabel>
                  )}
                </FormLabel>
              </FormLabelRow>
              <Controller
                name='numberOfEntries'
                control={control}
                rules={{
                  required: `Must be ${maxCountForSide} or less.`,
                  max: {
                    message: `Must be less than ${maxCountForSide}`,
                    value: maxCountForSide,
                  },
                  min: 1,
                }}
                render={({ field }) => {
                  const MIN = 1
                  const MAX = maxCountForSide
                  const value = numeral(field.value).value()
                  const clampedValue = clamp(ensureNumber(value), MIN, MAX)
                  const { ref, ...props } = field

                  return (
                    <FareNumberInput
                      {...props}
                      getInputRef={ref}
                      onChange={event => field.onChange(ensureNumber(event.currentTarget.value))}
                      onBlur={event => {
                        const val = clamp(ensureNumber(event.currentTarget.value), MIN, MAX)
                        field.onChange(val)
                      }}
                      allowLeadingZeros={false}
                      allowNegative={false}
                      hasInputSlider={true}
                      inputSliderProps={{
                        value: clampedValue,
                        onChange: sliderValue => field.onChange(sliderValue),
                        min: 1,
                        max: maxCountForSide,
                        step: 1,
                        disabled: isGameAnimating,
                      }}
                      disabled={isGameAnimating}
                    />
                  )
                }}
              />
            </SFormSection>
          </SDoubleInputRow>
        </form>
      </FormLayout>
      <FormFooter>
        <GameButton
          formData={{ ...formData, side: selectedSide * 100 }}
          entryAmountNum={entryAmount}
        />
      </FormFooter>
    </FormWrapper>
  )
}
