import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { Dropdown, SAddressAvatarWrapper, SwitchCoin } from './style'
import { useNetworkSwitch } from '@/hooks/useNetworkSwitch'
import { useActiveWallet } from '@/lib/privy/hooks'
import { dropdownVariant } from '../../Mobile/Dropdown/style'
import { NetworkDropDownItems } from './NetworkDropDownItems'

export const SwitchCurrency: React.FC = () => {
  const isMobileScreen = useIsBreakpoint('lg')
  const mouseLeaveTimeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const { isOpen, setIsOpen } = useNetworkSwitch()
  const { networkStyle, appChainConfig } = useActiveWallet()

  const handleMouseEnter = () => {
    if (!isMobileScreen) {
      clearTimeout(mouseLeaveTimeoutRef.current)
    }
  }
  const handleMouseLeave = () => {
    if (!isMobileScreen) {
      mouseLeaveTimeoutRef.current = setTimeout(() => setIsOpen(false), 800)
    }
  }

  return (
    <SwitchCoin onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <SAddressAvatarWrapper $isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <img
          src={networkStyle.networkLogo}
          alt={networkStyle.networkName}
          width={isMobileScreen ? 20 : 12}
        />
        {!isMobileScreen && appChainConfig.chainDefinition.name}
      </SAddressAvatarWrapper>

      {isOpen && (
        <Dropdown variants={dropdownVariant} initial='initial' animate={'animate'} exit={'exit'}>
          <div>
            <NetworkDropDownItems />
          </div>
        </Dropdown>
      )}
    </SwitchCoin>
  )
}
