import { arbitrumMainnetAppConfig } from './defs/arbitrum.mainnet.config'
// import { arbitrumSepoliaAppConfig } from './defs/arbitrum.sepolia.config'
import { SupportedAppChainId, type AppChainConfig } from '@/chains/types'

// NOTE: Add appChainConfig here
export const appChainIdMap: Record<SupportedAppChainId, AppChainConfig> = {
  // [SupportedAppChainId.ARBITRUM_SEPOLIA]: arbitrumSepoliaAppConfig,
  [SupportedAppChainId.ARBITRUM_MAINNET]: arbitrumMainnetAppConfig,
} as const

export const appChainConfigs = Object.values(appChainIdMap)
export const supportedChains = Object.values(appChainIdMap).map(
  chainConfig => chainConfig.chainDefinition
)
export const DEFAULT_APP_CHAIN_ID = SupportedAppChainId.ARBITRUM_MAINNET
export const defaultSupportedChain =
  appChainIdMap[SupportedAppChainId.ARBITRUM_MAINNET].chainDefinition
