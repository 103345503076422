import { gameProxy } from '@/store/proxy/gameProxy'
import { routeGameTypeMap } from '@/chains/lib'
import useUserDataStore from '@/store/useUserDataStore'

export const PathNameListener = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    gameProxy.pathGameName = routeGameTypeMap[pathname]

    useUserDataStore.setState({ currentGameName: routeGameTypeMap[pathname] })
  }, [pathname])

  return null
}
