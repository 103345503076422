import { ensureString } from '@/utils/text'
import { ChatFState, initialChatFState } from '../state'
import type { ChatSocket } from '../types'
import { FSpaceBase, IFSpaceBaseOpts } from './FSpace'
import { addAppNoti } from '@/store/useNotiStore'

export const MAX_CHAT_MSG_LENGTH = 140

type ChatSpaceOpts = Omit<IFSpaceBaseOpts<ChatFState>, 'name' | 'status' | 'state'>

export class ChatSpace extends FSpaceBase<ChatSocket, ChatFState> {
  constructor({ fsocketOpts, authToken }: ChatSpaceOpts) {
    super({
      name: 'chat',
      state: initialChatFState,
      fsocketOpts,
      authToken,
    })

    this.mountListeners()
  }

  onReconnectSocket(): void {
    this.state.chatMsgs = []
  }

  mountListeners() {
    if (this.hasMountedListeners) return
    this.hasMountedListeners = true
    this.io.on('initial_state', initialMsgs => {
      this.status.isConnecting = false
      this.state.chatMsgs = initialMsgs
    })

    // @TODO: Ensure that the same chat message isn't added more than once
    this.io.on('new_chat', chatMsg => {
      this.state.chatMsgs.push(chatMsg)
    })
  }

  sendChatMsg(text: string) {
    const msg = ensureString(text).trim()
    if (!msg || msg.length === 0 || msg.length > MAX_CHAT_MSG_LENGTH)
      return addAppNoti({
        type: 'error',
        msg: `Chat message must be less than ${MAX_CHAT_MSG_LENGTH}`,
      })

    this.io.emit('send_chat', msg)
    // @TODO: Implement optimistic UI update so client immediately sees their message
  }
}
