import { create } from 'zustand'
import { AppGameName } from '@/chains/types'
import { createGameStateStore, initialGameEntryState } from '@fare/sdk/state'
import { createGameLiveEntrySlice } from '@/lib/fare/state/gameLiveEntrySlice'
import useUserDataStore from './useUserDataStore'

export type GameStoreName = 'coin-flip' | 'dice' | 'rps' | 'bombs' | 'plinko' | 'crash'

// @NOTE: At some point, we might want to refactor the CoinFlip, RPS, Dice's sides to be objects to make them similar to other games
const initialCoinFlipState = {
  entry: {
    ...initialGameEntryState,
    side: Math.round(Math.random()), // Select heads or tails randomly on load
  },
}
export const useCoinFlipGameState = createGameStateStore(initialCoinFlipState)

const initialDiceGameState = {
  entry: {
    ...initialGameEntryState,
    side: 50,
  },
}
export const useDiceGameState = createGameStateStore(initialDiceGameState)

const initialRPSGameState = {
  entry: {
    ...initialGameEntryState,
    // side: Math.floor(Math.random() * 3), // Select random side (0-2)
    side: 0, // set to rock
  },
}
export const useRPSGameState = createGameStateStore(initialRPSGameState)

const initialBombsGameState = {
  entry: {
    ...initialGameEntryState,
    side: {
      bombCount: Math.floor(Math.random() * 11), // Select random bombCount (0 - 10)
      revealCount: 1, // Default is to have one revealed tile
      revealIndexes: [Math.floor(Math.random() * 25)], // Select random revealindex (0 - 24)
    },
  },
}
export const useBombsGameState = createGameStateStore(initialBombsGameState)

const initialPlinkoGameState = {
  entry: {
    ...initialGameEntryState,
    side: {
      riskLevel: 0, // Can be changed if we want to show a higher risk config for users
      rowCount: 8,
    },
  },
}
export const usePlinkoGameState = createGameStateStore(initialPlinkoGameState)

const initialCrashGameState = {
  entry: {
    ...initialGameEntryState,
    side: {
      cashoutMultiplier: 1,
    },
  },
}
export const useCrashGameState = createGameStateStore(initialCrashGameState)

export const useDelayedLiveEntry = create(createGameLiveEntrySlice)

export const useDummyGameState = createGameStateStore(initialCoinFlipState)

export const gameStoreMap: Record<GameStoreName, ReturnType<typeof createGameStateStore>> = {
  'coin-flip': useCoinFlipGameState,
  dice: useDiceGameState,
  rps: useRPSGameState,
  bombs: useBombsGameState,
  plinko: usePlinkoGameState,
  crash: useCrashGameState,
}

export const useGetGameStoreType = (pathname: string) => {
  const gameStore =
    ((gameStoreMap as any) || {})[pathname.replace('/', '')]?.() || useDummyGameState()

  return useMemo(() => gameStore.type, [gameStore.type])
}

export const sendGameStoreUpdate = (gameStoreName: GameStoreName) => {
  const store = gameStoreMap[gameStoreName]
  return store.getState().send
}

export const gameStoreMapByGameName: Record<
  AppGameName,
  ReturnType<typeof createGameStateStore>
> = {
  coinFlip: useCoinFlipGameState,
  dice: useDiceGameState,
  rps: useRPSGameState,
  bombs: useBombsGameState,
  plinko: usePlinkoGameState,
  crash: useCrashGameState,
}

export const resetGameStoreByGameName = (gameName: AppGameName) => {
  if (gameName === AppGameName.CoinFlip) {
    gameStoreMapByGameName[gameName].setState(initialCoinFlipState)
  } else if (gameName === AppGameName.Dice) {
    gameStoreMapByGameName[gameName].setState(initialDiceGameState)
  } else if (gameName === AppGameName.RPS) {
    gameStoreMapByGameName[gameName].setState(initialRPSGameState)
  } else if (gameName === AppGameName.Bombs) {
    gameStoreMapByGameName[gameName].setState(initialBombsGameState)
  } else if (gameName === AppGameName.Plinko) {
    gameStoreMapByGameName[gameName].setState(initialPlinkoGameState)
  } else if (gameName === AppGameName.Crash) {
    gameStoreMapByGameName[gameName].setState(initialCrashGameState)
  }
}

export type FareGameNameUnion = keyof typeof gameStoreMapByGameName

export const sendGameStoreUpdateByAddress = (gameName: AppGameName) => {
  if (!gameName && useUserDataStore.getState().currentGameName !== gameName) return
  const store = gameStoreMapByGameName[gameName]
  return store.getState().send
}
