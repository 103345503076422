import useMaxValuesStore from '@/store/useMaxValuesStore'
import { useAppChainConfig } from './useAppChainConfig'

// export const DEFAULT_MAX_VALUES = {
//   ethUsdcPriceBuffer: DEFAULT_BUFFER_PERCENTAGE,
//   averageCallbackGasBuffer: DEFAULT_BUFFER_PERCENTAGE,
//   aaCostMultiplierBuffer: DEFAULT_BUFFER_PERCENTAGE,
//   ethUsdcPriceSCValue: '', // @NOTE: These values should be fetched from smart contract. 'FetchCurrentMaxValues' component does fetch does values and sets them inside this object
//   averageCallbackGasSCValue: '', // @NOTE: These values should be fetched from smart contract. 'FetchCurrentMaxValues' component does fetch does values and sets them inside this object
//   aaCostMultiplierSCValue: '', // @NOTE: These values should be fetched from smart contract. 'FetchCurrentMaxValues' component does fetch does values and sets them inside this object
// }

const useMaxValues = () => {
  const { setSCValues } = useMaxValuesStore()
  const { appContracts } = useAppChainConfig()

  const fetchSCMaxValues = useCallback(async () => {
    if (!appContracts?.vault) return
    try {
      const config = await appContracts.vault.configView()
      const scVals = {
        ethUsdcPriceSCValue: config.ethUsdcPrice.toString(),
        averageCallbackGasSCValue: String(config.averageCallbackGas),
        aaCostMultiplierSCValue: String(config.aaCostMultiplier),
      }
      return scVals
    } catch (err) {}
  }, [appContracts])

  const fetchAndSetSCMaxValues = useCallback(
    async (account: string) => {
      const scMaxValues = await fetchSCMaxValues()
      if (!scMaxValues) return
      setSCValues(scMaxValues, account)
    },
    [setSCValues, fetchSCMaxValues]
  )

  return { fetchSCMaxValues, fetchAndSetSCMaxValues }
}

export default useMaxValues
