import { useAuthedActiveWallet } from '@/lib/privy/hooks'
import useCurrencyStore from '@/store/useCurrencyStore'
import useSUContractStore from '@/store/useSUContractStore'

export const useHasAppLoaded = () => {
  const { walletReady, sessionVerifyState, walletAddress } = useAuthedActiveWallet()
  const approvedGameState = useSUContractStore(state => state.approvedGameState)
  const approveAllowanceState = useCurrencyStore.use.approveAllowanceState()

  return useMemo(
    () =>
      (walletReady && !walletAddress) ||
      (walletReady &&
        sessionVerifyState !== 'pending' &&
        approvedGameState !== 'pending' &&
        approveAllowanceState !== 'pending'),
    [walletReady, sessionVerifyState, approvedGameState, approveAllowanceState]
  )
}
