import { USDCContractInterface } from '@/lib/crypto'
import useCurrencyStore, { FetchableBalanceType } from '@/store/useCurrencyStore'
import { addAppNoti } from '@/store/useNotiStore'
import { BigNumberish, utils } from 'ethers'
import { decodeError } from 'ethers-decode-error'
import { useAppChainConfig } from '@/hooks/useAppChainConfig'
import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'
import { useActiveWallet } from '@/lib/privy/hooks'
import { MAX_APPROVAL_AMOUNT } from '@/constants/crypto'

export const useNetworkStyle = () => {
  const { networkStyle } = useAppChainConfigStore.use.appChainConfig()
  return useMemo(() => networkStyle, [networkStyle.networkName])
}

export const useCurrency = () => {
  const { walletAddress } = useActiveWallet()
  const {
    appAddresses,
    appContracts,
    networkStyle,
    appChainDefinition,
    appProvider: provider,
  } = useAppChainConfig()
  const wsProvider = useAppChainConfigStore.use.appProviderWs()
  const { setAllowance, setBalance, setIsApprovingAllowance } = useCurrencyStore(state => ({
    setAllowance: state.setAllowance,
    setBalance: state.setBalance,
    setIsApprovingAllowance: state.setIsApprovingAllowance,
  }))

  const fetchBalance = async (address: string, currencyType: FetchableBalanceType) => {
    if (!address || !appContracts || !provider || !wsProvider) return '0'

    try {
      switch (currencyType) {
        case 'native':
          const ethBalance = await provider.getBalance(address)
          if (!ethBalance) return console.warn('There was an issue fetching ethBalance')
          return utils.formatUnits(ethBalance, appChainDefinition.nativeCurrency.decimals)
        case 'currency':
          const usdcBalance = await appContracts.ws.currency.balanceOf(address)
          if (!usdcBalance) return console.warn('There was an issue fetching usdcBalance')
          return utils.formatUnits(usdcBalance, networkStyle.decimals)
        default:
          console.warn('Invalid currency type passed to fetchUpdateBalance')
          break
      }
    } catch (err) {
      console.error('Issue with fetchBalance:', err)
      return '0'
    }
  }

  const fetchAndSetCurrencyBalance = async (
    address: string | undefined,
    currencyType: FetchableBalanceType = 'currency'
  ) => {
    try {
      if (!address) return

      const currencyBalance = await fetchBalance(address, currencyType)
      setBalance('currency', currencyBalance ?? '0')
    } catch (err) {
      console.error(err)
      throw new Error(err as any)
    }
  }

  const fetchAllowance = async (ownerAddress: string) => {
    if (!ownerAddress || !appContracts) return '0'

    try {
      const bnAllowance = await appContracts.ws.currency.allowance(
        ownerAddress,
        appAddresses.bankroll
      )
      if (!bnAllowance) {
        console.warn('There was an issue fetching allowance')
        return '0'
      }

      return utils.formatUnits(bnAllowance, networkStyle.decimals)
    } catch (err) {
      console.error('Issue with fetchBalance:', err)
      return '0'
    }
  }

  const fetchAndSetAllowance = async (...args: Parameters<typeof fetchAllowance>) => {
    try {
      const allowance = await fetchAllowance(...args)
      setAllowance('currency', allowance)
    } catch (err) {
      console.error(err)
      throw new Error(err as any)
    }
  }

  const approveAllowance = async (
    spenderAddress: string,
    allowanceAmount: BigNumberish = MAX_APPROVAL_AMOUNT
  ) => {
    try {
      if (!appContracts) throw new Error(`ERC20 contracts are not defined`)
      if (!walletAddress) throw new Error('There was an issue getting the public address')
      const parsedAmount = utils.parseUnits(String(allowanceAmount), networkStyle.decimals)

      setIsApprovingAllowance(true)

      const approveTx = await appContracts.currency.approve(spenderAddress, parsedAmount)

      const receipt = await approveTx.wait()

      return receipt
    } catch (err) {
      // NOTE: Package found from the following discussion: https://github.com/ethers-io/ethers.js/discussions/3027
      const decodedError = decodeError(err, USDCContractInterface)
      addAppNoti({
        msg: decodedError.error,
        type: 'error',
      })
      throw (console.error(err), new Error(`Error approving allowance`))
    } finally {
      setIsApprovingAllowance(false)
    }
  }

  const fetchUpdateNativeBalance = async (addr: string | undefined) => {
    if (!addr) return
    setBalance('native', (await fetchBalance(addr, 'native')) ?? '0')
  }

  return {
    networkStyle,
    currencyContract: appContracts?.currency,
    currencyContractWs: appContracts?.ws.currency,
    fetchBalance,
    fetchAllowance,
    approveAllowance,
    fetchAndSetAllowance,
    fetchAndSetCurrencyBalance,
    fetchUpdateNativeBalance,
  }
}
