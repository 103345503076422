import { appChainIdMap, DEFAULT_APP_CHAIN_ID } from '@/chains'
import { SupportedAppChainId } from '@/chains/types'
import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'
import { usePrivy, useWallets } from '@privy-io/react-auth'

export const useAuthedActiveWallet = () => {
  const { ready, authenticated } = usePrivy()
  const { wallets } = useWallets()
  const { appWalletClientType, sessionVerifyState } = useAppChainConfigStore(state => ({
    appWalletClientType: state.appWalletClientType,
    sessionVerifyState: state.sessionVerifyState,
  }))

  const activeWallet = useMemo(() => {
    let selectedWallet = wallets.find(
      wallet => wallet.walletClientType === appWalletClientType && wallet.linked
    )
    if (!selectedWallet) selectedWallet = wallets.find(wallet => wallet.linked)

    return ready && authenticated ? selectedWallet || wallets[0] : null
  }, [wallets, ready, authenticated, appWalletClientType])

  const walletChainId = useMemo(
    () => (activeWallet ? Number(activeWallet.chainId.split(':')[1]) : 0),
    [activeWallet]
  )

  const isWalletLinked = useMemo(() => Boolean(activeWallet?.linked), [activeWallet])

  const isWalletAuthed = useMemo(
    () => Boolean(activeWallet) && ready && authenticated && isWalletLinked,
    [ready, authenticated, isWalletLinked, activeWallet]
  )

  return {
    activeWallet: isWalletAuthed ? activeWallet : null,
    walletAddress: isWalletAuthed ? activeWallet?.address : null,
    walletChainId,
    isWalletAuthed,
    sessionVerifyState,
    walletReady: ready,
  }
}

export const usePrivyTwitterData = () => {
  const { user } = usePrivy()

  return useMemo(() => {
    return user?.twitter
  }, [user?.twitter])
}

export const useActiveWallet = () => {
  const { ready, authenticated, user, isModalOpen } = usePrivy()
  const { wallets } = useWallets()
  const {
    appProvider: walletProvider,
    appWalletClientType,
    isWrongNetwork,
    sessionVerifyState,
  } = useAppChainConfigStore(state => ({
    appProvider: state.appProvider,
    appWalletClientType: state.appWalletClientType,
    isWrongNetwork: state.isWrongNetwork,
    sessionVerifyState: state.sessionVerifyState,
  }))

  const activeWallet = useMemo(() => {
    let selectedWallet = wallets.find(
      wallet => wallet.walletClientType === appWalletClientType && wallet.linked
    )
    if (!selectedWallet) selectedWallet = wallets.find(wallet => wallet.linked)

    return ready && authenticated ? selectedWallet || wallets[0] : null
  }, [wallets, ready, authenticated, appWalletClientType])

  const walletChainId = useMemo(
    () => (activeWallet ? Number(activeWallet.chainId.split(':')[1]) : 0),
    [activeWallet]
  )

  const appChainConfig = useMemo(
    () =>
      appChainIdMap[walletChainId as SupportedAppChainId] || appChainIdMap[DEFAULT_APP_CHAIN_ID],
    [appChainIdMap, walletChainId]
  )

  const isWalletLinked = useMemo(() => Boolean(activeWallet?.linked), [activeWallet])

  const isWalletAuthed = useMemo(
    () => Boolean(activeWallet) && ready && authenticated && isWalletLinked,
    [ready, authenticated, isWalletLinked, activeWallet]
  )

  return {
    user,
    isWalletAuthed,
    isModalOpen,
    ready,
    authenticated,
    isWalletLinked,
    activeWallet,
    walletChainId,
    walletProvider,
    networkStyle: appChainConfig.networkStyle,
    appChainConfig,
    walletAddress: activeWallet?.address || '',
    isWrongNetwork,
    sessionVerifyState,
  }
}
