export const transferReadMoreText = [
  'Deposit funds into your wallet from Coinbase, Binance or whichever exchange you hold your crypto on.',
  'You control the wallet that your funds go to. Fareplay has no access to your funds and cannot block withdrawals.',
  'Fareplay will never ask for your identification (no KYC).',
]

export const depositReadMoreText = [
  'Deposit funds into your wallet with Visa, Mastercard, Apple Pay or Google Pay.',
  "Powered by Moonpay, the world's largest and most trusted crypto on-ramp.",
  'Previous Moonpay users will not be asked to repeat KYC.',
]
