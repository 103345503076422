import { arbitrum as chainDefinition } from 'viem/chains'
import { type AppChainConfig } from '../types'
import { appChainNetworkStyleMap, defaultAppChainConfig } from '../configs'

export const arbitrumMainnetAppConfig: AppChainConfig = {
  httpUrl: import.meta.env.VITE_USDC_HTTP_URL || 'http://localhost:3200',
  websocketUrl: import.meta.env.VITE_USDC_SOCKET_URL || 'http://localhost:3337',
  rpc: {
    ws: 'wss://arb-mainnet.g.alchemy.com/v2/JWKuEgCnFaENNKXDRcItWMnRdpyS-ez8',
    http: 'https://arb-mainnet.g.alchemy.com/v2/JWKuEgCnFaENNKXDRcItWMnRdpyS-ez8',
  },
  addresses: {
    currency: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
    vault: '0x2247f17e6719A1D0Bee0F9dbcCC6E050A6dada6e',
    aa: '0x33a31853f63649d7113958D7411c8852406910e5',
    bankroll: '0x33a31853f63649d7113958D7411c8852406910e5',
  },
  chainDefinition,
  networkStyle: appChainNetworkStyleMap.ARBITRUM,
  biconomyConfig: {
    bundlerUrl: 'https://bundler.biconomy.io/api/v2/42161/plfarye.wh12stac-7E49-85b-af80-z7JnP3xLs',
    paymasterId: '0xace659dc614d5fc455d123a1c3e438dd78a05e77',
    paymasterUrl:
      'https://paymaster.biconomy.io/api/v1/42161/RltkjGfVe.397a4268-259e-4640-b3cb-dd210018af58',
    payMasterAPIKey: 'RltkjGfVe.397a4268-259e-4640-b3cb-dd210018af58',
  },
  features: defaultAppChainConfig.features,
  services: defaultAppChainConfig.services,
}
