import { PrivyClientConfig, WalletListEntry } from '@privy-io/react-auth'

import { FARE_COLORS } from '@/design'
import { defaultSupportedChain, supportedChains } from '@/chains'
import './privy-theme-override.css'

export type PrivyLoginMethod = PrivyClientConfig['loginMethods']

export const PRIVY_APP_ID = import.meta.env.VITE_PRIVY_APP_ID || 'cm2mgmv1g06oz12h3qrxie4k7'
export const PRIVY_APP_CLIENT_ID =
  import.meta.env.VITE_PRIVY_APP_CLIENT_ID || 'client-WY5cjJTuri7cwFC8eCUk8LjmcibTWmkMJj8nefphRTLBs'

export const privyLoginMethods = [
  'wallet',
  'email',
  'sms',
  'google',
  'twitter',
  'discord',
] as PrivyLoginMethod

export const privyWalletList = [
  'metamask',
  'coinbase_wallet',
  'rainbow',
  'wallet_connect',
  'safe',
  'zerion',
  'detected_wallets',
] as WalletListEntry[]

export const privyConfig: PrivyClientConfig = {
  defaultChain: defaultSupportedChain,
  supportedChains,
  loginMethods: privyLoginMethods,
  appearance: {
    // landingHeader: '',
    // loginMessage: 'Sign in with a wallet, email, sms, or a social account',
    walletChainType: 'ethereum-only',
    showWalletLoginFirst: true,
    walletList: privyWalletList,
    theme: 'dark',
    accentColor: FARE_COLORS.aqua,
    logo: `${location.origin}/images/fareplay-white-logo.svg`,
  },
  // Create embedded wallets for users who don't have a wallet
  embeddedWallets: {
    createOnLogin: 'users-without-wallets',
    showWalletUIs: false,
  },
  externalWallets: {
    coinbaseWallet: {
      connectionOptions: 'eoaOnly',
    },
  },
}
