import {
  createSession,
  createSessionKeyEOA,
  createSessionSmartAccountClient,
  createSmartAccountClient,
  getSingleSessionTxParams,
  SessionLocalStorage,
  Transaction,
  Session,
  BiconomySmartAccountV2,
} from '@biconomy/account'

import useAAStore, { AASessionState, getIsUsingQuickPlayFromLocalStorage } from './useAAStore'
import { createRulesAndPolicy } from '../policyRules'
import { withSponsorship } from '@/chains/configs'
import {
  SessionAALocalStorageMap,
  SessionSignerLocalStorageMap,
  SmartAccountReturnType,
} from '../types'
import { usePostLog } from '@/lib/posthog/logging'
import { ISessionStorage } from '@biconomy/account/dist/_types/modules/interfaces/ISessionStorage'
import { useAppChainConfig } from '@/hooks/useAppChainConfig'

export const useAA = () => {
  const { postlog } = usePostLog()
  const { appAddresses, appContracts, appChainConfig, appProvider: provider } = useAppChainConfig()
  const aaStore = useAAStore(state => state)
  const { biconomyConfig, chainDefinition: chain } = appChainConfig
  const bankrollContract = appContracts?.bankroll

  const createAASmartAccount = useCallback(async () => {
    if (!provider) throw new Error('Must pass a valid provider to useSmartAccount')
    const signer = provider.getSigner()
    if (!signer) throw new Error('Must pass a valid signer to useSmartAccount')

    const smartAccount = await createSmartAccountClient({
      signer,
      bundlerUrl: biconomyConfig.bundlerUrl,
      biconomyPaymasterApiKey: biconomyConfig.payMasterAPIKey,
    })
    const smartAccountAddress = await smartAccount.getAddress()

    useAAStore.setState({
      smartAccountClient: smartAccount ?? null,
      smartAccountAddress: smartAccountAddress ?? '0x',
    })

    return {
      smartAccountClient: smartAccount ?? null,
      smartAccountAddress: smartAccountAddress ?? '0x',
    } as SmartAccountReturnType
  }, [provider])

  const populateAASession = async (walletAddress: string) => {
    if (!walletAddress || !bankrollContract) return

    // @TODO: Need to think over condition if user changes wallet account to
    // a non authenticated account while aaSession is populated
    try {
      if (aaStore.aaInitialState !== 'pending') {
        useAAStore.setState({
          isActivatingAA: true,
        })
      }

      const { smartAccountAddress } = await createAASmartAccount()
      postlog('create_aa_smart_account', { smartAccountAddress })

      // localStorage keys to get aa sessions and signers
      const sessionSignerStorageKey = `${smartAccountAddress.toLowerCase()}_signers`
      const sessionAAStorageKey = `${smartAccountAddress.toLowerCase()}_sessions`

      // Objects to populate with parsed JSON from localStorage
      let existingSessionSignerInfo: SessionSignerLocalStorageMap | null = null
      let existingSessionAAInfo: SessionAALocalStorageMap | null = null

      try {
        const localStorageSignerObj = localStorage.getItem(sessionSignerStorageKey)
        if (localStorageSignerObj !== null) {
          existingSessionSignerInfo = JSON.parse(localStorageSignerObj)
        }
      } catch (err) {
        console.error('Problem parsing localStorage session data:', err)
      }

      try {
        const localStorageAAObj = localStorage.getItem(sessionAAStorageKey)
        if (localStorageAAObj !== null) {
          existingSessionAAInfo = JSON.parse(localStorageAAObj)
        }
      } catch (err) {
        console.error('Problem parsing localStorage aa sessionKey data:', err)
      }

      // No session data found so exit from function
      if (!existingSessionSignerInfo || !existingSessionAAInfo) {
        useAAStore.getState().set(
          {
            isActivatingAA: false,
            isUsingAA: false,
          },
          walletAddress
        )

        postlog('No aa signers or sessions found in localStorage', {
          smartAccountAddress,
          loglevel: 'warning',
          eventName: 'populate_missing_aa_sesion',
        })
        return
      }

      const session: Session = {
        sessionStorageClient: new SessionLocalStorage(smartAccountAddress),
        sessionIDInfo: [
          existingSessionAAInfo.leafNodes[existingSessionAAInfo.leafNodes.length - 1].sessionID!,
        ],
      }

      const { params } = await getSingleSessionTxParams(session.sessionStorageClient, chain, 0)

      // This is the `emulatedUsersSmartAccount` instance from the aa session data
      const aaClient = await createSessionSmartAccountClient(
        {
          accountAddress: session.sessionStorageClient.smartAccountAddress, // Dapp can set the account address on behalf of the user
          bundlerUrl: biconomyConfig.bundlerUrl,
          paymasterUrl: biconomyConfig.paymasterUrl,
          chainId: chain.id,
        },
        session.sessionStorageClient // Storage client, full Session or simply the smartAccount address if using default storage for your environment
      )

      const aaInfo: Partial<AASessionState> = {
        sessionStorageClient: session.sessionStorageClient,
        session,
        aaClient,
        params,
        hasSetupBefore: true,
        isActivatingAA: false,
        isUsingAA: getIsUsingQuickPlayFromLocalStorage(walletAddress.toLowerCase()), // Get current isUsingQuickplay value from localStorage
      }

      useAAStore.getState().set(aaInfo, walletAddress)

      postlog(`Successfully populated aa session for ${walletAddress.substring(0, 10)}`, {
        loglevel: 'success',
        eventName: 'populated_aa_session',
        smartAccountAddress: aaInfo.sessionStorageClient?.smartAccountAddress,
        isUsingAA: aaInfo.isUsingAA,
      })

      return aaInfo
    } catch (err) {
      useAAStore.getState().set(
        {
          isActivatingAA: false,
          isUsingAA: false,
        },
        walletAddress
      )
      postlog('Failed to populate users aa session', {
        eventName: 'populate_aa_failed',
        loglevel: 'error',
      })

      throw err
    }
  }

  const createAASession = async (
    account: `0x${string}`,
    sessionKeyAddress: `0x${string}`,
    smartAccountClient: BiconomySmartAccountV2,
    sessionStorageClient: ISessionStorage
  ) => {
    const { policies } = createRulesAndPolicy(account, sessionKeyAddress, appAddresses.vault)
    const { wait, session: createdSession } = await createSession(
      smartAccountClient,
      policies,
      sessionStorageClient,
      withSponsorship
    )
    await wait()

    postlog(
      `Created new session | ${account.substring(0, 10)} | ${sessionKeyAddress.substring(0, 10)}`,
      {
        loglevel: 'success',
      }
    )
    return createdSession
  }

  const activateAASession = useCallback(
    async (walletAddress: `0x${string}` | undefined, shouldCreateNew = false) => {
      try {
        if (!bankrollContract) throw new Error('bankrollContract not defined in activateAASession')

        useAAStore.setState({
          isActivatingAA: true,
        })

        const {
          smartAccountClient: _smartAccountClient,
          smartAccountAddress: _smartAccountAddress,
          session,
          sessionStorageClient: _sessionStorageClient,
        } = useAAStore.getState()

        if (!provider || !walletAddress)
          throw new Error('provider and account must provided in createAASession')

        let smartAccountClient = _smartAccountClient
        let smartAccountAddress = _smartAccountAddress

        if (!_smartAccountAddress || !_smartAccountClient) {
          const _smartAccount = await createAASmartAccount()
          smartAccountClient = _smartAccount.smartAccountClient
          smartAccountAddress = _smartAccount.smartAccountAddress
        }

        // Check if smartAccountAddress (submitter) has been approved
        const hasApprovedSubmitter =
          await bankrollContract.fundOwnerAddressToSubmitterAddressToIsAllowed(
            walletAddress,
            smartAccountAddress
          )

        if (!hasApprovedSubmitter) {
          // Allow smartAccountAddress as a submitted for EOA account
          const tx = await bankrollContract.setAllowedSubmitter(smartAccountAddress, true)
          const resp = await tx.wait()
          console.log(
            `User has approved smartAccountAddress${smartAccountAddress.substring(0, 10)} as submitted`,
            `Tx: ${chain.blockExplorers?.default.url}/tx/${resp.transactionHash}`
          )
        }

        if (!smartAccountClient || !smartAccountAddress)
          throw new Error(
            'smartAccountAddress & smartAccountClient are required to create AA session'
          )

        const sessionSignerStorageKey = `${smartAccountAddress.toLowerCase()}_signers`
        const sessionAAStorageKey = `${smartAccountAddress.toLowerCase()}_sessions`
        let existingSessionSignerInfo: SessionSignerLocalStorageMap | null = null
        let existingSessionAAInfo: SessionAALocalStorageMap | null = null

        try {
          const localStorageSignerObj = localStorage.getItem(sessionSignerStorageKey)
          if (localStorageSignerObj !== null) {
            existingSessionSignerInfo = JSON.parse(localStorageSignerObj)
          }
        } catch (err) {
          console.error(err)
        }

        try {
          const localStorageAAObj = localStorage.getItem(sessionAAStorageKey)
          if (localStorageAAObj !== null) {
            existingSessionAAInfo = JSON.parse(localStorageAAObj)
          }
        } catch (err) {
          console.error(err)
        }

        let _session = session

        if (existingSessionAAInfo && existingSessionSignerInfo && !shouldCreateNew) {
          _session = {
            sessionStorageClient: new SessionLocalStorage(smartAccountAddress),
            sessionIDInfo: [
              existingSessionAAInfo.leafNodes[existingSessionAAInfo.leafNodes.length - 1]
                .sessionID!,
            ],
          }
          postlog('Constructed session from existing sessionStorage:', {
            _session,
            loglevel: 'success',
          })
        } else {
          // create new session key
          const { sessionKeyAddress, sessionStorageClient } = await createSessionKeyEOA(
            smartAccountClient,
            chain,
            _sessionStorageClient ?? undefined
          )

          if (!_session) {
            const createdSession = await createAASession(
              walletAddress,
              sessionKeyAddress,
              smartAccountClient,
              sessionStorageClient
            )

            _session = createdSession
          }
        }

        const { params } = await getSingleSessionTxParams(_session.sessionStorageClient, chain, 0)

        const emulatedUsersSmartAccount = await createSessionSmartAccountClient(
          {
            accountAddress: _session.sessionStorageClient.smartAccountAddress, // Dapp can set the account address on behalf of the user
            bundlerUrl: biconomyConfig.bundlerUrl,
            paymasterUrl: biconomyConfig.paymasterUrl,
            chainId: chain.id,
          },
          _session.sessionStorageClient // Storage client, full Session or simply the smartAccount address if using default storage for your environment
        )

        const aaInfo: Partial<AASessionState> = {
          sessionStorageClient: _sessionStorageClient,
          session: _session,
          aaClient: emulatedUsersSmartAccount,
          params,
          isActivatingAA: false,
          isUsingAA: true,
          hasSetupBefore: true,
        }

        useAAStore.getState().set(aaInfo, walletAddress)

        return aaInfo
      } catch (err) {
        console.log(err)
        useAAStore.getState().set(
          {
            isUsingAA: false,
            isActivatingAA: false,
          },
          walletAddress
        )

        throw err
      }
    },
    [provider, appAddresses]
  )

  const deactivateAASession = (walletAddress: string) => {
    useAAStore.getState().set(
      {
        isUsingAA: false,
      },
      walletAddress
    )
  }

  const aaTrialRegister = async (txData: `0x${string}`) => {
    const { aaClient, params } = useAAStore.getState()
    if (!aaClient || !params)
      throw new Error('aaClient and params must be provided in aaTrialRegister')

    const trialRegisterTx: Transaction = {
      to: appAddresses.vault,
      data: txData,
    }

    try {
      const { wait, waitForTxHash, userOpHash } = await aaClient.sendTransaction(trialRegisterTx, {
        ...params,
        ...withSponsorship,
      })

      return {
        wait,
        waitForTxHash,
        userOpHash,
      }
    } catch (err) {
      let shouldCreateSession = (err as any).message?.includes('SessionNotApproved') // Check if session is not approved

      // Attempts to create a new session key because the previous session was invalid
      if (shouldCreateSession) {
        if (!provider) throw err

        const account = (await provider?.getSigner().getAddress()) as `0x${string}`
        postlog(`User has invalid session. Attempting to create a new one`, {
          loglevel: 'info',
          eventName: 'invalid_aa_session_creating_new_session',
        })

        const smartAccountAddress = aaClient.biconomySmartAccountConfig.accountAddress || ''
        const sessionSignerStorageKey = `${smartAccountAddress.toLowerCase()}_signers`
        const sessionAAStorageKey = `${smartAccountAddress.toLowerCase()}_sessions`

        // Invalidate aa session and attempt to recreate session
        localStorage.removeItem(sessionSignerStorageKey)
        localStorage.removeItem(sessionAAStorageKey)
        useAAStore.getState().reset({ hasSetupBefore: true })

        try {
          await activateAASession(account, true)
        } catch (err2) {
          // Invalidate aa session and attempt to recreate session
          localStorage.removeItem(sessionSignerStorageKey)
          localStorage.removeItem(sessionAAStorageKey)
          useAAStore.getState().reset({ hasSetupBefore: true })
          throw err
        }

        try {
          const trialRegisterTxObj = (await aaTrialRegister(txData)) as any
          return trialRegisterTxObj
        } catch (err3) {
          throw err3
        }
      }
    }
  }

  return {
    activateAASession,
    deactivateAASession,
    createAASmartAccount,
    populateAASession,
    aaTrialRegister,
    createAASession,
    ...aaStore,
  }
}
