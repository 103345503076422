import { HandleLocalStorageWithAccountChange } from '@/components/shared/Panels/RightPanel/HandleLocalStorageWithAccountChange'
import { MountFSocket } from './MountFSocket'
import { AllowanceListener } from './AllowanceListener'
import { BalanceListener } from './BalanceListener'
import { AASessionListener } from './AASessionListener'
import { RouteParamsListener } from './RouteParamsListener'
import { AppChainConfigListener } from './AppChainConfigListener'
import { PathNameListener } from './PathNameListener'
import { PrivyLoadedListener } from './PrivyLoadedListener'

export function SingletonListeners() {
  return (
    <>
      <PathNameListener />
      <AppChainConfigListener />
      <BalanceListener />
      <AllowanceListener />
      {/* <HandleLocalStorageWithAccountChange /> */}
      <MountFSocket />
      <AASessionListener />
      <RouteParamsListener />
      <PrivyLoadedListener />
    </>
  )
}
