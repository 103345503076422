import * as Sentry from '@sentry/react'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { PostHogProvider } from '@/lib/posthog/Provider'
import { enableMapSet } from 'immer'
import { createWeb3 } from '@/chains/createWeb3'
import { PrivyProvider } from '@/lib/privy/PrivyProvider'

import { GlobalStyle } from './style'
import { App } from './App'
import { SoundProvider } from './components/shared/SoundSystem/SoundContext'
import { AppDownPage } from '@/pages/AppDownPage'
import { MoonPayProvider } from '@moonpay/moonpay-react'

Sentry.init({
  environment: import.meta.env.VITE_DEPLOYMENT_ENV,
  dsn: 'https://2cfe4abfd194ab4e6fd750a962bdb363@o4508333283082240.ingest.us.sentry.io/4508333357531136',
  integrations: [],
})

// Init
createWeb3()
enableMapSet()

const root = createRoot(document.getElementById('root') as HTMLElement)

const isAppDown = location.pathname === '/down-for-maintenance'

const removeLoadingElement = () => {
  const loadingElement = document.getElementById('app-initial-loading')
  if (loadingElement) {
    loadingElement.remove()
  }
}

root.render(
  <React.StrictMode>
    <GlobalStyle />
    <PostHogProvider>
      {isAppDown ?
        <AppDownPage />
      : <SoundProvider>
          <PrivyProvider>
            <MoonPayProvider apiKey={import.meta.env.VITE_MOON_PAY_API_KEY} debug>
              <App />
            </MoonPayProvider>
          </PrivyProvider>
        </SoundProvider>
      }
    </PostHogProvider>
  </React.StrictMode>
)

setTimeout(removeLoadingElement, 250)
