/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react'
import { styled } from 'styled-components'
import { LeftContainer, PageWrapper, RightContainer } from '../style'
import { SPACING } from '@/design'
import { GamePage } from '..'
import { RefactorLiveEntries } from '@/components/shared/LiveEntries/RefactorLiveEntries'
import PlinkoCanvas from '@/components/Canvases/PlinkoCanvas'
import { PlinkoForm } from '@/components/Forms/Plinko'
import { useUnityContext } from 'react-unity-webgl'
import unityContextConfig from '@/components/Canvases/PlinkoCanvas/unityContextConfig'
import { GameLoadingChip } from '@/components/shared/GamePage/styled'
import FullScreenLoadingChip from '@/components/shared/FullScreenLoadingChip'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { GameContractListener } from '@/components/Singletons/GameContractListener'
import { usePlinkoGameState } from '@/store/useGameStateStore'
import { useGameOutcomeStore } from '@/store/useGameOutcomeStore'
import useSUContractStore from '@/store/useSUContractStore'
import { entryEvent } from '@/events/entryEvent'
import { useShallow } from 'zustand/react/shallow'

// Define the type for a risk bucket
type RiskBucket = {
  likelihood: number
  payoutMultiplier: number
  // Add other properties if necessary
}

export const LeftPlinkoContainer = styled.div`
  display: grid;
  grid-template-rows: 485px minmax(0, 1fr);
  grid-gap: ${SPACING.md}px;
  grid-row: 1 / span 2;
`

export const RightPlinkoContainer = styled.div`
  display: grid;
  grid-column: 2 / span 2;
  grid-row: 1 / span 2;
  grid-auto-rows: auto;
`

export const PlinkoPage = () => {
  const plinkoGameState = usePlinkoGameState()

  const unityContext = useUnityContext(unityContextConfig)
  const [isMounted, setIsMounted] = useState(true)
  const isMobileScreen = useIsBreakpoint('sm')

  const setBorderActive = useGameOutcomeStore(state => state.setIsShowingOutcome)
  const setPlayerWon = useGameOutcomeStore(state => state.setDidPlayerWin)
  const setWinIntensity = useGameOutcomeStore(state => state.setIntensity)

  const { submittedAmount, entryCount } = useSUContractStore(state => ({
    submittedAmount: state.submittedAmount,
    entryCount: state.entryCount,
  }))

  const [cumulativeReturn, setCumulativeReturn] = useState(0)

  const { sendMessage, addEventListener, removeEventListener, isLoaded } = unityContext
  const { send, gameStateType } = usePlinkoGameState(
    useShallow(state => ({
      selectedSide: state.entry.side,
      setSelectedSide: (side: number) => state.setEntry({ side }),
      results: state.results,
      side: state.submittedEntry ? state.submittedEntry.side : state.entry.side,
      gameStateType: state.type,
      send: state.send,
    }))
  )

  // useEffect(() => {
  //   console.log('DEBUG: unity isLoaded', isLoaded)
  // }, [isLoaded])

  const handleUpdateRiskFromReact = (riskLevel: number) => {
    const data = {
      risk: riskLevel.toString(),
    }
    const jsonData = JSON.stringify(data)
    sendMessage('Plinko Interactable', 'UpdateRiskFromReact', jsonData)
  }

  const handleUpdateRowsFromReact = (rowCount: number) => {
    const data = {
      rows: rowCount,
    }
    const jsonData = JSON.stringify(data)
    sendMessage('Plinko Interactable', 'UpdateRowsFromReact', jsonData)
  }

  // @TODO: This is not ideal, but it works
  useEffect(() => {
    console.log('running use effect for results: ', plinkoGameState)
    if (!plinkoGameState.results) return
    setCumulativeReturn(0)
    const data = {
      bucketsToLandIn: plinkoGameState.results.resultSides.map(rs => String(rs)).join(','),
    }
    console.log(
      'use effect data: ',
      plinkoGameState.results.resultSides.map(rs => String(rs)).join(',')
    )
    const jsonData = JSON.stringify(data)
    // console.log('DEBUG: UNITY handleSubmitGameEntryReact', jsonData)
    sendMessage('Plinko Interactable', 'DisplayGameResultsFromReact', jsonData)
    console.log('sent data to unity')
  }, [plinkoGameState.results, sendMessage])

  const handleUpdateVolumeFromReact = (volume: number) => {
    const data = {
      volumeMultiplier: volume,
    }
    const jsonData = JSON.stringify(data)
    // sendMessage('Plinko Interactable', 'UpdateVolumeMultiplierFromReact', jsonData)
  }

  const handleBallCompletedFall = useCallback(
    (multiplier: any) => {
      // Get the multiplier value from the Unity callback
      const payoutMultiplier = parseFloat(multiplier)

      const amountPerBall = Number(submittedAmount) / (entryCount || 1)
      const returnAmount = amountPerBall * payoutMultiplier
      const deltaAmount = returnAmount - amountPerBall

      console.log('DEBUG: handleBallCompletedFall', payoutMultiplier, deltaAmount)

      entryEvent.pub('updateBalance')
      entryEvent.pub('entryFinished', {
        deltaAmount,
      })

      // Update cumulative return
      setCumulativeReturn(prev => {
        const newTotal = prev + deltaAmount

        // Calculate return percentage based on total bet
        const totalBetAmount = submittedAmount
        const returnPercentage = (newTotal / Number(totalBetAmount)) * 100

        // Show border animation as soon as we're profitable
        if (newTotal > 0) {
          setBorderActive(true)
          setPlayerWon(true)

          // Calculate intensity based on return percentage thresholds
          let intensity = 1
          if (returnPercentage >= 200)
            intensity = 5 // 200%+ return
          else if (returnPercentage >= 150)
            intensity = 4 // 150-200% return
          else if (returnPercentage >= 100)
            intensity = 3 // 100-150% return
          else if (returnPercentage >= 50)
            intensity = 2 // 50-100% return
          else intensity = 1 // 0-50% return

          setWinIntensity(intensity)
        }
        return newTotal
      })
    },
    [setBorderActive, setPlayerWon, setWinIntensity, submittedAmount, entryCount]
  )

  useEffect(() => {
    // console.log('state.type', state.type)
    switch (gameStateType) {
      case 'IDLE':
        // idleDice()
        break
      case 'RESET':
        // resetDice()
        send({ type: 'IDLE', payload: {} })
        break
      default:
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameStateType])

  const handlePlinkoRoundOver = useCallback(() => {
    // Wait 1 second before resetting the border animation
    setTimeout(() => {
      setBorderActive(false)
      setPlayerWon(false)
      setWinIntensity(1)
      entryEvent.pub('gameFinished')
    }, 1000)
  }, [setBorderActive, setPlayerWon, setWinIntensity])

  useEffect(() => {
    addEventListener('BallCompletedFall', handleBallCompletedFall)
    return () => removeEventListener('BallCompletedFall', handleBallCompletedFall)
  }, [addEventListener, handleBallCompletedFall, removeEventListener])

  useEffect(() => {
    addEventListener('PlinkoRoundOver', handlePlinkoRoundOver)
    return () => removeEventListener('PlinkoRoundOver', handlePlinkoRoundOver)
  }, [addEventListener, handlePlinkoRoundOver, removeEventListener])

  return (
    <PageWrapper>
      {!isMounted &&
        (isMobileScreen ? <GameLoadingChip width={48} height={48} /> : <FullScreenLoadingChip />)}
      <GamePage mode='plinko'>
        <LeftContainer>
          <PlinkoCanvas unityContext={unityContext} />
          {!isMobileScreen && isMounted && <RefactorLiveEntries />}
        </LeftContainer>
        <RightContainer>
          {isMounted && (
            <PlinkoForm
              sendRiskLevelToUnity={handleUpdateRiskFromReact}
              sendRowsToUnity={handleUpdateRowsFromReact}
              unityContext={unityContext}
            />
          )}
        </RightContainer>
      </GamePage>
      <GameContractListener />
    </PageWrapper>
  )
}
