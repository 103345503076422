import { useAA } from '@/lib/aa/hooks/useAA'
import { FullOverlayLoading } from '@/components/shared/FullOverlayLoading'
import { useActiveWallet } from '@/lib/privy/hooks'
import useAAStore from '@/lib/aa/hooks/useAAStore'

export const AASessionListener = () => {
  const { walletProvider, walletAddress } = useActiveWallet()
  const { populateAASession } = useAA()
  const isActivatingAA = useAAStore(state => state.isActivatingAA)

  useEffect(() => {
    if (!walletProvider || !walletAddress) return
    populateAASession(walletAddress).finally(() =>
      useAAStore.setState({ aaInitialState: 'initialized' })
    )
  }, [walletProvider, walletAddress])

  return <FullOverlayLoading loadingText='setting up quickplay' isShowing={isActivatingAA} />
}
