import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { useIsBreakpoint } from './hooks/common/useIsBreakpoint'
import { RootWrapper, AppWrapper } from './style'
import { PublicRoutes } from './Router'
import { SingletonListeners } from './components/Singletons/SingletonListeners'
import { SingletonModals } from './components/Singletons/SingletonModals'
import { Header } from './components/shared/Header'
import { RightPanel } from './components/shared/Panels/RightPanel'
import { LeftPanel } from './components/shared/Panels/LeftPanel'
import { ParticlesBackground } from './components/shared/ParticlesBackground'
import FundWalletModal from './components/Modals/FundWalletModal'

export function Router() {
  const isMobileScreen = useIsBreakpoint('sm')

  return (
    <RootWrapper>
      <Routes>
        <Route
          path='*'
          element={
            <>
              <Header />
              <FundWalletModal />
              <AppWrapper $isMobileScreen={isMobileScreen}>
                <ParticlesBackground />
                {!isMobileScreen && <LeftPanel />}
                <PublicRoutes />
                {!isMobileScreen && <RightPanel />}
              </AppWrapper>
            </>
          }
        />
      </Routes>
      <SingletonListeners />
      <SingletonModals />
    </RootWrapper>
  )
}

export function App() {
  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  )
}
