import { useGameContractListener } from './useGameContractListener'
import { useOnGameFinsihed } from './useOnGameFinished'
import useSUContractStore from '@/store/useSUContractStore'
import { resetGameStoreByGameName, useDelayedLiveEntry } from '@/store/useGameStateStore'
import { useCurrency } from '@/hooks/useCurrency'
import { useAuthedActiveWallet } from '@/lib/privy/hooks'
import { usePathGameName } from '@/hooks/usePathGameName'

export const GameContractListener = () => {
  const gameName = usePathGameName()
  const { setIsSubmitting, setInProgressEntry } = useSUContractStore(state => ({
    setInProgressEntry: state.setInProgressEntry,
    setIsSubmitting: state.setIsSubmitting,
  }))
  const clearDelayedLiveEntry = useDelayedLiveEntry(state => state.clear)
  const { fetchAndSetCurrencyBalance } = useCurrency()
  const { walletAddress } = useAuthedActiveWallet()

  useEffect(() => {
    return () => {
      clearDelayedLiveEntry()
      setIsSubmitting(false)
      setInProgressEntry(null)
      resetGameStoreByGameName(gameName)
    }
  }, [gameName])

  useEffect(() => {
    if (!walletAddress) return

    fetchAndSetCurrencyBalance(walletAddress, 'currency')
  }, [gameName, walletAddress])

  useGameContractListener()
  useOnGameFinsihed()

  return null
}
