import { BiconomySmartAccountV2, Hex, Session, SessionParams } from '@biconomy/account'
import { create } from 'zustand'
import { defaultBiconomyConfig } from '@/chains/configs'
import { ISessionStorage } from '@biconomy/account/dist/_types/modules/interfaces/ISessionStorage'
import { LOCAL_STORAGE } from '@/constants/storage'

export const DEFAULT_IS_USING_QUICK_PLAY = false

export const setIsUsingQuickPlayInLocalStorage = (
  isUsingQuickPlay: boolean,
  publicAddress: string
) => {
  let isUsingQuickplayStr = localStorage.getItem(LOCAL_STORAGE.IS_USING_QUICKPLAY)
  if (!isUsingQuickplayStr) {
    localStorage.setItem(LOCAL_STORAGE.IS_USING_QUICKPLAY, JSON.stringify({}))
    isUsingQuickplayStr = localStorage.getItem(LOCAL_STORAGE.IS_USING_QUICKPLAY)
  }
  localStorage.setItem(
    LOCAL_STORAGE.IS_USING_QUICKPLAY,
    JSON.stringify({
      ...JSON.parse(isUsingQuickplayStr!),
      [publicAddress?.toLowerCase()]: isUsingQuickPlay + '',
    })
  )
}

export const getIsUsingQuickPlayFromLocalStorage = (publicAddress: string) => {
  const isUsingQuickplayStr = localStorage.getItem(LOCAL_STORAGE.IS_USING_QUICKPLAY)
  if (!isUsingQuickplayStr) return DEFAULT_IS_USING_QUICK_PLAY
  const parsedJSON = JSON.parse(isUsingQuickplayStr)
  return parsedJSON[publicAddress?.toLowerCase()] ?
      parsedJSON[publicAddress?.toLowerCase()] === 'true'
    : DEFAULT_IS_USING_QUICK_PLAY
}

type AAInitialState = 'pending' | 'initialized'
export type AASessionState = {
  smartAccountClient: BiconomySmartAccountV2 | null
  smartAccountAddress: Hex
  bundlerUrl: string
  biconomyPaymasterApiKey: string
  isSmartAccountLoading: boolean
  session: Session | null
  sessionStorageClient: ISessionStorage | null
  aaClient: BiconomySmartAccountV2 | null
  params: SessionParams | null
  isUsingAA: boolean
  isActivatingAA: boolean
  hasSetupBefore: boolean
  aaInitialState: AAInitialState
}

export type AASessionActions = {
  set(aaInfo: Partial<AASessionState>, walletAddress?: string): void
  reset(stateOverride?: Partial<AASessionState>): void
}

export const initialAASessionState: AASessionState = {
  smartAccountClient: null,
  smartAccountAddress: '0x',
  bundlerUrl: defaultBiconomyConfig.bundlerUrl,
  biconomyPaymasterApiKey: defaultBiconomyConfig.payMasterAPIKey,
  isSmartAccountLoading: false,
  session: null,
  sessionStorageClient: null,
  aaClient: null,
  params: null,
  isUsingAA: false,
  isActivatingAA: false,
  hasSetupBefore: false,
  aaInitialState: 'pending',
}

const useAAStore = create<AASessionState & AASessionActions>(set => ({
  ...initialAASessionState,
  set: (state: Partial<AASessionState>, walletAddress: string) => {
    set(prevState => {
      // Check if 'isUsingQuickplay' is present in the incoming 'state'
      if (state.hasOwnProperty('isUsingAA')) {
        if (!walletAddress) {
          console.warn(
            'Cannot set isUsingQuickplay in localStorage without authPublicAddress in useAAStore.set(...)'
          )
        } else {
          // Call the function if the key exists
          setIsUsingQuickPlayInLocalStorage(state.isUsingAA!, walletAddress?.toLowerCase())
        }
      }

      // Spread the previous state and merge with the new state
      return { ...prevState, ...state }
    })
  },
  reset: (stateOverride = {}) =>
    set(() => {
      return { ...initialAASessionState, ...stateOverride }
    }),
}))

export default useAAStore
