import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import Asteroid, { type AsteroidRef } from './Asteroid'
import { type ShipConfig, type AsteroidConfig } from './GameConfig'
import * as THREE from 'three'
import { useGLTF } from '@react-three/drei'
import { getShipPosition, getShipVelocity, getCrashRoundTime } from './shipPath'
import { useGameTime } from './GameTimeContext'
import { v4 as uuidv4 } from 'uuid'
import { useCrashGameStore } from '@/store/useCrashGameStore'
import { useCrashSound } from './CrashSoundInterface'

const asteroidModels = [
  import.meta.env.VITE_GAME_ASSETS_CDN + '/glb/asteroids/Asteroid1.glb',
  import.meta.env.VITE_GAME_ASSETS_CDN + '/glb/asteroids/Asteroid2.glb',
  import.meta.env.VITE_GAME_ASSETS_CDN + '/glb/asteroids/Asteroid3.glb',
  import.meta.env.VITE_GAME_ASSETS_CDN + '/glb/asteroids/Asteroid4.glb',
]

interface AsteroidFieldProps {
  config: AsteroidConfig
  killerConfig: AsteroidConfig
  shipConfig: ShipConfig
  isKiller?: boolean
}

interface AsteroidData {
  id: string
  index: number
  config: {
    speed: number
    initialPosition: THREE.Vector3
    size: number
    isKiller: boolean
    onCollision?: () => void
    color: string
    emissiveColor: string
    edgeColor: string
    edgeThickness: number
    roughness: number
    metalness: number
    edgeGlow: number
    onRespawn: () => void
    rotationIntensity: number
    onPositionUpdate?: (position: THREE.Vector3) => void
    onDestroyComplete?: () => void
  }
}

const emissiveColorSets = ['#ff0000', '#00ff00', '#0000ff']
const edgeColorSets = ['#ff6666', '#66ff66', '#6666ff']

const AsteroidField: React.FC<AsteroidFieldProps> = ({
  config: asteroidConfig,
  killerConfig,
  shipConfig,
  isKiller = false,
}) => {
  const { gameTime, roundTime } = useGameTime()
  const [asteroids, setAsteroids] = useState<AsteroidData[]>([])
  const { gameState, crashPoint, getConfigAtIntensity, getIntensityForTime } = useCrashGameStore()
  const asteroidPositions = useRef(new Map<string, THREE.Vector3>())
  const { playGameSound } = useCrashSound()

  const asteroidRefs = useRef(new Map<string, AsteroidRef>())
  const gameTimeRef = useRef<number>(gameTime)
  const roundTimeRef = useRef<number>(roundTime)
  const asteroidConfigRef = useRef<AsteroidConfig>(asteroidConfig)

  // Killing the ship
  const crashRoundTime = useRef<number>(-1)
  const killerAsteroidLaunchTime = useRef<number>(-1)
  const killerAsteroidSpeed = useRef<number>(0)
  const killerAsteroidSize = useRef<number>(0)
  const killerAsteroidFinalYPosition = useRef<number>(0)
  const [killerIsActive, setKillerIsActive] = useState(false)

  const killerAsteroidRef = useRef<AsteroidRef>(null!)

  const [killerFinalYPosition, setKillerFinalYPosition] = useState<number>(0)

  useEffect(() => {
    asteroidConfigRef.current = asteroidConfig
  }, [asteroidConfig])

  useEffect(() => {
    asteroidModels.forEach(url => {
      useGLTF.preload(url)
    })
  }, [])

  useEffect(() => {
    gameTimeRef.current = gameTime
    roundTimeRef.current = roundTime

    if (killerAsteroidLaunchTime.current < 0) return

    if (roundTime >= killerAsteroidLaunchTime.current) {
      if (!killerIsActive) {
        setKillerIsActive(true)
        console.log('DEBUG: Killer asteroid activated')
      }
    }
  }, [gameTime, roundTime, killerIsActive])

  // Calculate Initial Killer Asteroid Values and Launch Time ------------------------
  useEffect(() => {
    crashRoundTime.current = getCrashRoundTime(crashPoint)
    console.log('DEBUG crashRoundTime', crashRoundTime.current)
    killerAsteroidSpeed.current =
      Math.random() * (killerConfig.speed.max - killerConfig.speed.min) + killerConfig.speed.min
    killerAsteroidSize.current =
      Math.random() * (killerConfig.maxSize - killerConfig.minSize) + killerConfig.minSize

    killerAsteroidFinalYPosition.current = getShipPosition(
      gameTimeRef.current + crashRoundTime.current,
      crashRoundTime.current,
      shipConfig
    )
    const finalXPosition = getConfigAtIntensity(
      'shipConfig.position.x',
      getIntensityForTime(crashRoundTime.current)
    )

    // Calculate the time it takes for the killer asteroid to reach the ship
    const timeToImpact =
      (15 - finalXPosition - (killerAsteroidSize.current * 2 + 0.03)) / killerAsteroidSpeed.current
    console.log(
      'DEBUG crash timeToImpact',
      timeToImpact,
      finalXPosition,
      killerAsteroidSpeed.current
    )

    // Set the launch time to be crashRoundTime minus the travel time, ensuring it's not negative
    killerAsteroidLaunchTime.current = crashRoundTime.current - timeToImpact
    console.log('DEBUG crash killerAsteroidLaunchTime', killerAsteroidLaunchTime.current)

    setKillerFinalYPosition(killerAsteroidFinalYPosition.current)
  }, [crashPoint])

  const currentShipXPosition = useRef(shipConfig.position.x)
  useEffect(() => {
    currentShipXPosition.current = shipConfig.position.x
  }, [shipConfig.position.x])

  const initialKillerAsteroidPosition = useMemo(() => {
    return new THREE.Vector3(15, killerFinalYPosition, 5)
  }, [killerFinalYPosition])

  const killerAsteroid = (
    <Asteroid
      key='killer-asteroid'
      ref={killerAsteroidRef}
      config={{
        ...killerConfig,
        speed: killerAsteroidSpeed.current,
        size: killerAsteroidSize.current,
        initialPosition: initialKillerAsteroidPosition,
        isKiller: true,
        emissiveColor: emissiveColorSets[Math.floor(Math.random() * emissiveColorSets.length)],
        edgeColor: edgeColorSets[Math.floor(Math.random() * edgeColorSets.length)],
        edgeThickness: 1,
        roughness: 1,
        metalness: 0.6,
        edgeGlow: 1,
        color: 'gray',
        onRespawn: () => {},
        onDestroyComplete: () => {},
        onPositionUpdate: position => {
          onAsteroidPositionUpdate('-1', position)
          // console.log('DEBUG: Killer asteroid position:', position)
        },
      }}
    />
  )

  const generateAsteroidData: (index: number) => AsteroidData = useCallback(
    (index: number): AsteroidData => {
      const asteroidId = uuidv4()
      const currentGlobalTime = gameTimeRef.current
      const currentGameTime = roundTimeRef.current
      const shipRadius = 1
      const currentConfig = asteroidConfigRef.current

      const x =
        Math.random() * (currentConfig.xSpawnRange.max - currentConfig.xSpawnRange.min) +
        currentConfig.xSpawnRange.min
      let y =
        Math.random() * (currentConfig.ySpawnRange.max - currentConfig.ySpawnRange.min) +
        currentConfig.ySpawnRange.min

      const speed =
        Math.random() * (currentConfig.speed.max - currentConfig.speed.min) +
        currentConfig.speed.min

      // Calculate time to impact
      const distance = x - currentShipXPosition.current
      const timeToImpact = distance / speed

      // Predict ship position and adjust asteroid y-position
      const globalImpactTime = currentGlobalTime + timeToImpact
      const gameImpactTime = currentGameTime + timeToImpact
      const predictedShipY = getShipPosition(
        globalImpactTime - currentConfig.timingOffset,
        gameImpactTime - currentConfig.timingOffset,
        shipConfig
      )
      const predictedShipVelocityY = getShipVelocity(globalImpactTime, gameImpactTime, shipConfig)

      const safeDistance = currentConfig.maxSize / 2 + shipRadius + currentConfig.safetyBuffer

      if (Math.abs(y - predictedShipY) < safeDistance) {
        if (predictedShipVelocityY > 0) {
          y = predictedShipY - safeDistance
        } else if (predictedShipVelocityY < 0) {
          y = predictedShipY + safeDistance
        } else {
          y = predictedShipY + (Math.random() < 0.5 ? safeDistance : -safeDistance)
        }
      }

      const size =
        Math.random() * (currentConfig.maxSize - currentConfig.minSize) + currentConfig.minSize
      const initialPosition = new THREE.Vector3(x, y, 5)

      const colorSetIndex = Math.floor(Math.random() * emissiveColorSets.length)
      const selectedEmissiveColor = emissiveColorSets[colorSetIndex]
      const selectedEdgeColor = edgeColorSets[colorSetIndex]

      return {
        id: asteroidId,
        index,
        config: {
          speed,
          initialPosition,
          size,
          isKiller,
          color: 'gray',
          emissiveColor: selectedEmissiveColor,
          edgeColor: selectedEdgeColor,
          edgeThickness: 1,
          roughness: 1,
          metalness: 0.6,
          edgeGlow: 1,
          onRespawn: () => {
            setAsteroids(prevAsteroids => {
              if (index >= prevAsteroids.length) {
                console.warn(
                  `Respawn index ${index} is out of bounds. Current asteroid count: ${prevAsteroids.length}`
                )
                return prevAsteroids
              }
              const newAsteroids = [...prevAsteroids]
              newAsteroids[index] = generateAsteroidData(index)
              return newAsteroids
            })
          },
          rotationIntensity: currentConfig.rotationIntensity,
          onPositionUpdate: (position: THREE.Vector3) =>
            onAsteroidPositionUpdate(asteroidId, position),
          onDestroyComplete: () => {
            setAsteroids(prev => prev.filter(ast => ast.id !== asteroidId))
            asteroidPositions.current.delete(asteroidId)
          },
        },
      }
    },
    [shipConfig, gameState]
  )

  const updateAsteroids = useCallback(() => {
    setAsteroids(prevAsteroids => {
      const currentCount = prevAsteroids.length
      const desiredCount = asteroidConfig.density

      let updatedAsteroids = [...prevAsteroids]

      if (desiredCount > currentCount) {
        const asteroidsToAdd = desiredCount - currentCount
        const newAsteroids: AsteroidData[] = Array.from({ length: asteroidsToAdd }, (_, i) =>
          generateAsteroidData(currentCount + i)
        ).filter(Boolean) as AsteroidData[]
        updatedAsteroids = [...updatedAsteroids, ...newAsteroids]
      } else if (desiredCount < currentCount) {
        updatedAsteroids = updatedAsteroids.slice(0, desiredCount)
      }

      return updatedAsteroids
    })
  }, [asteroidConfig.density, generateAsteroidData, gameState])

  useEffect(() => {
    updateAsteroids()
  }, [asteroidConfig.density, updateAsteroids])

  const onAsteroidPositionUpdate = useCallback((id: string, position: THREE.Vector3) => {
    if (!id) return
    asteroidPositions.current.set(id, position.clone())
  }, [])

  useEffect(() => {
    if (gameState === 'CRASHING') {
      // Freeze all regular asteroids
      asteroidRefs.current.forEach(asteroidRef => {
        asteroidRef?.freeze?.()
      })

      // Freeze the killer asteroid if it's active
      if (killerIsActive) {
        killerAsteroidRef.current?.freeze?.()
        console.log('DEBUG: Killer asteroid frozen during CRASHING state')
      }
    } else if (gameState === 'EXPLODING') {
      const totalAsteroids = Math.max(asteroids.length, 1)
      const delayPerAsteroid = 1000 / totalAsteroids

      asteroids.forEach((asteroid, index) => {
        setTimeout(() => {
          const asteroidRef = asteroidRefs.current.get(asteroid.id)
          if (asteroidRef) {
            playGameSound('beep', 0.1, 3)
            // console.log('DEBUG destroying asteroid', asteroid.id)
            asteroidRef.destroy()
          }
        }, index * delayPerAsteroid)
      })

      // Handle the killer asteroid destruction
      if (killerIsActive) {
        setTimeout(() => {
          if (killerAsteroidRef.current) {
            playGameSound('beep', 0.1, 3)
            console.log('DEBUG destroying killer asteroid')
            killerAsteroidRef.current.destroy()
          }
        }, 0) // Adjust delay as needed based on game logic
      }
    }
  }, [gameState])

  // Cleanup when the game is reset to 'IDLE'
  useEffect(() => {
    if (gameState === 'IDLE') {
      setAsteroids([])
      setKillerIsActive(false)
      asteroidRefs.current.clear()
      asteroidPositions.current.clear()
      killerAsteroidLaunchTime.current = -1
      killerAsteroidSpeed.current = 0
      killerAsteroidSize.current = 0
      killerAsteroidFinalYPosition.current = 0
      setKillerFinalYPosition(0)
      console.log('DEBUG: AsteroidField reset for new game')
    }
  }, [gameState])

  return (
    <>
      {asteroids.map(asteroid => (
        <Asteroid
          key={asteroid.id}
          ref={(ref: AsteroidRef) => {
            if (ref) {
              asteroidRefs.current.set(asteroid.id, ref)
            } else {
              asteroidRefs.current.delete(asteroid.id)
            }
          }}
          config={{
            ...asteroid.config,
            onPositionUpdate: pos => onAsteroidPositionUpdate(asteroid.id, pos),
          }}
        />
      ))}
      {killerIsActive && killerAsteroid}
    </>
  )
}

export default AsteroidField
