import React, { useRef, useEffect, useCallback, useState } from 'react'
import { EffectComposer, Bloom } from '@react-three/postprocessing'
import { useGameTime } from './GameTimeContext'
import AsteroidField from './AsteroidField'
import Ship from './Ship'
import { useCrashGameStore } from '@/store/useCrashGameStore'
import { GameGrid } from '@/components/shared/GameGrid/GameGrid'
import AnimatedText from './AnimatedMultiplierText'
import { type Group } from 'three'
import { useGameOutcomeStore } from '@/store/useGameOutcomeStore'
import GameIntensityManager from './GameIntensityManager'
import { type GamePacingConfig } from './GameConfig'
import ForceField from './ForceField'
import { useCrashSound } from './CrashSoundInterface'
import Stars from './Stars'
import { entryEvent } from '@/events/entryEvent'
import { useCrashGameState } from '@/store/useGameStateStore'
import { useShallow } from 'zustand/react/shallow'

export const CrashScene: React.FC = () => {
  const { playGameSound } = useCrashSound()

  // GameTime and State ----------------------------------------------------------
  const { roundTime, startRoundTime, resetRoundTime } = useGameTime()
  const {
    gameState,
    multiplier,
    crashPoint,
    autoCashoutMultiplier,
    endGame,
    incrementMultiplierBy,
    asteroidConfig,
    killerConfig,
    shipConfig,
    gamePacingConfig,
    activateForceField,
    backendResult,
  } = useCrashGameStore()
  // const send = (payload: any) => useCrashGameState.getState().send?.(payload)
  // const genericGameState = () => useCrashGameState.getState().type
  const { send, gameStateType } = useCrashGameState(
    useShallow(state => ({
      selectedSide: state.entry.side,
      setSelectedSide: (side: number) => state.setEntry({ side }),
      results: state.results,
      side: state.submittedEntry ? state.submittedEntry.side : state.entry.side,
      gameStateType: state.type,
      send: state.send,
    }))
  )

  // Local State ----------------------------------------------------------------
  const [isLoading, setIsLoading] = useState(true)
  const [gameOutcome, setGameOutcome] = useState<'WIN' | 'LOSS' | null>(null)

  // Border Animator -------------------------------------------------------------
  const setIsShowingOutcome = useGameOutcomeStore(state => state.setIsShowingOutcome)
  const setDidPlayerWin = useGameOutcomeStore(state => state.setDidPlayerWin)
  const setBorderIntensity = useGameOutcomeStore(state => state.setIntensity)

  // Refs ------------------------------------------------------------------------
  const animationFrameIdRef = useRef<number | null>(null)
  const multiplierRef = useRef<number>(multiplier)
  const lastUpdateTime = useRef<number | null>(null)
  const shipRef = useRef<Group>(null!)
  const finalHudPosition = useRef<[number, number, number]>([0, 0, 0])
  const roundTimeRef = useRef<number>(0)
  const crashPointRef = useRef<number>(crashPoint)
  const finalMultiplierRef = useRef<number>(0)
  const gameOutcomeRef = useRef<'WIN' | 'LOSS' | null>(gameOutcome)
  const autoCashoutMultiplierRef = useRef<number>(autoCashoutMultiplier)
  const gamePacingConfigRef = useRef<GamePacingConfig>(gamePacingConfig)
  const accumulatedTimeRef = useRef<number>(0)

  // Synchronize refs -------------------------------------------------------
  useEffect(() => {
    multiplierRef.current = multiplier
  }, [multiplier])

  useEffect(() => {
    roundTimeRef.current = roundTime
  }, [roundTime])

  useEffect(() => {
    crashPointRef.current = crashPoint
  }, [crashPoint])

  useEffect(() => {
    gameOutcomeRef.current = gameOutcome
  }, [gameOutcome])

  useEffect(() => {
    autoCashoutMultiplierRef.current = autoCashoutMultiplier
  }, [autoCashoutMultiplier])

  useEffect(() => {
    gamePacingConfigRef.current = gamePacingConfig
  }, [gamePacingConfig])

  useEffect(() => {
    // console.log('state.type', state.type)
    switch (gameStateType) {
      case 'IDLE':
        // idleDice()
        break
      case 'RESET':
        // resetDice()
        send({ type: 'IDLE', payload: {} })
        break
      default:
        break
    }
  }, [gameStateType])

  // Handle AnimatedText Position ------------------------------------------------
  const setFinalHudPosition = useCallback(() => {
    if (shipRef.current) {
      const position = shipRef.current.position
      finalHudPosition.current = [
        position.x,
        position.y + shipConfig.hud.verticalOffset,
        position.z,
      ]
      // console.log('DEBUG: finalHudPosition', finalHudPosition.current[1])
    }
  }, [shipConfig.hud.verticalOffset])

  // Handle Multiplier Increment -------------------------------------------------
  const stableIncrementMultiplier = useCallback(() => {
    // Get current values
    const currentRoundTime = roundTimeRef.current
    const currentCrashPoint = crashPointRef.current
    const currentAutoCashoutMultiplier = autoCashoutMultiplierRef.current
    const currentGameOutcome = gameOutcomeRef.current
    const { multiplierStepSize, multiplierStepRate } = gamePacingConfigRef.current

    // Calculate time since last update
    const deltaTime = currentRoundTime - (lastUpdateTime.current ?? 0)

    // Accumulate the deltaTime
    accumulatedTimeRef.current += deltaTime

    const stepDuration = multiplierStepRate / 1000 // Convert to seconds

    // Process all full steps
    while (accumulatedTimeRef.current >= stepDuration) {
      // Decrease accumulated time
      accumulatedTimeRef.current -= stepDuration

      const nextMultiplier = multiplierRef.current + multiplierStepSize

      // Player wins
      if (nextMultiplier >= currentAutoCashoutMultiplier && currentGameOutcome !== 'WIN') {
        finalMultiplierRef.current = currentAutoCashoutMultiplier
        incrementMultiplierBy(currentAutoCashoutMultiplier - multiplierRef.current)
        setGameOutcome('WIN')
        entryEvent.pub('entryFinished', {
          deltaAmount: backendResult.deltaAmounts[0], // deltaAmount
        })
        entryEvent.pub('updateBalance')
        playGameSound('win', 0.5, 0.7)
        setFinalHudPosition()
        setIsShowingOutcome(true)
        setDidPlayerWin(true)
        // Intensity logic if needed
        // setBorderIntensity(intensity);

        // Since the game outcome is determined, exit the loop
        break

        // Player loses
      } else if (nextMultiplier >= currentCrashPoint && currentGameOutcome === null) {
        finalMultiplierRef.current = currentCrashPoint
        setFinalHudPosition()
        setGameOutcome('LOSS')
        entryEvent.pub('entryFinished', {
          deltaAmount: backendResult.deltaAmounts[0], // deltaAmount
        })
        entryEvent.pub('updateBalance')
        playGameSound('beep', 0.5, 0.1)
        setTimeout(() => {
          playGameSound('lose', 0.4, 1)
        }, 550)
        resetRoundTime()
        setIsShowingOutcome(true)
        setDidPlayerWin(false)
        setBorderIntensity(1)
        endGame()

        // Exit the loop since the game has ended
        break

        // Crash after winning
      } else if (nextMultiplier >= currentCrashPoint && currentGameOutcome === 'WIN') {
        playGameSound('beep', 0.2, 0.1)
        resetRoundTime()
        endGame()
        setTimeout(() => {
          setIsShowingOutcome(false)
          setDidPlayerWin(false)
          setBorderIntensity(1)
        }, 2000)

        // Exit the loop since the game has ended
        break

        // Game continues
      } else {
        incrementMultiplierBy(multiplierStepSize)
        multiplierRef.current += multiplierStepSize
      }
    }

    lastUpdateTime.current = currentRoundTime

    animationFrameIdRef.current = requestAnimationFrame(stableIncrementMultiplier)
  }, [
    incrementMultiplierBy,
    playGameSound,
    setIsShowingOutcome,
    setDidPlayerWin,
    setFinalHudPosition,
    setBorderIntensity,
    endGame,
    resetRoundTime,
    backendResult,
  ])

  // Start the animation loop
  useEffect(() => {
    if (gameState === 'PLAYING') {
      startRoundTime()
      lastUpdateTime.current = roundTimeRef.current
      animationFrameIdRef.current = requestAnimationFrame(stableIncrementMultiplier)
    }

    if (gameState === 'IDLE') {
      setGameOutcome(null)
    }

    return () => {
      if (animationFrameIdRef.current) {
        cancelAnimationFrame(animationFrameIdRef.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState])

  useEffect(() => {
    const loadAssets = async () => {
      setIsLoading(false)
    }
    loadAssets()
  }, [])

  const handleGameCompletion = useCallback(() => {
    setTimeout(() => {
      // setIsShowingOutcome(false)
      setDidPlayerWin(false)
      setBorderIntensity(0)
      resetRoundTime()
      setGameOutcome(null)
    }, 1000) // extract to config file - same as AnimatedMultiplierText duration
  }, [resetRoundTime, setDidPlayerWin, setBorderIntensity, setIsShowingOutcome])

  const starsDistribution = useMemo(
    () => ({
      xSpread: 3000,
      ySpread: 30,
      zSpread: 10,
      spawnBuffer: 100,
    }),
    []
  )

  const starsColor = useMemo(
    () => ({
      hue: 0.6,
      saturationRange: [0.1, 0.3] as [number, number],
      lightnessRange: [0.8, 1] as [number, number],
    }),
    []
  )

  if (isLoading) {
    return null // or a loading indicator
  }

  return (
    <>
      <GameIntensityManager />
      <EffectComposer>
        <Bloom luminanceThreshold={0.15} luminanceSmoothing={3} intensity={0.5} />
      </EffectComposer>
      <Stars
        count={10000}
        baseSpeed={0.1}
        speedMultiplier={10}
        size={0.15}
        opacity={0.05}
        distribution={starsDistribution}
        color={starsColor}
      />
      <ambientLight intensity={1} />
      {/* <directionalLight position={[5, 10, 7.5]} intensity={1} /> */}
      <directionalLight position={[5, 10, 7.5]} intensity={8} />
      <pointLight position={[0, 0, 10]} intensity={1} distance={20} />
      <AsteroidField config={asteroidConfig} shipConfig={shipConfig} killerConfig={killerConfig} />
      <ForceField />

      {/* Always render the Ship component */}
      <Ship
        ref={shipRef}
        config={shipConfig}
        isPlaying={gameState === 'PLAYING'}
        multiplier={multiplier}
        gameOutcome={gameOutcome}
      />

      {/* Display AnimatedText when game ends */}
      {gameOutcome && (
        <AnimatedText
          position={[
            finalHudPosition.current[0],
            finalHudPosition.current[1],
            finalHudPosition.current[2],
          ]}
          text={`${finalMultiplierRef.current.toFixed(2)}x`}
          onAnimationComplete={() => {}}
          gameOutcome={gameOutcome}
          config={shipConfig}
        />
      )}
      {/* Always render the grid */}
      <GameGrid
        position={[0, -10, 0]}
        size={[300, 300]}
        color='#000000'
        emissive='#181717'
        emissiveIntensity={0.5}
        opacity={0.8}
        gridSpacing={3}
      />
      {/* {asteroidBeltRef.current} */}
    </>
  )
}

export default CrashScene
