import { createWithEqualityFn } from 'zustand/traditional'
import { shallow } from 'zustand/vanilla/shallow'
import { immer } from 'zustand/middleware/immer'
import { createSelectors } from './helpers/createSelectors'
import { usePostLog } from '@/lib/posthog/logging'
import posthog from 'posthog-js'

export type FetchableBalanceType = 'native' | 'currency'
// NOTE: Whenever we implement multi currency we will need to make this dynamic
// Single source of truth for all supported erc20 tokens
// example: 'usdc' | 'fare' | 'usdt' | 'link' ...
export type AvailableCurrencyType = 'currency'

export type CurrencyAmountMap = {
  [K in FetchableBalanceType]: string
}

export type CurrencyAllowanceMap = {
  [K in AvailableCurrencyType]: string
}

export type ApproveAllowanceState = 'pending' | 'approved' | 'not-approved'
export type CurrencyState = {
  approveAllowanceState: ApproveAllowanceState
  isApprovingAllowance: boolean
  selectedCurrency: AvailableCurrencyType
  allowances: CurrencyAllowanceMap
  balances: CurrencyAmountMap
}

export type CurrencyActions = {
  setApproveAllowanceState: (approveAllowanceState: ApproveAllowanceState) => void
  setSelectedCurrency: (type: AvailableCurrencyType) => void
  setBalance: (type: keyof CurrencyAmountMap, amount: string) => void
  setAllowance: (type: AvailableCurrencyType, amount: string) => void
  setIsApprovingAllowance: (isApprovingAllowance: boolean) => void
}

// game contract and currency
export const initialCurrencyAllowance: CurrencyAmountMap = {
  currency: '0',
  native: '0',
}

export const initialBalances: CurrencyAmountMap = {
  currency: '0',
  native: '0',
}

export const initialCurrencyState: CurrencyState = {
  approveAllowanceState: 'pending',
  isApprovingAllowance: false,
  selectedCurrency: 'currency',
  balances: initialBalances,
  allowances: initialCurrencyAllowance,
}

export type CurrencyStore = CurrencyState & CurrencyActions

const useCurrencyStoreBase = createWithEqualityFn<CurrencyStore>()(
  immer(set => ({
    ...initialCurrencyState,
    setSelectedCurrency: selectedCurrency =>
      set(() => ({
        selectedCurrency,
      })),
    setBalance: (type, balance) => {
      set(state => {
        const previousBalance = state.balances[type]

        if (previousBalance !== balance) {
          posthog?.capture('user_balance_changed', {
            balance: balance,
            previousBalance: previousBalance,
            type: type,
          })
        }

        state.balances[type] = balance
      })
    },
    setAllowance: (type, amount) =>
      set(state => ({
        allowances: {
          ...state.allowances,
          [type]: amount,
        },
      })),
    setIsApprovingAllowance: isApprovingAllowance =>
      set(() => ({
        isApprovingAllowance,
      })),
    setApproveAllowanceState: approveAllowanceState =>
      set({
        approveAllowanceState,
      }),
  })),
  shallow
)

const useCurrencyStore = createSelectors(useCurrencyStoreBase)

export default useCurrencyStore
