import { useCallback } from 'react'
import { fundWalletModalState } from '@/components/Modals/FundWalletModal'
// import ModalCard from '../components/shared/Modal/Card'
// import { DepositTab } from '@/pages/SettingsPage/DepositTab'

export const useFundModal = () => {
  const openModal = useCallback(() => (fundWalletModalState.isFundModalShowing = true), [])
  const closeModal = useCallback(() => (fundWalletModalState.isFundModalShowing = false), [])

  // const Modal = useCallback(() => {
  //   return showModal ?
  //       <ModalCard title='FUND WALLET' className='fund-modal-content' onClose={closeModal}>
  //         <DepositTab />
  //       </ModalCard>
  //     : null
  // }, [showModal, closeModal])

  return { openModal, closeModal }
}
