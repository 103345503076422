import { SFormSection } from '@/components/Forms/style'
import { BORDER_COLORS, BREAKPOINTS } from '@/design'
import { phoneBP } from '@/design/phoneSizes'

export const SDoubleInputRow = styled.div`
  @media only screen and (max-width: ${BREAKPOINTS.sm}px) {
    display: flex;
    flex-direction: row;
    align-items: end;
    border: none;
  }

  &.mobile-form {
    @media only screen and (max-width: ${BREAKPOINTS.sm}px) {
      padding-top: 15px;
    }
    @media ${phoneBP.xs} {
      padding-top: 0px;
    }
    @media ${phoneBP.lg} and (min-height: 800px) {
      padding-top: 0px;
      flex-direction: column;
      align-items: stretch;
    }
  }
`

export const STripleRow = styled.div`
  display: flex;
  padding: 0px 12px 16px 24px;
  border-bottom: 1px solid ${BORDER_COLORS.one};
  box-sizing: border-box;

  &.no-pd {
    padding: 0px;
    border-bottom: none;
  }

  &.large-mobile-screens {
    @media ${phoneBP.lg} and (min-height: 800px) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  ${SFormSection} {
    flex: 1;
  }

  @media only screen and (max-width: ${BREAKPOINTS.sm}px) {
    padding: 5px 0px 0px 10px;
    border: none;
    margin-top: 6px;
  }
  @media ${phoneBP.xs} {
    padding-top: 0px;
  }
`
