import { gameStoreMapByGameName } from '@/store/useGameStateStore'
import useSUContractStore from '@/store/useSUContractStore'
import { useShallow } from 'zustand/react/shallow'
import { usePathGameName } from './usePathGameName'

export const useIsGameAnimating = () => {
  const gameName = usePathGameName()
  const { isSubmitting } = useSUContractStore(
    useShallow(state => ({ isSubmitting: state.isSubmitting }))
  )
  const { type: gameStateType } = gameStoreMapByGameName[gameName](
    useShallow(state => ({ type: state.type }))
  )
  const isGameAnimating = useMemo(() => {
    return gameStateType !== 'IDLE' || isSubmitting
  }, [isSubmitting, gameStateType])
  return { isGameAnimating }
}
