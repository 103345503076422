import { useEffect, useRef, type PropsWithChildren } from 'react'
import { type GameModes } from '@/components/shared/Spinner'
import { FloatingContainer, PageWrapper, ScrollContainer, ScrollItem } from './style'
import { MobileTrollBox } from '@/components/Mobile/MobileTrollBox'
import { MobileLiveEntries } from '@/components/Mobile/MobileLiveEntries'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { SwiperAlert } from '@/components/shared/SwiperAlert'

interface IGamePageProps extends PropsWithChildren {
  mode: GameModes
}

const FloatingContent = ({ children }: PropsWithChildren<object>) => (
  <FloatingContainer
    transition={{
      duration: 0.5,
      ease: 'easeIn',
    }}
    initial={{
      opacity: 0,
    }}
    animate={{
      opacity: 1,
    }}
    exit={{
      opacity: 0,
    }}
  >
    {children}
  </FloatingContainer>
)

export const GamePage = ({ children, mode: _mode }: IGamePageProps) => {
  const isMobileScreen = useIsBreakpoint('sm')
  const middleSectionRef = useRef<HTMLDivElement>(null)
  const scrollContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isMobileScreen && scrollContainerRef.current && middleSectionRef.current) {
      const middleSectionOffset = middleSectionRef.current.offsetLeft
      scrollContainerRef.current.scrollLeft = middleSectionOffset
    }
  }, [isMobileScreen])

  return (
    <PageWrapper>
      {isMobileScreen ?
        <ScrollContainer ref={scrollContainerRef}>
          <ScrollItem>
            <MobileTrollBox isShowChat={true} />
          </ScrollItem>
          <ScrollItem ref={middleSectionRef} style={{ overflowY: 'scroll' }}>
            <SwiperAlert />
            <FloatingContent>{children}</FloatingContent>
          </ScrollItem>
          <ScrollItem>
            <MobileLiveEntries isShowLiveEntries={true} />
          </ScrollItem>
        </ScrollContainer>
      : <FloatingContent>{children}</FloatingContent>}
    </PageWrapper>
  )
}
