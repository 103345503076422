import { useSnapshot } from 'valtio'
import { AnimatePresence, motion } from 'framer-motion'

import { fundWalletModalState } from '.'
import { FundWalletMenu } from './FundWalletMenu'
import { TransferModalFunds } from './TransferFunds'
import { Button, ButtonEnum } from '@/components/shared/Button'
import { SVGS } from '@/assets'
import { TEXT_COLORS } from '@/design'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const ContentWrapper = styled.div`
  flex: 1;
`
const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  margin: auto;
  padding-inline: 0;
`

const FundPageButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const FundPageButton = styled(Button)`
  background-color: #7d00ff;
  border-radius: 624.9375rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  height: 56px;

  &:hover {
    background-color: #6400cc;
  }
`

const ButtonText = styled.span`
  flex: 1;
  text-align: center;
  text-transform: none;
  font-size: 1.0625rem;
  font-weight: 600;
  font-family: system-ui, sans-serif;
`

const cards = [<FundWalletMenu key={0} />, <TransferModalFunds key={1} />]

export default function CardCarousel() {
  const { stepIdx } = useSnapshot(fundWalletModalState)
  const isMobileScreen = useIsBreakpoint('xs')

  const selectedCardElem = useMemo(() => cards[stepIdx], [stepIdx])

  return (
    <Container>
      <ContentWrapper>
        <AnimatePresence>
          <motion.div
            layout
            transition={{
              layout: { duration: 0.2, type: 'spring' },
            }}
          >
            {selectedCardElem}
          </motion.div>
        </AnimatePresence>
      </ContentWrapper>
      {!isMobileScreen && (
        <ButtonWrapper>
          <FundPageButton
            type='button'
            buttonType={ButtonEnum.BASE}
            onClick={() => {
              window.location.href = '/settings'
            }}
            disabled={false}
            style={{ border: 'none' }}
          >
            <FundPageButtonWrapper>
              <ButtonText>View all options</ButtonText>
              <img
                style={{ position: 'absolute', right: 0 }}
                src={SVGS.arrowRight}
                alt='Arrow right'
                width={40}
              />
            </FundPageButtonWrapper>
          </FundPageButton>
          <p style={{ textAlign: 'center', color: TEXT_COLORS.two }}>Go to funding in settings</p>
        </ButtonWrapper>
      )}
    </Container>
  )
}
