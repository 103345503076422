import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { blockedGeoMessage } from '@/constants/geofence'
import './geofence-modal.css'
import {
  mobileModalContentVariants,
  modalContentVariants,
  SGradientWalletModalContent,
} from '@/components/Privy/SelectWalletModal'
import { ModalContent, ModalWrapper } from '@/components/shared/Modal/styles'

export default function GeofenceModal() {
  const originDomainName = location.origin
  const isMobileScreen = useIsBreakpoint('xs')

  if (!originDomainName.includes('blocked-geo.')) {
    return null
  }

  return (
    <div id='blocked-geo-modal-overlay' className='modal-showing'>
      <ModalWrapper>
        <SGradientWalletModalContent
          variants={isMobileScreen ? mobileModalContentVariants : modalContentVariants}
          initial='initial'
          animate='animate'
          exit='exit'
        >
          <ModalContent style={{ display: 'flex', justifyContent: 'center' }}>
            {blockedGeoMessage}
          </ModalContent>
        </SGradientWalletModalContent>
      </ModalWrapper>
    </div>
  )
}
