import { useHasAppLoaded } from '@/hooks/useHasAppLoaded'
import { usePrivy } from '@privy-io/react-auth'

export const PrivyLoadedListener = () => {
  const { isModalOpen } = usePrivy()
  const hasLoaded = useHasAppLoaded()

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const socialLoginSelectionElem = Array.from(
        document.querySelectorAll('.login-method-button')
      ).find(
        btn => btn.querySelector('div')?.textContent?.trim() === 'Log in with email or socials'
      )

      if (socialLoginSelectionElem) {
        const tempInner = socialLoginSelectionElem.innerHTML.replaceAll(
          'Log in with email or socials',
          'Sms, email, or socials'
        )

        socialLoginSelectionElem.innerHTML = tempInner
      }
    }, 1)

    return () => clearTimeout(timeoutId)
  }, [hasLoaded, isModalOpen])

  return null
}
